.ant-space {
  display: inline-flex;
}

.ant-space-vertical {
  flex-direction: column;
}

.ant-space-align-center {
  align-items: center;
}

.ant-space-align-start {
  align-items: flex-start;
}

.ant-space-align-end {
  align-items: flex-end;
}

.ant-space-align-baseline {
  align-items: baseline;
}

.ant-space-item:empty {
  display: none;
}

.ant-space-rtl {
  direction: rtl;
}