.ant-skeleton {
  display: table;
  width: 100%;
}

.ant-skeleton-header {
  display: table-cell;
  padding-right: 0.21rem;
  vertical-align: top;
}

.ant-skeleton-header .ant-skeleton-avatar {
  display: inline-block;
  vertical-align: top;
  background: rgba(190,190,190,.2);
  width: 0.43rem;
  height: 0.43rem;
  line-height: 0.43rem;
}

.ant-skeleton-header .ant-skeleton-avatar.ant-skeleton-avatar-circle {
  border-radius: 50%;
}

.ant-skeleton-header .ant-skeleton-avatar-lg {
  width: 0.53rem;
  height: 0.53rem;
  line-height: 0.53rem;
}

.ant-skeleton-header .ant-skeleton-avatar-lg.ant-skeleton-avatar-circle {
  border-radius: 50%;
}

.ant-skeleton-header .ant-skeleton-avatar-sm {
  width: 0.32rem;
  height: 0.32rem;
  line-height: 0.32rem;
}

.ant-skeleton-header .ant-skeleton-avatar-sm.ant-skeleton-avatar-circle {
  border-radius: 50%;
}

.ant-skeleton-content {
  display: table-cell;
  width: 100%;
  vertical-align: top;
}

.ant-skeleton-content .ant-skeleton-title {
  width: 100%;
  height: 0.21rem;
  background: rgba(190,190,190,.2);
  border-radius: 0.05rem;
}

.ant-skeleton-content .ant-skeleton-title+.ant-skeleton-paragraph {
  margin-top: 0.32rem;
}

.ant-skeleton-content .ant-skeleton-paragraph {
  padding: 0;
}

.ant-skeleton-content .ant-skeleton-paragraph>li {
  width: 100%;
  height: 0.21rem;
  list-style: none;
  background: rgba(190,190,190,.2);
  border-radius: 0.05rem;
}

.ant-skeleton-content .ant-skeleton-paragraph>li:last-child:not(:first-child):not(:nth-child(2)) {
  width: 61%;
}

.ant-skeleton-content .ant-skeleton-paragraph>li+li {
  margin-top: 0.21rem;
}

.ant-skeleton-with-avatar .ant-skeleton-content .ant-skeleton-title {
  margin-top: 0.16rem;
}

.ant-skeleton-with-avatar .ant-skeleton-content .ant-skeleton-title+.ant-skeleton-paragraph {
  margin-top: 0.37rem;
}

.ant-skeleton-round .ant-skeleton-content .ant-skeleton-title,
.ant-skeleton-round .ant-skeleton-content .ant-skeleton-paragraph>li {
  border-radius: 1.33rem;
}

.ant-skeleton.ant-skeleton-active .ant-skeleton-content .ant-skeleton-title,
.ant-skeleton.ant-skeleton-active .ant-skeleton-content .ant-skeleton-paragraph>li {
  position: relative;
  overflow: hidden;
  background: #fff;
}

.ant-skeleton.ant-skeleton-active .ant-skeleton-content .ant-skeleton-title::after,
.ant-skeleton.ant-skeleton-active .ant-skeleton-content .ant-skeleton-paragraph>li::after {
  position: absolute;
  top: 0;
  right: -150%;
  bottom: 0;
  left: -150%;
  background: linear-gradient(90deg, rgba(190, 190, 190, 0.2) 25%, rgba(129, 129, 129, 0.24) 37%, rgba(190, 190, 190, 0.2) 63%);
  animation: ant-skeleton-loading 1.4s ease infinite;
  content: "";
}

.ant-skeleton.ant-skeleton-active .ant-skeleton-avatar {
  position: relative;
  overflow: hidden;
  background: #fff;
}

.ant-skeleton.ant-skeleton-active .ant-skeleton-avatar::after {
  position: absolute;
  top: 0;
  right: -150%;
  bottom: 0;
  left: -150%;
  background: linear-gradient(90deg, rgba(190, 190, 190, 0.2) 25%, rgba(129, 129, 129, 0.24) 37%, rgba(190, 190, 190, 0.2) 63%);
  animation: ant-skeleton-loading 1.4s ease infinite;
  content: "";
}

.ant-skeleton.ant-skeleton-active .ant-skeleton-button {
  position: relative;
  overflow: hidden;
  background: #fff;
}

.ant-skeleton.ant-skeleton-active .ant-skeleton-button::after {
  position: absolute;
  top: 0;
  right: -150%;
  bottom: 0;
  left: -150%;
  background: linear-gradient(90deg, rgba(190, 190, 190, 0.2) 25%, rgba(129, 129, 129, 0.24) 37%, rgba(190, 190, 190, 0.2) 63%);
  animation: ant-skeleton-loading 1.4s ease infinite;
  content: "";
}

.ant-skeleton.ant-skeleton-active .ant-skeleton-input {
  position: relative;
  overflow: hidden;
  background: #fff;
}

.ant-skeleton.ant-skeleton-active .ant-skeleton-input::after {
  position: absolute;
  top: 0;
  right: -150%;
  bottom: 0;
  left: -150%;
  background: linear-gradient(90deg, rgba(190, 190, 190, 0.2) 25%, rgba(129, 129, 129, 0.24) 37%, rgba(190, 190, 190, 0.2) 63%);
  animation: ant-skeleton-loading 1.4s ease infinite;
  content: "";
}

.ant-skeleton.ant-skeleton-active .ant-skeleton-image {
  position: relative;
  overflow: hidden;
  background: #fff;
}

.ant-skeleton.ant-skeleton-active .ant-skeleton-image::after {
  position: absolute;
  top: 0;
  right: -150%;
  bottom: 0;
  left: -150%;
  background: linear-gradient(90deg, rgba(190, 190, 190, 0.2) 25%, rgba(129, 129, 129, 0.24) 37%, rgba(190, 190, 190, 0.2) 63%);
  animation: ant-skeleton-loading 1.4s ease infinite;
  content: "";
}

.ant-skeleton.ant-skeleton-block {
  width: 100%;
}

.ant-skeleton.ant-skeleton-block .ant-skeleton-button {
  width: 100%;
}

.ant-skeleton.ant-skeleton-block .ant-skeleton-input {
  width: 100%;
}

.ant-skeleton-element {
  display: inline-block;
  width: auto;
}

.ant-skeleton-element .ant-skeleton-button {
  display: inline-block;
  vertical-align: top;
  background: rgba(190,190,190,.2);
  border-radius: 0.03rem;
  width: 0.85rem;
  min-width: 0.85rem;
  height: 0.43rem;
  line-height: 0.43rem;
}

.ant-skeleton-element .ant-skeleton-button.ant-skeleton-button-circle {
  width: 0.43rem;
  min-width: 0.43rem;
  border-radius: 50%;
}

.ant-skeleton-element .ant-skeleton-button.ant-skeleton-button-round {
  border-radius: 0.43rem;
}

.ant-skeleton-element .ant-skeleton-button-lg {
  width: 1.07rem;
  min-width: 1.07rem;
  height: 0.53rem;
  line-height: 0.53rem;
}

.ant-skeleton-element .ant-skeleton-button-lg.ant-skeleton-button-circle {
  width: 0.53rem;
  min-width: 0.53rem;
  border-radius: 50%;
}

.ant-skeleton-element .ant-skeleton-button-lg.ant-skeleton-button-round {
  border-radius: 0.53rem;
}

.ant-skeleton-element .ant-skeleton-button-sm {
  width: 0.64rem;
  min-width: 0.64rem;
  height: 0.32rem;
  line-height: 0.32rem;
}

.ant-skeleton-element .ant-skeleton-button-sm.ant-skeleton-button-circle {
  width: 0.32rem;
  min-width: 0.32rem;
  border-radius: 50%;
}

.ant-skeleton-element .ant-skeleton-button-sm.ant-skeleton-button-round {
  border-radius: 0.32rem;
}

.ant-skeleton-element .ant-skeleton-avatar {
  display: inline-block;
  vertical-align: top;
  background: rgba(190,190,190,.2);
  width: 0.43rem;
  height: 0.43rem;
  line-height: 0.43rem;
}

.ant-skeleton-element .ant-skeleton-avatar.ant-skeleton-avatar-circle {
  border-radius: 50%;
}

.ant-skeleton-element .ant-skeleton-avatar-lg {
  width: 0.53rem;
  height: 0.53rem;
  line-height: 0.53rem;
}

.ant-skeleton-element .ant-skeleton-avatar-lg.ant-skeleton-avatar-circle {
  border-radius: 50%;
}

.ant-skeleton-element .ant-skeleton-avatar-sm {
  width: 0.32rem;
  height: 0.32rem;
  line-height: 0.32rem;
}

.ant-skeleton-element .ant-skeleton-avatar-sm.ant-skeleton-avatar-circle {
  border-radius: 50%;
}

.ant-skeleton-element .ant-skeleton-input {
  display: inline-block;
  vertical-align: top;
  background: rgba(190,190,190,.2);
  width: 2.13rem;
  min-width: 2.13rem;
  height: 0.43rem;
  line-height: 0.43rem;
}

.ant-skeleton-element .ant-skeleton-input-lg {
  width: 2.67rem;
  min-width: 2.67rem;
  height: 0.53rem;
  line-height: 0.53rem;
}

.ant-skeleton-element .ant-skeleton-input-sm {
  width: 1.6rem;
  min-width: 1.6rem;
  height: 0.32rem;
  line-height: 0.32rem;
}

.ant-skeleton-element .ant-skeleton-image {
  display: flex;
  align-items: center;
  justify-content: center;
  vertical-align: top;
  background: rgba(190,190,190,.2);
  width: 1.28rem;
  height: 1.28rem;
  line-height: 1.28rem;
}

.ant-skeleton-element .ant-skeleton-image.ant-skeleton-image-circle {
  border-radius: 50%;
}

.ant-skeleton-element .ant-skeleton-image-path {
  fill: #bfbfbf;
}

.ant-skeleton-element .ant-skeleton-image-svg {
  width: 0.64rem;
  height: 0.64rem;
  line-height: 0.64rem;
  max-width: 2.56rem;
  max-height: 2.56rem;
}

.ant-skeleton-element .ant-skeleton-image-svg.ant-skeleton-image-circle {
  border-radius: 50%;
}

@keyframes ant-skeleton-loading {
  0% {
    transform: translateX(-37.5%);
  }

  100% {
    transform: translateX(37.5%);
  }
}

.ant-skeleton-rtl {
  direction: rtl;
}

.ant-skeleton-rtl .ant-skeleton-header {
  padding-right: 0;
  padding-left: 0.21rem;
}

.ant-skeleton-rtl.ant-skeleton.ant-skeleton-active .ant-skeleton-content .ant-skeleton-title,
.ant-skeleton-rtl.ant-skeleton.ant-skeleton-active .ant-skeleton-content .ant-skeleton-paragraph>li {
  animation-name: ant-skeleton-loading-rtl;
}

.ant-skeleton-rtl.ant-skeleton.ant-skeleton-active .ant-skeleton-avatar {
  animation-name: ant-skeleton-loading-rtl;
}

@keyframes ant-skeleton-loading-rtl {
  0% {
    background-position: 0% 50%;
  }

  100% {
    background-position: 100% 50%;
  }
}