.ant-drawer {
  position: fixed;
  z-index: 1000;
  width: 0%;
  height: 100%;
  transition: width 0s ease .3s,height 0s ease .3s;
}

.ant-drawer-content-wrapper {
  position: absolute;
  width: 100%;
  height: 100%;
  transition: transform .3s cubic-bezier(0.23, 1, 0.32, 1),box-shadow .3s cubic-bezier(0.23, 1, 0.32, 1);
}

.ant-drawer .ant-drawer-content {
  width: 100%;
  height: 100%;
}

.ant-drawer-left,
.ant-drawer-right {
  top: 0;
  width: 0%;
  height: 100%;
}

.ant-drawer-left .ant-drawer-content-wrapper,
.ant-drawer-right .ant-drawer-content-wrapper {
  height: 100%;
}

.ant-drawer-left.ant-drawer-open,
.ant-drawer-right.ant-drawer-open {
  width: 100%;
  transition: transform .3s cubic-bezier(0.23, 1, 0.32, 1);
}

.ant-drawer-left {
  left: 0;
}

.ant-drawer-left .ant-drawer-content-wrapper {
  left: 0;
}

.ant-drawer-left.ant-drawer-open .ant-drawer-content-wrapper {
  box-shadow: 0.08rem 0 0.21rem -0.11rem rgba(0,0,0,.08),0.12rem 0 0.37rem 0 rgba(0,0,0,.05),0.16rem 0 0.64rem 0.21rem rgba(0,0,0,.03);
}

.ant-drawer-right {
  right: 0;
}

.ant-drawer-right .ant-drawer-content-wrapper {
  right: 0;
}

.ant-drawer-right.ant-drawer-open .ant-drawer-content-wrapper {
  box-shadow: -0.08rem 0 0.21rem -0.11rem rgba(0,0,0,.08),-0.12rem 0 0.37rem 0 rgba(0,0,0,.05),-0.16rem 0 0.64rem 0.21rem rgba(0,0,0,.03);
}

.ant-drawer-right.ant-drawer-open.no-mask {
  right: 0.01rem;
  transform: translateX(0.01rem);
}

.ant-drawer-top,
.ant-drawer-bottom {
  left: 0;
  width: 100%;
  height: 0%;
}

.ant-drawer-top .ant-drawer-content-wrapper,
.ant-drawer-bottom .ant-drawer-content-wrapper {
  width: 100%;
}

.ant-drawer-top.ant-drawer-open,
.ant-drawer-bottom.ant-drawer-open {
  height: 100%;
  transition: transform .3s cubic-bezier(0.23, 1, 0.32, 1);
}

.ant-drawer-top {
  top: 0;
}

.ant-drawer-top.ant-drawer-open .ant-drawer-content-wrapper {
  box-shadow: 0 0.08rem 0.21rem -0.11rem rgba(0,0,0,.08),0 0.12rem 0.37rem 0 rgba(0,0,0,.05),0 0.16rem 0.64rem 0.21rem rgba(0,0,0,.03);
}

.ant-drawer-bottom {
  bottom: 0;
}

.ant-drawer-bottom .ant-drawer-content-wrapper {
  bottom: 0;
}

.ant-drawer-bottom.ant-drawer-open .ant-drawer-content-wrapper {
  box-shadow: 0 -0.08rem 0.21rem -0.11rem rgba(0,0,0,.08),0 -0.12rem 0.37rem 0 rgba(0,0,0,.05),0 -0.16rem 0.64rem 0.21rem rgba(0,0,0,.03);
}

.ant-drawer-bottom.ant-drawer-open.no-mask {
  bottom: 0.01rem;
  transform: translateY(0.01rem);
}

.ant-drawer.ant-drawer-open .ant-drawer-mask {
  height: 100%;
  opacity: 1;
  transition: none;
  animation: antdDrawerFadeIn .3s cubic-bezier(0.23, 1, 0.32, 1);
  pointer-events: auto;
}

.ant-drawer-title {
  flex: 1 1;
  margin: 0;
  color: rgba(0,0,0,.85);
  font-weight: 500;
  font-size: 0.21rem;
  line-height: 0.29rem;
}

.ant-drawer-content {
  position: relative;
  z-index: 1;
  overflow: auto;
  background-color: #fff;
  background-clip: padding-box;
  border: 0;
}

.ant-drawer-close {
  display: inline-block;
  margin-right: 0.16rem;
  color: rgba(0,0,0,.45);
  font-weight: 700;
  font-size: 0.21rem;
  font-style: normal;
  line-height: 1;
  text-align: center;
  text-transform: none;
  text-decoration: none;
  background: rgba(0,0,0,0);
  border: 0;
  outline: 0;
  cursor: pointer;
  transition: color .3s;
  text-rendering: auto;
}

.ant-drawer-close:focus,
.ant-drawer-close:hover {
  color: rgba(0,0,0,.75);
  text-decoration: none;
}

.ant-drawer-header {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0.21rem 0.32rem;
  color: rgba(0,0,0,.85);
  background: #fff;
  border-bottom: 0.01rem solid #f0f0f0;
  border-radius: 0.03rem 0.03rem 0 0;
}

.ant-drawer-header-title {
  display: flex;
  flex: 1 1;
  align-items: center;
  justify-content: space-between;
}

.ant-drawer-header-close-only {
  padding-bottom: 0;
  border: none;
}

.ant-drawer-wrapper-body {
  display: flex;
  flex-flow: column nowrap;
  width: 100%;
  height: 100%;
}

.ant-drawer-body {
  flex-grow: 1;
  padding: 0.32rem;
  overflow: auto;
  font-size: 0.19rem;
  line-height: 1.5715;
  word-wrap: break-word;
}

.ant-drawer-footer {
  flex-shrink: 0;
  padding: 0.13rem 0.21rem;
  border-top: 0.01rem solid #f0f0f0;
}

.ant-drawer-mask {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 0;
  background-color: rgba(0,0,0,.45);
  opacity: 0;
  transition: opacity .3s linear,height 0s ease .3s;
  pointer-events: none;
}

.ant-drawer .ant-picker-clear {
  background: #fff;
}

@keyframes antdDrawerFadeIn {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

.ant-drawer-rtl {
  direction: rtl;
}

.ant-drawer-rtl .ant-drawer-close {
  margin-right: 0;
  margin-left: 0.16rem;
}