.ant-message {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: rgba(0,0,0,.85);
  font-size: 0.19rem;
  font-variant: tabular-nums;
  line-height: 1.5715;
  list-style: none;
  font-feature-settings: "tnum";
  position: fixed;
  top: 0.11rem;
  left: 0;
  z-index: 1010;
  width: 100%;
  pointer-events: none;
}

.ant-message-notice {
  padding: 0.11rem;
  text-align: center;
}

.ant-message-notice-content {
  display: inline-block;
  padding: 0.13rem 0.21rem;
  background: #fff;
  border-radius: 0.03rem;
  box-shadow: 0 0.04rem 0.08rem -0.05rem rgba(0,0,0,.12),0 0.08rem 0.21rem 0 rgba(0,0,0,.08),0 0.12rem 0.37rem 0.11rem rgba(0,0,0,.05);
  pointer-events: all;
}

.ant-message-success .anticon {
  color: #52c41a;
}

.ant-message-error .anticon {
  color: #ff4d4f;
}

.ant-message-warning .anticon {
  color: #faad14;
}

.ant-message-info .anticon,
.ant-message-loading .anticon {
  color: #f9be00;
}

.ant-message .anticon {
  position: relative;
  top: 0.01rem;
  margin-right: 0.11rem;
  font-size: 0.21rem;
}

.ant-message-notice.ant-move-up-leave.ant-move-up-leave-active {
  animation-name: MessageMoveOut;
  animation-duration: .3s;
}

@keyframes MessageMoveOut {
  0% {
    max-height: 2rem;
    padding: 0.11rem;
    opacity: 1;
  }

  100% {
    max-height: 0;
    padding: 0;
    opacity: 0;
  }
}

.ant-message-rtl {
  direction: rtl;
}

.ant-message-rtl span {
  direction: rtl;
}

.ant-message-rtl .anticon {
  margin-right: 0;
  margin-left: 0.11rem;
}