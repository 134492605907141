.ant-switch {
  margin: 0;
  padding: 0;
  color: rgba(0,0,0,.85);
  font-size: 0.19rem;
  font-variant: tabular-nums;
  line-height: 1.5715;
  list-style: none;
  font-feature-settings: "tnum";
  position: relative;
  display: inline-block;
  box-sizing: border-box;
  min-width: 0.59rem;
  height: 0.29rem;
  line-height: 0.29rem;
  vertical-align: middle;
  background-image: linear-gradient(to right, rgba(0, 0, 0, 0.25), rgba(0, 0, 0, 0.25)),linear-gradient(to right, #fff, #fff);
  border: 0;
  border-radius: 1.33rem;
  cursor: pointer;
  transition: all .2s;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.ant-switch:focus {
  outline: 0;
  box-shadow: 0 0 0 0.03rem rgba(0,0,0,.1);
}

.ant-switch-checked:focus {
  box-shadow: 0 0 0 0.03rem #fffde6;
}

.ant-switch:focus:hover {
  box-shadow: none;
}

.ant-switch-checked {
  background: #f9be00;
}

.ant-switch-loading,
.ant-switch-disabled {
  cursor: not-allowed;
  opacity: .4;
}

.ant-switch-loading *,
.ant-switch-disabled * {
  box-shadow: none;
  cursor: not-allowed;
}

.ant-switch-inner {
  display: block;
  margin: 0 0.09rem 0 0.33rem;
  color: #fff;
  font-size: 0.16rem;
  transition: margin .2s;
}

.ant-switch-checked .ant-switch-inner {
  margin: 0 0.33rem 0 0.09rem;
}

.ant-switch-handle {
  position: absolute;
  top: 0.03rem;
  left: 0.03rem;
  width: 0.24rem;
  height: 0.24rem;
  transition: all .2s ease-in-out;
}

.ant-switch-handle::before {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: #fff;
  border-radius: 0.12rem;
  box-shadow: 0 0.03rem 0.05rem 0 rgba(0,35,11,.2);
  transition: all .2s ease-in-out;
  content: "";
}

.ant-switch-checked .ant-switch-handle {
  left: calc(100% - 0.24rem - 0.03rem);
}

.ant-switch:not(.ant-switch-disabled):active .ant-switch-handle::before {
  right: -30%;
  left: 0;
}

.ant-switch:not(.ant-switch-disabled):active.ant-switch-checked .ant-switch-handle::before {
  right: 0;
  left: -30%;
}

.ant-switch-loading-icon.anticon {
  position: relative;
  top: 0.03rem;
  color: rgba(0,0,0,.65);
  vertical-align: top;
}

.ant-switch-checked .ant-switch-loading-icon {
  color: #f9be00;
}

.ant-switch-small {
  min-width: 0.37rem;
  height: 0.21rem;
  line-height: 0.21rem;
}

.ant-switch-small .ant-switch-inner {
  margin: 0 0.07rem 0 0.24rem;
  font-size: 0.16rem;
}

.ant-switch-small .ant-switch-handle {
  width: 0.16rem;
  height: 0.16rem;
}

.ant-switch-small .ant-switch-loading-icon {
  top: 0.02rem;
  font-size: 0.12rem;
}

.ant-switch-small.ant-switch-checked .ant-switch-inner {
  margin: 0 0.24rem 0 0.07rem;
}

.ant-switch-small.ant-switch-checked .ant-switch-handle {
  left: calc(100% - 0.16rem - 0.03rem);
}

.ant-switch-rtl {
  direction: rtl;
}

.ant-switch-rtl .ant-switch-inner {
  margin: 0 0.33rem 0 0.09rem;
}

.ant-switch-rtl .ant-switch-handle {
  right: 0.03rem;
  left: auto;
}

.ant-switch-rtl:not(.ant-switch-rtl-disabled):active .ant-switch-handle::before {
  right: 0;
  left: -30%;
}

.ant-switch-rtl:not(.ant-switch-rtl-disabled):active.ant-switch-checked .ant-switch-handle::before {
  right: -30%;
  left: 0;
}

.ant-switch-rtl.ant-switch-checked .ant-switch-inner {
  margin: 0 0.09rem 0 0.33rem;
}

.ant-switch-rtl.ant-switch-checked .ant-switch-handle {
  right: calc(100% - 0.24rem - 0.03rem);
}

.ant-switch-rtl.ant-switch-small.ant-switch-checked .ant-switch-handle {
  right: calc(100% - 0.16rem - 0.03rem);
}