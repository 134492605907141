.ant-btn {
  line-height: 1.5715;
  position: relative;
  display: inline-block;
  font-weight: 400;
  white-space: nowrap;
  text-align: center;
  background-image: none;
  border: 0.01rem solid rgba(0,0,0,0);
  box-shadow: 0 0.03rem 0 rgba(0,0,0,.015);
  cursor: pointer;
  transition: all .3s cubic-bezier(0.645, 0.045, 0.355, 1);
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  touch-action: manipulation;
  height: 0.43rem;
  padding: 0.05rem 0.2rem;
  font-size: 0.19rem;
  border-radius: 0.03rem;
  color: rgba(0,0,0,.85);
  border-color: #d9d9d9;
  background: #fff;
}

.ant-btn>.anticon {
  line-height: 1;
}

.ant-btn,
.ant-btn:active,
.ant-btn:focus {
  outline: 0;
}

.ant-btn:not([disabled]):hover {
  text-decoration: none;
}

.ant-btn:not([disabled]):active {
  outline: 0;
  box-shadow: none;
}

.ant-btn[disabled] {
  cursor: not-allowed;
}

.ant-btn[disabled]>* {
  pointer-events: none;
}

.ant-btn-lg {
  height: 0.53rem;
  padding: 0.09rem 0.2rem;
  font-size: 0.21rem;
  border-radius: 0.03rem;
}

.ant-btn-sm {
  height: 0.32rem;
  padding: 0 0.09rem;
  font-size: 0.19rem;
  border-radius: 0.03rem;
}

.ant-btn>a:only-child {
  color: currentcolor;
}

.ant-btn>a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: rgba(0,0,0,0);
  content: "";
}

.ant-btn:hover,
.ant-btn:focus {
  color: #ffd429;
  border-color: #ffd429;
  background: #fff;
}

.ant-btn:hover>a:only-child,
.ant-btn:focus>a:only-child {
  color: currentcolor;
}

.ant-btn:hover>a:only-child::after,
.ant-btn:focus>a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: rgba(0,0,0,0);
  content: "";
}

.ant-btn:active {
  color: #d49b00;
  border-color: #d49b00;
  background: #fff;
}

.ant-btn:active>a:only-child {
  color: currentcolor;
}

.ant-btn:active>a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: rgba(0,0,0,0);
  content: "";
}

.ant-btn[disabled],
.ant-btn[disabled]:hover,
.ant-btn[disabled]:focus,
.ant-btn[disabled]:active {
  color: rgba(0,0,0,.25);
  border-color: #d9d9d9;
  background: #f5f5f5;
  text-shadow: none;
  box-shadow: none;
}

.ant-btn[disabled]>a:only-child,
.ant-btn[disabled]:hover>a:only-child,
.ant-btn[disabled]:focus>a:only-child,
.ant-btn[disabled]:active>a:only-child {
  color: currentcolor;
}

.ant-btn[disabled]>a:only-child::after,
.ant-btn[disabled]:hover>a:only-child::after,
.ant-btn[disabled]:focus>a:only-child::after,
.ant-btn[disabled]:active>a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: rgba(0,0,0,0);
  content: "";
}

.ant-btn:hover,
.ant-btn:focus,
.ant-btn:active {
  text-decoration: none;
  background: #fff;
}

.ant-btn>span {
  display: inline-block;
}

.ant-btn-primary {
  color: #fff;
  border-color: #f9be00;
  background: #f9be00;
  text-shadow: 0 -0.01rem 0 rgba(0,0,0,.12);
  box-shadow: 0 0.03rem 0 rgba(0,0,0,.045);
}

.ant-btn-primary>a:only-child {
  color: currentcolor;
}

.ant-btn-primary>a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: rgba(0,0,0,0);
  content: "";
}

.ant-btn-primary:hover,
.ant-btn-primary:focus {
  color: #fff;
  border-color: #ffd429;
  background: #ffd429;
}

.ant-btn-primary:hover>a:only-child,
.ant-btn-primary:focus>a:only-child {
  color: currentcolor;
}

.ant-btn-primary:hover>a:only-child::after,
.ant-btn-primary:focus>a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: rgba(0,0,0,0);
  content: "";
}

.ant-btn-primary:active {
  color: #fff;
  border-color: #d49b00;
  background: #d49b00;
}

.ant-btn-primary:active>a:only-child {
  color: currentcolor;
}

.ant-btn-primary:active>a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: rgba(0,0,0,0);
  content: "";
}

.ant-btn-primary[disabled],
.ant-btn-primary[disabled]:hover,
.ant-btn-primary[disabled]:focus,
.ant-btn-primary[disabled]:active {
  color: rgba(0,0,0,.25);
  border-color: #d9d9d9;
  background: #f5f5f5;
  text-shadow: none;
  box-shadow: none;
}

.ant-btn-primary[disabled]>a:only-child,
.ant-btn-primary[disabled]:hover>a:only-child,
.ant-btn-primary[disabled]:focus>a:only-child,
.ant-btn-primary[disabled]:active>a:only-child {
  color: currentcolor;
}

.ant-btn-primary[disabled]>a:only-child::after,
.ant-btn-primary[disabled]:hover>a:only-child::after,
.ant-btn-primary[disabled]:focus>a:only-child::after,
.ant-btn-primary[disabled]:active>a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: rgba(0,0,0,0);
  content: "";
}

.ant-btn-group .ant-btn-primary:not(:first-child):not(:last-child) {
  border-right-color: #ffd429;
  border-left-color: #ffd429;
}

.ant-btn-group .ant-btn-primary:not(:first-child):not(:last-child):disabled {
  border-color: #d9d9d9;
}

.ant-btn-group .ant-btn-primary:first-child:not(:last-child) {
  border-right-color: #ffd429;
}

.ant-btn-group .ant-btn-primary:first-child:not(:last-child)[disabled] {
  border-right-color: #d9d9d9;
}

.ant-btn-group .ant-btn-primary:last-child:not(:first-child),
.ant-btn-group .ant-btn-primary+.ant-btn-primary {
  border-left-color: #ffd429;
}

.ant-btn-group .ant-btn-primary:last-child:not(:first-child)[disabled],
.ant-btn-group .ant-btn-primary+.ant-btn-primary[disabled] {
  border-left-color: #d9d9d9;
}

.ant-btn-ghost {
  color: rgba(0,0,0,.85);
  border-color: #d9d9d9;
  background: rgba(0,0,0,0);
}

.ant-btn-ghost>a:only-child {
  color: currentcolor;
}

.ant-btn-ghost>a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: rgba(0,0,0,0);
  content: "";
}

.ant-btn-ghost:hover,
.ant-btn-ghost:focus {
  color: #ffd429;
  border-color: #ffd429;
  background: rgba(0,0,0,0);
}

.ant-btn-ghost:hover>a:only-child,
.ant-btn-ghost:focus>a:only-child {
  color: currentcolor;
}

.ant-btn-ghost:hover>a:only-child::after,
.ant-btn-ghost:focus>a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: rgba(0,0,0,0);
  content: "";
}

.ant-btn-ghost:active {
  color: #d49b00;
  border-color: #d49b00;
  background: rgba(0,0,0,0);
}

.ant-btn-ghost:active>a:only-child {
  color: currentcolor;
}

.ant-btn-ghost:active>a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: rgba(0,0,0,0);
  content: "";
}

.ant-btn-ghost[disabled],
.ant-btn-ghost[disabled]:hover,
.ant-btn-ghost[disabled]:focus,
.ant-btn-ghost[disabled]:active {
  color: rgba(0,0,0,.25);
  border-color: #d9d9d9;
  background: #f5f5f5;
  text-shadow: none;
  box-shadow: none;
}

.ant-btn-ghost[disabled]>a:only-child,
.ant-btn-ghost[disabled]:hover>a:only-child,
.ant-btn-ghost[disabled]:focus>a:only-child,
.ant-btn-ghost[disabled]:active>a:only-child {
  color: currentcolor;
}

.ant-btn-ghost[disabled]>a:only-child::after,
.ant-btn-ghost[disabled]:hover>a:only-child::after,
.ant-btn-ghost[disabled]:focus>a:only-child::after,
.ant-btn-ghost[disabled]:active>a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: rgba(0,0,0,0);
  content: "";
}

.ant-btn-dashed {
  color: rgba(0,0,0,.85);
  border-color: #d9d9d9;
  background: #fff;
  border-style: dashed;
}

.ant-btn-dashed>a:only-child {
  color: currentcolor;
}

.ant-btn-dashed>a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: rgba(0,0,0,0);
  content: "";
}

.ant-btn-dashed:hover,
.ant-btn-dashed:focus {
  color: #ffd429;
  border-color: #ffd429;
  background: #fff;
}

.ant-btn-dashed:hover>a:only-child,
.ant-btn-dashed:focus>a:only-child {
  color: currentcolor;
}

.ant-btn-dashed:hover>a:only-child::after,
.ant-btn-dashed:focus>a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: rgba(0,0,0,0);
  content: "";
}

.ant-btn-dashed:active {
  color: #d49b00;
  border-color: #d49b00;
  background: #fff;
}

.ant-btn-dashed:active>a:only-child {
  color: currentcolor;
}

.ant-btn-dashed:active>a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: rgba(0,0,0,0);
  content: "";
}

.ant-btn-dashed[disabled],
.ant-btn-dashed[disabled]:hover,
.ant-btn-dashed[disabled]:focus,
.ant-btn-dashed[disabled]:active {
  color: rgba(0,0,0,.25);
  border-color: #d9d9d9;
  background: #f5f5f5;
  text-shadow: none;
  box-shadow: none;
}

.ant-btn-dashed[disabled]>a:only-child,
.ant-btn-dashed[disabled]:hover>a:only-child,
.ant-btn-dashed[disabled]:focus>a:only-child,
.ant-btn-dashed[disabled]:active>a:only-child {
  color: currentcolor;
}

.ant-btn-dashed[disabled]>a:only-child::after,
.ant-btn-dashed[disabled]:hover>a:only-child::after,
.ant-btn-dashed[disabled]:focus>a:only-child::after,
.ant-btn-dashed[disabled]:active>a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: rgba(0,0,0,0);
  content: "";
}

.ant-btn-danger {
  color: #fff;
  border-color: #ff4d4f;
  background: #ff4d4f;
  text-shadow: 0 -0.01rem 0 rgba(0,0,0,.12);
  box-shadow: 0 0.03rem 0 rgba(0,0,0,.045);
}

.ant-btn-danger>a:only-child {
  color: currentcolor;
}

.ant-btn-danger>a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: rgba(0,0,0,0);
  content: "";
}

.ant-btn-danger:hover,
.ant-btn-danger:focus {
  color: #fff;
  border-color: #ff7875;
  background: #ff7875;
}

.ant-btn-danger:hover>a:only-child,
.ant-btn-danger:focus>a:only-child {
  color: currentcolor;
}

.ant-btn-danger:hover>a:only-child::after,
.ant-btn-danger:focus>a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: rgba(0,0,0,0);
  content: "";
}

.ant-btn-danger:active {
  color: #fff;
  border-color: #d9363e;
  background: #d9363e;
}

.ant-btn-danger:active>a:only-child {
  color: currentcolor;
}

.ant-btn-danger:active>a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: rgba(0,0,0,0);
  content: "";
}

.ant-btn-danger[disabled],
.ant-btn-danger[disabled]:hover,
.ant-btn-danger[disabled]:focus,
.ant-btn-danger[disabled]:active {
  color: rgba(0,0,0,.25);
  border-color: #d9d9d9;
  background: #f5f5f5;
  text-shadow: none;
  box-shadow: none;
}

.ant-btn-danger[disabled]>a:only-child,
.ant-btn-danger[disabled]:hover>a:only-child,
.ant-btn-danger[disabled]:focus>a:only-child,
.ant-btn-danger[disabled]:active>a:only-child {
  color: currentcolor;
}

.ant-btn-danger[disabled]>a:only-child::after,
.ant-btn-danger[disabled]:hover>a:only-child::after,
.ant-btn-danger[disabled]:focus>a:only-child::after,
.ant-btn-danger[disabled]:active>a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: rgba(0,0,0,0);
  content: "";
}

.ant-btn-link {
  color: #f9be00;
  border-color: rgba(0,0,0,0);
  background: rgba(0,0,0,0);
  box-shadow: none;
}

.ant-btn-link>a:only-child {
  color: currentcolor;
}

.ant-btn-link>a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: rgba(0,0,0,0);
  content: "";
}

.ant-btn-link:hover,
.ant-btn-link:focus {
  color: #ffd429;
  border-color: #ffd429;
  background: rgba(0,0,0,0);
}

.ant-btn-link:hover>a:only-child,
.ant-btn-link:focus>a:only-child {
  color: currentcolor;
}

.ant-btn-link:hover>a:only-child::after,
.ant-btn-link:focus>a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: rgba(0,0,0,0);
  content: "";
}

.ant-btn-link:active {
  color: #d49b00;
  border-color: #d49b00;
  background: rgba(0,0,0,0);
}

.ant-btn-link:active>a:only-child {
  color: currentcolor;
}

.ant-btn-link:active>a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: rgba(0,0,0,0);
  content: "";
}

.ant-btn-link[disabled],
.ant-btn-link[disabled]:hover,
.ant-btn-link[disabled]:focus,
.ant-btn-link[disabled]:active {
  color: rgba(0,0,0,.25);
  border-color: #d9d9d9;
  background: #f5f5f5;
  text-shadow: none;
  box-shadow: none;
}

.ant-btn-link[disabled]>a:only-child,
.ant-btn-link[disabled]:hover>a:only-child,
.ant-btn-link[disabled]:focus>a:only-child,
.ant-btn-link[disabled]:active>a:only-child {
  color: currentcolor;
}

.ant-btn-link[disabled]>a:only-child::after,
.ant-btn-link[disabled]:hover>a:only-child::after,
.ant-btn-link[disabled]:focus>a:only-child::after,
.ant-btn-link[disabled]:active>a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: rgba(0,0,0,0);
  content: "";
}

.ant-btn-link:hover {
  background: rgba(0,0,0,0);
}

.ant-btn-link:hover,
.ant-btn-link:focus,
.ant-btn-link:active {
  border-color: rgba(0,0,0,0);
}

.ant-btn-link[disabled],
.ant-btn-link[disabled]:hover,
.ant-btn-link[disabled]:focus,
.ant-btn-link[disabled]:active {
  color: rgba(0,0,0,.25);
  border-color: rgba(0,0,0,0);
  background: rgba(0,0,0,0);
  text-shadow: none;
  box-shadow: none;
}

.ant-btn-link[disabled]>a:only-child,
.ant-btn-link[disabled]:hover>a:only-child,
.ant-btn-link[disabled]:focus>a:only-child,
.ant-btn-link[disabled]:active>a:only-child {
  color: currentcolor;
}

.ant-btn-link[disabled]>a:only-child::after,
.ant-btn-link[disabled]:hover>a:only-child::after,
.ant-btn-link[disabled]:focus>a:only-child::after,
.ant-btn-link[disabled]:active>a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: rgba(0,0,0,0);
  content: "";
}

.ant-btn-text {
  color: rgba(0,0,0,.85);
  border-color: rgba(0,0,0,0);
  background: rgba(0,0,0,0);
  box-shadow: none;
}

.ant-btn-text>a:only-child {
  color: currentcolor;
}

.ant-btn-text>a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: rgba(0,0,0,0);
  content: "";
}

.ant-btn-text:hover,
.ant-btn-text:focus {
  color: #ffd429;
  border-color: #ffd429;
  background: rgba(0,0,0,0);
}

.ant-btn-text:hover>a:only-child,
.ant-btn-text:focus>a:only-child {
  color: currentcolor;
}

.ant-btn-text:hover>a:only-child::after,
.ant-btn-text:focus>a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: rgba(0,0,0,0);
  content: "";
}

.ant-btn-text:active {
  color: #d49b00;
  border-color: #d49b00;
  background: rgba(0,0,0,0);
}

.ant-btn-text:active>a:only-child {
  color: currentcolor;
}

.ant-btn-text:active>a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: rgba(0,0,0,0);
  content: "";
}

.ant-btn-text[disabled],
.ant-btn-text[disabled]:hover,
.ant-btn-text[disabled]:focus,
.ant-btn-text[disabled]:active {
  color: rgba(0,0,0,.25);
  border-color: #d9d9d9;
  background: #f5f5f5;
  text-shadow: none;
  box-shadow: none;
}

.ant-btn-text[disabled]>a:only-child,
.ant-btn-text[disabled]:hover>a:only-child,
.ant-btn-text[disabled]:focus>a:only-child,
.ant-btn-text[disabled]:active>a:only-child {
  color: currentcolor;
}

.ant-btn-text[disabled]>a:only-child::after,
.ant-btn-text[disabled]:hover>a:only-child::after,
.ant-btn-text[disabled]:focus>a:only-child::after,
.ant-btn-text[disabled]:active>a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: rgba(0,0,0,0);
  content: "";
}

.ant-btn-text:hover,
.ant-btn-text:focus {
  color: rgba(0,0,0,.85);
  background: rgba(0,0,0,.018);
  border-color: rgba(0,0,0,0);
}

.ant-btn-text:active {
  color: rgba(0,0,0,.85);
  background: rgba(0,0,0,.028);
  border-color: rgba(0,0,0,0);
}

.ant-btn-text[disabled],
.ant-btn-text[disabled]:hover,
.ant-btn-text[disabled]:focus,
.ant-btn-text[disabled]:active {
  color: rgba(0,0,0,.25);
  border-color: rgba(0,0,0,0);
  background: rgba(0,0,0,0);
  text-shadow: none;
  box-shadow: none;
}

.ant-btn-text[disabled]>a:only-child,
.ant-btn-text[disabled]:hover>a:only-child,
.ant-btn-text[disabled]:focus>a:only-child,
.ant-btn-text[disabled]:active>a:only-child {
  color: currentcolor;
}

.ant-btn-text[disabled]>a:only-child::after,
.ant-btn-text[disabled]:hover>a:only-child::after,
.ant-btn-text[disabled]:focus>a:only-child::after,
.ant-btn-text[disabled]:active>a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: rgba(0,0,0,0);
  content: "";
}

.ant-btn-dangerous {
  color: #ff4d4f;
  border-color: #ff4d4f;
  background: #fff;
}

.ant-btn-dangerous>a:only-child {
  color: currentcolor;
}

.ant-btn-dangerous>a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: rgba(0,0,0,0);
  content: "";
}

.ant-btn-dangerous:hover,
.ant-btn-dangerous:focus {
  color: #ff7875;
  border-color: #ff7875;
  background: #fff;
}

.ant-btn-dangerous:hover>a:only-child,
.ant-btn-dangerous:focus>a:only-child {
  color: currentcolor;
}

.ant-btn-dangerous:hover>a:only-child::after,
.ant-btn-dangerous:focus>a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: rgba(0,0,0,0);
  content: "";
}

.ant-btn-dangerous:active {
  color: #d9363e;
  border-color: #d9363e;
  background: #fff;
}

.ant-btn-dangerous:active>a:only-child {
  color: currentcolor;
}

.ant-btn-dangerous:active>a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: rgba(0,0,0,0);
  content: "";
}

.ant-btn-dangerous[disabled],
.ant-btn-dangerous[disabled]:hover,
.ant-btn-dangerous[disabled]:focus,
.ant-btn-dangerous[disabled]:active {
  color: rgba(0,0,0,.25);
  border-color: #d9d9d9;
  background: #f5f5f5;
  text-shadow: none;
  box-shadow: none;
}

.ant-btn-dangerous[disabled]>a:only-child,
.ant-btn-dangerous[disabled]:hover>a:only-child,
.ant-btn-dangerous[disabled]:focus>a:only-child,
.ant-btn-dangerous[disabled]:active>a:only-child {
  color: currentcolor;
}

.ant-btn-dangerous[disabled]>a:only-child::after,
.ant-btn-dangerous[disabled]:hover>a:only-child::after,
.ant-btn-dangerous[disabled]:focus>a:only-child::after,
.ant-btn-dangerous[disabled]:active>a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: rgba(0,0,0,0);
  content: "";
}

.ant-btn-dangerous.ant-btn-primary {
  color: #fff;
  border-color: #ff4d4f;
  background: #ff4d4f;
  text-shadow: 0 -0.01rem 0 rgba(0,0,0,.12);
  box-shadow: 0 0.03rem 0 rgba(0,0,0,.045);
}

.ant-btn-dangerous.ant-btn-primary>a:only-child {
  color: currentcolor;
}

.ant-btn-dangerous.ant-btn-primary>a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: rgba(0,0,0,0);
  content: "";
}

.ant-btn-dangerous.ant-btn-primary:hover,
.ant-btn-dangerous.ant-btn-primary:focus {
  color: #fff;
  border-color: #ff7875;
  background: #ff7875;
}

.ant-btn-dangerous.ant-btn-primary:hover>a:only-child,
.ant-btn-dangerous.ant-btn-primary:focus>a:only-child {
  color: currentcolor;
}

.ant-btn-dangerous.ant-btn-primary:hover>a:only-child::after,
.ant-btn-dangerous.ant-btn-primary:focus>a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: rgba(0,0,0,0);
  content: "";
}

.ant-btn-dangerous.ant-btn-primary:active {
  color: #fff;
  border-color: #d9363e;
  background: #d9363e;
}

.ant-btn-dangerous.ant-btn-primary:active>a:only-child {
  color: currentcolor;
}

.ant-btn-dangerous.ant-btn-primary:active>a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: rgba(0,0,0,0);
  content: "";
}

.ant-btn-dangerous.ant-btn-primary[disabled],
.ant-btn-dangerous.ant-btn-primary[disabled]:hover,
.ant-btn-dangerous.ant-btn-primary[disabled]:focus,
.ant-btn-dangerous.ant-btn-primary[disabled]:active {
  color: rgba(0,0,0,.25);
  border-color: #d9d9d9;
  background: #f5f5f5;
  text-shadow: none;
  box-shadow: none;
}

.ant-btn-dangerous.ant-btn-primary[disabled]>a:only-child,
.ant-btn-dangerous.ant-btn-primary[disabled]:hover>a:only-child,
.ant-btn-dangerous.ant-btn-primary[disabled]:focus>a:only-child,
.ant-btn-dangerous.ant-btn-primary[disabled]:active>a:only-child {
  color: currentcolor;
}

.ant-btn-dangerous.ant-btn-primary[disabled]>a:only-child::after,
.ant-btn-dangerous.ant-btn-primary[disabled]:hover>a:only-child::after,
.ant-btn-dangerous.ant-btn-primary[disabled]:focus>a:only-child::after,
.ant-btn-dangerous.ant-btn-primary[disabled]:active>a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: rgba(0,0,0,0);
  content: "";
}

.ant-btn-dangerous.ant-btn-link {
  color: #ff4d4f;
  border-color: rgba(0,0,0,0);
  background: rgba(0,0,0,0);
  box-shadow: none;
}

.ant-btn-dangerous.ant-btn-link>a:only-child {
  color: currentcolor;
}

.ant-btn-dangerous.ant-btn-link>a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: rgba(0,0,0,0);
  content: "";
}

.ant-btn-dangerous.ant-btn-link:hover,
.ant-btn-dangerous.ant-btn-link:focus {
  color: #ffd429;
  border-color: #ffd429;
  background: rgba(0,0,0,0);
}

.ant-btn-dangerous.ant-btn-link:hover>a:only-child,
.ant-btn-dangerous.ant-btn-link:focus>a:only-child {
  color: currentcolor;
}

.ant-btn-dangerous.ant-btn-link:hover>a:only-child::after,
.ant-btn-dangerous.ant-btn-link:focus>a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: rgba(0,0,0,0);
  content: "";
}

.ant-btn-dangerous.ant-btn-link:active {
  color: #d49b00;
  border-color: #d49b00;
  background: rgba(0,0,0,0);
}

.ant-btn-dangerous.ant-btn-link:active>a:only-child {
  color: currentcolor;
}

.ant-btn-dangerous.ant-btn-link:active>a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: rgba(0,0,0,0);
  content: "";
}

.ant-btn-dangerous.ant-btn-link[disabled],
.ant-btn-dangerous.ant-btn-link[disabled]:hover,
.ant-btn-dangerous.ant-btn-link[disabled]:focus,
.ant-btn-dangerous.ant-btn-link[disabled]:active {
  color: rgba(0,0,0,.25);
  border-color: #d9d9d9;
  background: #f5f5f5;
  text-shadow: none;
  box-shadow: none;
}

.ant-btn-dangerous.ant-btn-link[disabled]>a:only-child,
.ant-btn-dangerous.ant-btn-link[disabled]:hover>a:only-child,
.ant-btn-dangerous.ant-btn-link[disabled]:focus>a:only-child,
.ant-btn-dangerous.ant-btn-link[disabled]:active>a:only-child {
  color: currentcolor;
}

.ant-btn-dangerous.ant-btn-link[disabled]>a:only-child::after,
.ant-btn-dangerous.ant-btn-link[disabled]:hover>a:only-child::after,
.ant-btn-dangerous.ant-btn-link[disabled]:focus>a:only-child::after,
.ant-btn-dangerous.ant-btn-link[disabled]:active>a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: rgba(0,0,0,0);
  content: "";
}

.ant-btn-dangerous.ant-btn-link:hover,
.ant-btn-dangerous.ant-btn-link:focus {
  color: #ff7875;
  border-color: rgba(0,0,0,0);
  background: rgba(0,0,0,0);
}

.ant-btn-dangerous.ant-btn-link:hover>a:only-child,
.ant-btn-dangerous.ant-btn-link:focus>a:only-child {
  color: currentcolor;
}

.ant-btn-dangerous.ant-btn-link:hover>a:only-child::after,
.ant-btn-dangerous.ant-btn-link:focus>a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: rgba(0,0,0,0);
  content: "";
}

.ant-btn-dangerous.ant-btn-link:active {
  color: #d9363e;
  border-color: rgba(0,0,0,0);
  background: rgba(0,0,0,0);
}

.ant-btn-dangerous.ant-btn-link:active>a:only-child {
  color: currentcolor;
}

.ant-btn-dangerous.ant-btn-link:active>a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: rgba(0,0,0,0);
  content: "";
}

.ant-btn-dangerous.ant-btn-link[disabled],
.ant-btn-dangerous.ant-btn-link[disabled]:hover,
.ant-btn-dangerous.ant-btn-link[disabled]:focus,
.ant-btn-dangerous.ant-btn-link[disabled]:active {
  color: rgba(0,0,0,.25);
  border-color: rgba(0,0,0,0);
  background: rgba(0,0,0,0);
  text-shadow: none;
  box-shadow: none;
}

.ant-btn-dangerous.ant-btn-link[disabled]>a:only-child,
.ant-btn-dangerous.ant-btn-link[disabled]:hover>a:only-child,
.ant-btn-dangerous.ant-btn-link[disabled]:focus>a:only-child,
.ant-btn-dangerous.ant-btn-link[disabled]:active>a:only-child {
  color: currentcolor;
}

.ant-btn-dangerous.ant-btn-link[disabled]>a:only-child::after,
.ant-btn-dangerous.ant-btn-link[disabled]:hover>a:only-child::after,
.ant-btn-dangerous.ant-btn-link[disabled]:focus>a:only-child::after,
.ant-btn-dangerous.ant-btn-link[disabled]:active>a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: rgba(0,0,0,0);
  content: "";
}

.ant-btn-dangerous.ant-btn-text {
  color: #ff4d4f;
  border-color: rgba(0,0,0,0);
  background: rgba(0,0,0,0);
  box-shadow: none;
}

.ant-btn-dangerous.ant-btn-text>a:only-child {
  color: currentcolor;
}

.ant-btn-dangerous.ant-btn-text>a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: rgba(0,0,0,0);
  content: "";
}

.ant-btn-dangerous.ant-btn-text:hover,
.ant-btn-dangerous.ant-btn-text:focus {
  color: #ffd429;
  border-color: #ffd429;
  background: rgba(0,0,0,0);
}

.ant-btn-dangerous.ant-btn-text:hover>a:only-child,
.ant-btn-dangerous.ant-btn-text:focus>a:only-child {
  color: currentcolor;
}

.ant-btn-dangerous.ant-btn-text:hover>a:only-child::after,
.ant-btn-dangerous.ant-btn-text:focus>a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: rgba(0,0,0,0);
  content: "";
}

.ant-btn-dangerous.ant-btn-text:active {
  color: #d49b00;
  border-color: #d49b00;
  background: rgba(0,0,0,0);
}

.ant-btn-dangerous.ant-btn-text:active>a:only-child {
  color: currentcolor;
}

.ant-btn-dangerous.ant-btn-text:active>a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: rgba(0,0,0,0);
  content: "";
}

.ant-btn-dangerous.ant-btn-text[disabled],
.ant-btn-dangerous.ant-btn-text[disabled]:hover,
.ant-btn-dangerous.ant-btn-text[disabled]:focus,
.ant-btn-dangerous.ant-btn-text[disabled]:active {
  color: rgba(0,0,0,.25);
  border-color: #d9d9d9;
  background: #f5f5f5;
  text-shadow: none;
  box-shadow: none;
}

.ant-btn-dangerous.ant-btn-text[disabled]>a:only-child,
.ant-btn-dangerous.ant-btn-text[disabled]:hover>a:only-child,
.ant-btn-dangerous.ant-btn-text[disabled]:focus>a:only-child,
.ant-btn-dangerous.ant-btn-text[disabled]:active>a:only-child {
  color: currentcolor;
}

.ant-btn-dangerous.ant-btn-text[disabled]>a:only-child::after,
.ant-btn-dangerous.ant-btn-text[disabled]:hover>a:only-child::after,
.ant-btn-dangerous.ant-btn-text[disabled]:focus>a:only-child::after,
.ant-btn-dangerous.ant-btn-text[disabled]:active>a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: rgba(0,0,0,0);
  content: "";
}

.ant-btn-dangerous.ant-btn-text:hover,
.ant-btn-dangerous.ant-btn-text:focus {
  color: #ff7875;
  border-color: rgba(0,0,0,0);
  background: rgba(0,0,0,.018);
}

.ant-btn-dangerous.ant-btn-text:hover>a:only-child,
.ant-btn-dangerous.ant-btn-text:focus>a:only-child {
  color: currentcolor;
}

.ant-btn-dangerous.ant-btn-text:hover>a:only-child::after,
.ant-btn-dangerous.ant-btn-text:focus>a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: rgba(0,0,0,0);
  content: "";
}

.ant-btn-dangerous.ant-btn-text:active {
  color: #d9363e;
  border-color: rgba(0,0,0,0);
  background: rgba(0,0,0,.028);
}

.ant-btn-dangerous.ant-btn-text:active>a:only-child {
  color: currentcolor;
}

.ant-btn-dangerous.ant-btn-text:active>a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: rgba(0,0,0,0);
  content: "";
}

.ant-btn-dangerous.ant-btn-text[disabled],
.ant-btn-dangerous.ant-btn-text[disabled]:hover,
.ant-btn-dangerous.ant-btn-text[disabled]:focus,
.ant-btn-dangerous.ant-btn-text[disabled]:active {
  color: rgba(0,0,0,.25);
  border-color: rgba(0,0,0,0);
  background: rgba(0,0,0,0);
  text-shadow: none;
  box-shadow: none;
}

.ant-btn-dangerous.ant-btn-text[disabled]>a:only-child,
.ant-btn-dangerous.ant-btn-text[disabled]:hover>a:only-child,
.ant-btn-dangerous.ant-btn-text[disabled]:focus>a:only-child,
.ant-btn-dangerous.ant-btn-text[disabled]:active>a:only-child {
  color: currentcolor;
}

.ant-btn-dangerous.ant-btn-text[disabled]>a:only-child::after,
.ant-btn-dangerous.ant-btn-text[disabled]:hover>a:only-child::after,
.ant-btn-dangerous.ant-btn-text[disabled]:focus>a:only-child::after,
.ant-btn-dangerous.ant-btn-text[disabled]:active>a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: rgba(0,0,0,0);
  content: "";
}

.ant-btn-icon-only {
  width: 0.43rem;
  height: 0.43rem;
  padding: 0.03rem 0;
  font-size: 0.21rem;
  border-radius: 0.03rem;
  vertical-align: -0.04rem;
}

.ant-btn-icon-only>* {
  font-size: 0.21rem;
}

.ant-btn-icon-only.ant-btn-lg {
  width: 0.53rem;
  height: 0.53rem;
  padding: 0.07rem 0;
  font-size: 0.24rem;
  border-radius: 0.03rem;
}

.ant-btn-icon-only.ant-btn-lg>* {
  font-size: 0.24rem;
}

.ant-btn-icon-only.ant-btn-sm {
  width: 0.32rem;
  height: 0.32rem;
  padding: 0 0;
  font-size: 0.19rem;
  border-radius: 0.03rem;
}

.ant-btn-icon-only.ant-btn-sm>* {
  font-size: 0.19rem;
}

.ant-btn-icon-only>.anticon {
  display: flex;
  justify-content: center;
}

a.ant-btn-icon-only {
  vertical-align: -0.01rem;
}

a.ant-btn-icon-only>.anticon {
  display: inline;
}

.ant-btn-round {
  height: 0.43rem;
  padding: 0.05rem 0.21rem;
  font-size: 0.19rem;
  border-radius: 0.43rem;
}

.ant-btn-round.ant-btn-lg {
  height: 0.53rem;
  padding: 0.09rem 0.27rem;
  font-size: 0.21rem;
  border-radius: 0.53rem;
}

.ant-btn-round.ant-btn-sm {
  height: 0.32rem;
  padding: 0 0.16rem;
  font-size: 0.19rem;
  border-radius: 0.32rem;
}

.ant-btn-round.ant-btn-icon-only {
  width: auto;
}

.ant-btn-circle {
  min-width: 0.43rem;
  padding-right: 0;
  padding-left: 0;
  text-align: center;
  border-radius: 50%;
}

.ant-btn-circle.ant-btn-lg {
  min-width: 0.53rem;
  border-radius: 50%;
}

.ant-btn-circle.ant-btn-sm {
  min-width: 0.32rem;
  border-radius: 50%;
}

.ant-btn::before {
  position: absolute;
  top: -0.01rem;
  right: -0.01rem;
  bottom: -0.01rem;
  left: -0.01rem;
  z-index: 1;
  display: none;
  background: #fff;
  border-radius: inherit;
  opacity: .35;
  transition: opacity .2s;
  content: "";
  pointer-events: none;
}

.ant-btn .anticon {
  transition: margin-left .3s cubic-bezier(0.645, 0.045, 0.355, 1);
}

.ant-btn .anticon.anticon-plus>svg,
.ant-btn .anticon.anticon-minus>svg {
  shape-rendering: optimizespeed;
}

.ant-btn.ant-btn-loading {
  position: relative;
  cursor: default;
}

.ant-btn.ant-btn-loading::before {
  display: block;
}

.ant-btn>.ant-btn-loading-icon {
  transition: width .3s cubic-bezier(0.645, 0.045, 0.355, 1),opacity .3s cubic-bezier(0.645, 0.045, 0.355, 1);
}

.ant-btn>.ant-btn-loading-icon .anticon {
  padding-right: 0.11rem;
  animation: none;
}

.ant-btn>.ant-btn-loading-icon .anticon svg {
  animation: loadingCircle 1s infinite linear;
}

.ant-btn>.ant-btn-loading-icon:only-child .anticon {
  padding-right: 0;
}

.ant-btn-group {
  position: relative;
  display: inline-flex;
}

.ant-btn-group>.ant-btn,
.ant-btn-group>span>.ant-btn {
  position: relative;
}

.ant-btn-group>.ant-btn:hover,
.ant-btn-group>span>.ant-btn:hover,
.ant-btn-group>.ant-btn:focus,
.ant-btn-group>span>.ant-btn:focus,
.ant-btn-group>.ant-btn:active,
.ant-btn-group>span>.ant-btn:active {
  z-index: 2;
}

.ant-btn-group>.ant-btn[disabled],
.ant-btn-group>span>.ant-btn[disabled] {
  z-index: 0;
}

.ant-btn-group .ant-btn-icon-only {
  font-size: 0.19rem;
}

.ant-btn-group .ant-btn+.ant-btn,
.ant-btn+.ant-btn-group,
.ant-btn-group span+.ant-btn,
.ant-btn-group .ant-btn+span,
.ant-btn-group>span+span,
.ant-btn-group+.ant-btn,
.ant-btn-group+.ant-btn-group {
  margin-left: -0.01rem;
}

.ant-btn-group .ant-btn-primary+.ant-btn:not(.ant-btn-primary):not([disabled]) {
  border-left-color: rgba(0,0,0,0);
}

.ant-btn-group .ant-btn {
  border-radius: 0;
}

.ant-btn-group>.ant-btn:first-child,
.ant-btn-group>span:first-child>.ant-btn {
  margin-left: 0;
}

.ant-btn-group>.ant-btn:only-child {
  border-radius: 0.03rem;
}

.ant-btn-group>span:only-child>.ant-btn {
  border-radius: 0.03rem;
}

.ant-btn-group>.ant-btn:first-child:not(:last-child),
.ant-btn-group>span:first-child:not(:last-child)>.ant-btn {
  border-top-left-radius: 0.03rem;
  border-bottom-left-radius: 0.03rem;
}

.ant-btn-group>.ant-btn:last-child:not(:first-child),
.ant-btn-group>span:last-child:not(:first-child)>.ant-btn {
  border-top-right-radius: 0.03rem;
  border-bottom-right-radius: 0.03rem;
}

.ant-btn-group-sm>.ant-btn:only-child {
  border-radius: 0.03rem;
}

.ant-btn-group-sm>span:only-child>.ant-btn {
  border-radius: 0.03rem;
}

.ant-btn-group-sm>.ant-btn:first-child:not(:last-child),
.ant-btn-group-sm>span:first-child:not(:last-child)>.ant-btn {
  border-top-left-radius: 0.03rem;
  border-bottom-left-radius: 0.03rem;
}

.ant-btn-group-sm>.ant-btn:last-child:not(:first-child),
.ant-btn-group-sm>span:last-child:not(:first-child)>.ant-btn {
  border-top-right-radius: 0.03rem;
  border-bottom-right-radius: 0.03rem;
}

.ant-btn-group>.ant-btn-group {
  float: left;
}

.ant-btn-group>.ant-btn-group:not(:first-child):not(:last-child)>.ant-btn {
  border-radius: 0;
}

.ant-btn-group>.ant-btn-group:first-child:not(:last-child)>.ant-btn:last-child {
  padding-right: 0.11rem;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.ant-btn-group>.ant-btn-group:last-child:not(:first-child)>.ant-btn:first-child {
  padding-left: 0.11rem;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.ant-btn-rtl.ant-btn-group .ant-btn+.ant-btn,
.ant-btn-rtl.ant-btn+.ant-btn-group,
.ant-btn-rtl.ant-btn-group span+.ant-btn,
.ant-btn-rtl.ant-btn-group .ant-btn+span,
.ant-btn-rtl.ant-btn-group>span+span,
.ant-btn-rtl.ant-btn-group+.ant-btn,
.ant-btn-rtl.ant-btn-group+.ant-btn-group,
.ant-btn-group-rtl.ant-btn-group .ant-btn+.ant-btn,
.ant-btn-group-rtl.ant-btn+.ant-btn-group,
.ant-btn-group-rtl.ant-btn-group span+.ant-btn,
.ant-btn-group-rtl.ant-btn-group .ant-btn+span,
.ant-btn-group-rtl.ant-btn-group>span+span,
.ant-btn-group-rtl.ant-btn-group+.ant-btn,
.ant-btn-group-rtl.ant-btn-group+.ant-btn-group {
  margin-right: -0.01rem;
  margin-left: auto;
}

.ant-btn-group.ant-btn-group-rtl {
  direction: rtl;
}

.ant-btn-group-rtl.ant-btn-group>.ant-btn:first-child:not(:last-child),
.ant-btn-group-rtl.ant-btn-group>span:first-child:not(:last-child)>.ant-btn {
  border-radius: 0 0.03rem 0.03rem 0;
}

.ant-btn-group-rtl.ant-btn-group>.ant-btn:last-child:not(:first-child),
.ant-btn-group-rtl.ant-btn-group>span:last-child:not(:first-child)>.ant-btn {
  border-radius: 0.03rem 0 0 0.03rem;
}

.ant-btn-group-rtl.ant-btn-group-sm>.ant-btn:first-child:not(:last-child),
.ant-btn-group-rtl.ant-btn-group-sm>span:first-child:not(:last-child)>.ant-btn {
  border-radius: 0 0.03rem 0.03rem 0;
}

.ant-btn-group-rtl.ant-btn-group-sm>.ant-btn:last-child:not(:first-child),
.ant-btn-group-rtl.ant-btn-group-sm>span:last-child:not(:first-child)>.ant-btn {
  border-radius: 0.03rem 0 0 0.03rem;
}

.ant-btn:focus>span,
.ant-btn:active>span {
  position: relative;
}

.ant-btn>.anticon+span,
.ant-btn>span+.anticon {
  margin-left: 0.11rem;
}

.ant-btn.ant-btn-background-ghost {
  color: #fff;
  border-color: #fff;
}

.ant-btn.ant-btn-background-ghost,
.ant-btn.ant-btn-background-ghost:hover,
.ant-btn.ant-btn-background-ghost:active,
.ant-btn.ant-btn-background-ghost:focus {
  background: rgba(0,0,0,0);
}

.ant-btn.ant-btn-background-ghost:hover,
.ant-btn.ant-btn-background-ghost:focus {
  color: #ffd429;
  border-color: #ffd429;
}

.ant-btn.ant-btn-background-ghost:active {
  color: #d49b00;
  border-color: #d49b00;
}

.ant-btn.ant-btn-background-ghost[disabled] {
  color: rgba(0,0,0,.25);
  background: rgba(0,0,0,0);
  border-color: #d9d9d9;
}

.ant-btn-background-ghost.ant-btn-primary {
  color: #f9be00;
  border-color: #f9be00;
  text-shadow: none;
}

.ant-btn-background-ghost.ant-btn-primary>a:only-child {
  color: currentcolor;
}

.ant-btn-background-ghost.ant-btn-primary>a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: rgba(0,0,0,0);
  content: "";
}

.ant-btn-background-ghost.ant-btn-primary:hover,
.ant-btn-background-ghost.ant-btn-primary:focus {
  color: #ffd429;
  border-color: #ffd429;
}

.ant-btn-background-ghost.ant-btn-primary:hover>a:only-child,
.ant-btn-background-ghost.ant-btn-primary:focus>a:only-child {
  color: currentcolor;
}

.ant-btn-background-ghost.ant-btn-primary:hover>a:only-child::after,
.ant-btn-background-ghost.ant-btn-primary:focus>a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: rgba(0,0,0,0);
  content: "";
}

.ant-btn-background-ghost.ant-btn-primary:active {
  color: #d49b00;
  border-color: #d49b00;
}

.ant-btn-background-ghost.ant-btn-primary:active>a:only-child {
  color: currentcolor;
}

.ant-btn-background-ghost.ant-btn-primary:active>a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: rgba(0,0,0,0);
  content: "";
}

.ant-btn-background-ghost.ant-btn-primary[disabled],
.ant-btn-background-ghost.ant-btn-primary[disabled]:hover,
.ant-btn-background-ghost.ant-btn-primary[disabled]:focus,
.ant-btn-background-ghost.ant-btn-primary[disabled]:active {
  color: rgba(0,0,0,.25);
  border-color: #d9d9d9;
  background: #f5f5f5;
  text-shadow: none;
  box-shadow: none;
}

.ant-btn-background-ghost.ant-btn-primary[disabled]>a:only-child,
.ant-btn-background-ghost.ant-btn-primary[disabled]:hover>a:only-child,
.ant-btn-background-ghost.ant-btn-primary[disabled]:focus>a:only-child,
.ant-btn-background-ghost.ant-btn-primary[disabled]:active>a:only-child {
  color: currentcolor;
}

.ant-btn-background-ghost.ant-btn-primary[disabled]>a:only-child::after,
.ant-btn-background-ghost.ant-btn-primary[disabled]:hover>a:only-child::after,
.ant-btn-background-ghost.ant-btn-primary[disabled]:focus>a:only-child::after,
.ant-btn-background-ghost.ant-btn-primary[disabled]:active>a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: rgba(0,0,0,0);
  content: "";
}

.ant-btn-background-ghost.ant-btn-danger {
  color: #ff4d4f;
  border-color: #ff4d4f;
  text-shadow: none;
}

.ant-btn-background-ghost.ant-btn-danger>a:only-child {
  color: currentcolor;
}

.ant-btn-background-ghost.ant-btn-danger>a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: rgba(0,0,0,0);
  content: "";
}

.ant-btn-background-ghost.ant-btn-danger:hover,
.ant-btn-background-ghost.ant-btn-danger:focus {
  color: #ff7875;
  border-color: #ff7875;
}

.ant-btn-background-ghost.ant-btn-danger:hover>a:only-child,
.ant-btn-background-ghost.ant-btn-danger:focus>a:only-child {
  color: currentcolor;
}

.ant-btn-background-ghost.ant-btn-danger:hover>a:only-child::after,
.ant-btn-background-ghost.ant-btn-danger:focus>a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: rgba(0,0,0,0);
  content: "";
}

.ant-btn-background-ghost.ant-btn-danger:active {
  color: #d9363e;
  border-color: #d9363e;
}

.ant-btn-background-ghost.ant-btn-danger:active>a:only-child {
  color: currentcolor;
}

.ant-btn-background-ghost.ant-btn-danger:active>a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: rgba(0,0,0,0);
  content: "";
}

.ant-btn-background-ghost.ant-btn-danger[disabled],
.ant-btn-background-ghost.ant-btn-danger[disabled]:hover,
.ant-btn-background-ghost.ant-btn-danger[disabled]:focus,
.ant-btn-background-ghost.ant-btn-danger[disabled]:active {
  color: rgba(0,0,0,.25);
  border-color: #d9d9d9;
  background: #f5f5f5;
  text-shadow: none;
  box-shadow: none;
}

.ant-btn-background-ghost.ant-btn-danger[disabled]>a:only-child,
.ant-btn-background-ghost.ant-btn-danger[disabled]:hover>a:only-child,
.ant-btn-background-ghost.ant-btn-danger[disabled]:focus>a:only-child,
.ant-btn-background-ghost.ant-btn-danger[disabled]:active>a:only-child {
  color: currentcolor;
}

.ant-btn-background-ghost.ant-btn-danger[disabled]>a:only-child::after,
.ant-btn-background-ghost.ant-btn-danger[disabled]:hover>a:only-child::after,
.ant-btn-background-ghost.ant-btn-danger[disabled]:focus>a:only-child::after,
.ant-btn-background-ghost.ant-btn-danger[disabled]:active>a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: rgba(0,0,0,0);
  content: "";
}

.ant-btn-background-ghost.ant-btn-dangerous {
  color: #ff4d4f;
  border-color: #ff4d4f;
  text-shadow: none;
}

.ant-btn-background-ghost.ant-btn-dangerous>a:only-child {
  color: currentcolor;
}

.ant-btn-background-ghost.ant-btn-dangerous>a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: rgba(0,0,0,0);
  content: "";
}

.ant-btn-background-ghost.ant-btn-dangerous:hover,
.ant-btn-background-ghost.ant-btn-dangerous:focus {
  color: #ff7875;
  border-color: #ff7875;
}

.ant-btn-background-ghost.ant-btn-dangerous:hover>a:only-child,
.ant-btn-background-ghost.ant-btn-dangerous:focus>a:only-child {
  color: currentcolor;
}

.ant-btn-background-ghost.ant-btn-dangerous:hover>a:only-child::after,
.ant-btn-background-ghost.ant-btn-dangerous:focus>a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: rgba(0,0,0,0);
  content: "";
}

.ant-btn-background-ghost.ant-btn-dangerous:active {
  color: #d9363e;
  border-color: #d9363e;
}

.ant-btn-background-ghost.ant-btn-dangerous:active>a:only-child {
  color: currentcolor;
}

.ant-btn-background-ghost.ant-btn-dangerous:active>a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: rgba(0,0,0,0);
  content: "";
}

.ant-btn-background-ghost.ant-btn-dangerous[disabled],
.ant-btn-background-ghost.ant-btn-dangerous[disabled]:hover,
.ant-btn-background-ghost.ant-btn-dangerous[disabled]:focus,
.ant-btn-background-ghost.ant-btn-dangerous[disabled]:active {
  color: rgba(0,0,0,.25);
  border-color: #d9d9d9;
  background: #f5f5f5;
  text-shadow: none;
  box-shadow: none;
}

.ant-btn-background-ghost.ant-btn-dangerous[disabled]>a:only-child,
.ant-btn-background-ghost.ant-btn-dangerous[disabled]:hover>a:only-child,
.ant-btn-background-ghost.ant-btn-dangerous[disabled]:focus>a:only-child,
.ant-btn-background-ghost.ant-btn-dangerous[disabled]:active>a:only-child {
  color: currentcolor;
}

.ant-btn-background-ghost.ant-btn-dangerous[disabled]>a:only-child::after,
.ant-btn-background-ghost.ant-btn-dangerous[disabled]:hover>a:only-child::after,
.ant-btn-background-ghost.ant-btn-dangerous[disabled]:focus>a:only-child::after,
.ant-btn-background-ghost.ant-btn-dangerous[disabled]:active>a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: rgba(0,0,0,0);
  content: "";
}

.ant-btn-background-ghost.ant-btn-dangerous.ant-btn-link {
  color: #ff4d4f;
  border-color: rgba(0,0,0,0);
  text-shadow: none;
}

.ant-btn-background-ghost.ant-btn-dangerous.ant-btn-link>a:only-child {
  color: currentcolor;
}

.ant-btn-background-ghost.ant-btn-dangerous.ant-btn-link>a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: rgba(0,0,0,0);
  content: "";
}

.ant-btn-background-ghost.ant-btn-dangerous.ant-btn-link:hover,
.ant-btn-background-ghost.ant-btn-dangerous.ant-btn-link:focus {
  color: #ff7875;
  border-color: rgba(0,0,0,0);
}

.ant-btn-background-ghost.ant-btn-dangerous.ant-btn-link:hover>a:only-child,
.ant-btn-background-ghost.ant-btn-dangerous.ant-btn-link:focus>a:only-child {
  color: currentcolor;
}

.ant-btn-background-ghost.ant-btn-dangerous.ant-btn-link:hover>a:only-child::after,
.ant-btn-background-ghost.ant-btn-dangerous.ant-btn-link:focus>a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: rgba(0,0,0,0);
  content: "";
}

.ant-btn-background-ghost.ant-btn-dangerous.ant-btn-link:active {
  color: #d9363e;
  border-color: rgba(0,0,0,0);
}

.ant-btn-background-ghost.ant-btn-dangerous.ant-btn-link:active>a:only-child {
  color: currentcolor;
}

.ant-btn-background-ghost.ant-btn-dangerous.ant-btn-link:active>a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: rgba(0,0,0,0);
  content: "";
}

.ant-btn-background-ghost.ant-btn-dangerous.ant-btn-link[disabled],
.ant-btn-background-ghost.ant-btn-dangerous.ant-btn-link[disabled]:hover,
.ant-btn-background-ghost.ant-btn-dangerous.ant-btn-link[disabled]:focus,
.ant-btn-background-ghost.ant-btn-dangerous.ant-btn-link[disabled]:active {
  color: rgba(0,0,0,.25);
  border-color: #d9d9d9;
  background: #f5f5f5;
  text-shadow: none;
  box-shadow: none;
}

.ant-btn-background-ghost.ant-btn-dangerous.ant-btn-link[disabled]>a:only-child,
.ant-btn-background-ghost.ant-btn-dangerous.ant-btn-link[disabled]:hover>a:only-child,
.ant-btn-background-ghost.ant-btn-dangerous.ant-btn-link[disabled]:focus>a:only-child,
.ant-btn-background-ghost.ant-btn-dangerous.ant-btn-link[disabled]:active>a:only-child {
  color: currentcolor;
}

.ant-btn-background-ghost.ant-btn-dangerous.ant-btn-link[disabled]>a:only-child::after,
.ant-btn-background-ghost.ant-btn-dangerous.ant-btn-link[disabled]:hover>a:only-child::after,
.ant-btn-background-ghost.ant-btn-dangerous.ant-btn-link[disabled]:focus>a:only-child::after,
.ant-btn-background-ghost.ant-btn-dangerous.ant-btn-link[disabled]:active>a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: rgba(0,0,0,0);
  content: "";
}

.ant-btn-two-chinese-chars::first-letter {
  letter-spacing: .34em;
}

.ant-btn-two-chinese-chars>*:not(.anticon) {
  margin-right: -0.34em;
  letter-spacing: .34em;
}

.ant-btn.ant-btn-block {
  width: 100%;
}

.ant-btn:empty {
  display: inline-block;
  width: 0;
  visibility: hidden;
  content: " ";
}

a.ant-btn {
  padding-top: .0.01rem !important;
  line-height: 0.4rem;
}

a.ant-btn-lg {
  line-height: 0.51rem;
}

a.ant-btn-sm {
  line-height: 0.29rem;
}

.ant-btn-rtl {
  direction: rtl;
}

.ant-btn-group-rtl.ant-btn-group .ant-btn-primary:last-child:not(:first-child),
.ant-btn-group-rtl.ant-btn-group .ant-btn-primary+.ant-btn-primary {
  border-right-color: #ffd429;
  border-left-color: #d9d9d9;
}

.ant-btn-group-rtl.ant-btn-group .ant-btn-primary:last-child:not(:first-child)[disabled],
.ant-btn-group-rtl.ant-btn-group .ant-btn-primary+.ant-btn-primary[disabled] {
  border-right-color: #d9d9d9;
  border-left-color: #ffd429;
}

.ant-btn-rtl.ant-btn>.ant-btn-loading-icon .anticon {
  padding-right: 0;
  padding-left: 0.11rem;
}

.ant-btn>.ant-btn-loading-icon:only-child .anticon {
  padding-right: 0;
  padding-left: 0;
}

.ant-btn-rtl.ant-btn>.anticon+span,
.ant-btn-rtl.ant-btn>span+.anticon {
  margin-right: 0.11rem;
  margin-left: 0;
}