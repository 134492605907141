.ant-layout {
  display: flex;
  flex: auto;
  flex-direction: column;
  min-height: 0;
  background: #f0f2f5;
}

.ant-layout,
.ant-layout * {
  box-sizing: border-box;
}

.ant-layout.ant-layout-has-sider {
  flex-direction: row;
}

.ant-layout.ant-layout-has-sider>.ant-layout,
.ant-layout.ant-layout-has-sider>.ant-layout-content {
  width: 0;
}

.ant-layout-header,
.ant-layout-footer {
  flex: 0 0 auto;
}

.ant-layout-header {
  height: 0.85rem;
  padding: 0 0.67rem;
  color: rgba(0,0,0,.85);
  line-height: 0.85rem;
  background: #001529;
}

.ant-layout-footer {
  padding: 0.32rem 0.67rem;
  color: rgba(0,0,0,.85);
  font-size: 0.19rem;
  background: #f0f2f5;
}

.ant-layout-content {
  flex: auto;
  min-height: 0;
}

.ant-layout-sider {
  position: relative;
  min-width: 0;
  background: #001529;
  transition: all .2s;
}

.ant-layout-sider-children {
  height: 100%;
  margin-top: -0;
  padding-top: .0.01rem;
}

.ant-layout-sider-children .ant-menu.ant-menu-inline-collapsed {
  width: auto;
}

.ant-layout-sider-has-trigger {
  padding-bottom: 0.64rem;
}

.ant-layout-sider-right {
  order: 1;
}

.ant-layout-sider-trigger {
  position: fixed;
  bottom: 0;
  z-index: 1;
  height: 0.64rem;
  color: #fff;
  line-height: 0.64rem;
  text-align: center;
  background: #002140;
  cursor: pointer;
  transition: all .2s;
}

.ant-layout-sider-zero-width>* {
  overflow: hidden;
}

.ant-layout-sider-zero-width-trigger {
  position: absolute;
  top: 0.85rem;
  right: -0.48rem;
  z-index: 1;
  width: 0.48rem;
  height: 0.56rem;
  color: #fff;
  font-size: 0.24rem;
  line-height: 0.56rem;
  text-align: center;
  background: #001529;
  border-radius: 0 0.03rem 0.03rem 0;
  cursor: pointer;
  transition: background .3s ease;
}

.ant-layout-sider-zero-width-trigger::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: rgba(0,0,0,0);
  transition: all .3s;
  content: "";
}

.ant-layout-sider-zero-width-trigger:hover::after {
  background: hsla(0,0%,100%,.1);
}

.ant-layout-sider-zero-width-trigger-right {
  left: -0.48rem;
  border-radius: 0.03rem 0 0 0.03rem;
}

.ant-layout-sider-light {
  background: #fff;
}

.ant-layout-sider-light .ant-layout-sider-trigger {
  color: rgba(0,0,0,.85);
  background: #fff;
}

.ant-layout-sider-light .ant-layout-sider-zero-width-trigger {
  color: rgba(0,0,0,.85);
  background: #fff;
}

.ant-layout-rtl {
  direction: rtl;
}