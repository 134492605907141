.ant-input-affix-wrapper {
  position: relative;
  display: inline-block;
  width: 100%;
  min-width: 0;
  padding: 0.05rem 0.15rem;
  color: rgba(0,0,0,.85);
  font-size: 0.19rem;
  line-height: 1.5715;
  background-color: #fff;
  background-image: none;
  border: 0.01rem solid #d9d9d9;
  border-radius: 0.03rem;
  transition: all .3s;
  display: inline-flex;
}

.ant-input-affix-wrapper::-moz-placeholder {
  opacity: 1;
}

.ant-input-affix-wrapper:-ms-input-placeholder {
  color: #bfbfbf;
  -ms-user-select: none;
  user-select: none;
}

.ant-input-affix-wrapper::placeholder {
  color: #bfbfbf;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.ant-input-affix-wrapper:-moz-placeholder-shown {
  text-overflow: ellipsis;
}

.ant-input-affix-wrapper:-ms-input-placeholder {
  text-overflow: ellipsis;
}

.ant-input-affix-wrapper:placeholder-shown {
  text-overflow: ellipsis;
}

.ant-input-affix-wrapper:hover {
  border-color: #ffd429;
  border-right-width: 0.01rem;
}

.ant-input-rtl .ant-input-affix-wrapper:hover {
  border-right-width: 0;
  border-left-width: 0.01rem !important;
}

.ant-input-affix-wrapper:focus,
.ant-input-affix-wrapper-focused {
  border-color: #ffd429;
  box-shadow: 0 0 0 0.03rem rgba(249,190,0,.2);
  border-right-width: 0.01rem;
  outline: 0;
}

.ant-input-rtl .ant-input-affix-wrapper:focus,
.ant-input-rtl .ant-input-affix-wrapper-focused {
  border-right-width: 0;
  border-left-width: 0.01rem !important;
}

.ant-input-affix-wrapper-disabled {
  color: rgba(0,0,0,.25);
  background-color: #f5f5f5;
  border-color: #d9d9d9;
  box-shadow: none;
  cursor: not-allowed;
  opacity: 1;
}

.ant-input-affix-wrapper-disabled:hover {
  border-color: #d9d9d9;
  border-right-width: 0.01rem;
}

.ant-input-affix-wrapper[disabled] {
  color: rgba(0,0,0,.25);
  background-color: #f5f5f5;
  border-color: #d9d9d9;
  box-shadow: none;
  cursor: not-allowed;
  opacity: 1;
}

.ant-input-affix-wrapper[disabled]:hover {
  border-color: #d9d9d9;
  border-right-width: 0.01rem;
}

.ant-input-affix-wrapper-borderless,
.ant-input-affix-wrapper-borderless:hover,
.ant-input-affix-wrapper-borderless:focus,
.ant-input-affix-wrapper-borderless-focused,
.ant-input-affix-wrapper-borderless-disabled,
.ant-input-affix-wrapper-borderless[disabled] {
  background-color: rgba(0,0,0,0);
  border: none;
  box-shadow: none;
}

textarea.ant-input-affix-wrapper {
  max-width: 100%;
  height: auto;
  min-height: 0.43rem;
  line-height: 1.5715;
  vertical-align: bottom;
  transition: all .3s,height 0s;
}

.ant-input-affix-wrapper-lg {
  padding: 0.09rem 0.15rem;
  font-size: 0.21rem;
}

.ant-input-affix-wrapper-sm {
  padding: 0 0.09rem;
}

.ant-input-affix-wrapper-rtl {
  direction: rtl;
}

.ant-input-affix-wrapper:not(.ant-input-affix-wrapper-disabled):hover {
  border-color: #ffd429;
  border-right-width: 0.01rem;
  z-index: 1;
}

.ant-input-rtl .ant-input-affix-wrapper:not(.ant-input-affix-wrapper-disabled):hover {
  border-right-width: 0;
  border-left-width: 0.01rem !important;
}

.ant-input-search-with-button .ant-input-affix-wrapper:not(.ant-input-affix-wrapper-disabled):hover {
  z-index: 0;
}

.ant-input-affix-wrapper-focused,
.ant-input-affix-wrapper:focus {
  z-index: 1;
}

.ant-input-affix-wrapper-disabled .ant-input[disabled] {
  background: rgba(0,0,0,0);
}

.ant-input-affix-wrapper>input.ant-input {
  padding: 0;
  border: none;
  outline: none;
}

.ant-input-affix-wrapper>input.ant-input:focus {
  box-shadow: none !important;
}

.ant-input-affix-wrapper::before {
  width: 0;
  visibility: hidden;
  content: " ";
}

.ant-input-prefix,
.ant-input-suffix {
  display: flex;
  flex: none;
  align-items: center;
}

.ant-input-prefix>*:not(:last-child),
.ant-input-suffix>*:not(:last-child) {
  margin-right: 0.11rem;
}

.ant-input-show-count-suffix {
  color: rgba(0,0,0,.45);
}

.ant-input-show-count-has-suffix {
  margin-right: 0.03rem;
}

.ant-input-prefix {
  margin-right: 0.05rem;
}

.ant-input-suffix {
  margin-left: 0.05rem;
}

.anticon.ant-input-clear-icon,
.ant-input-clear-icon {
  margin: 0;
  color: rgba(0,0,0,.25);
  font-size: 0.16rem;
  vertical-align: -0.01rem;
  cursor: pointer;
  transition: color .3s;
}

.anticon.ant-input-clear-icon:hover,
.ant-input-clear-icon:hover {
  color: rgba(0,0,0,.45);
}

.anticon.ant-input-clear-icon:active,
.ant-input-clear-icon:active {
  color: rgba(0,0,0,.85);
}

.anticon.ant-input-clear-icon-hidden,
.ant-input-clear-icon-hidden {
  visibility: hidden;
}

.anticon.ant-input-clear-icon-has-suffix,
.ant-input-clear-icon-has-suffix {
  margin: 0 0.05rem;
}

.ant-input-affix-wrapper-textarea-with-clear-btn {
  padding: 0 !important;
  border: 0 !important;
}

.ant-input-affix-wrapper-textarea-with-clear-btn .ant-input-clear-icon {
  position: absolute;
  top: 0.11rem;
  right: 0.11rem;
  z-index: 1;
}

.ant-input-status-error:not(.ant-input-disabled):not(.ant-input-borderless).ant-input,
.ant-input-status-error:not(.ant-input-disabled):not(.ant-input-borderless).ant-input:hover {
  background: #fff;
  border-color: #ff4d4f;
}

.ant-input-status-error:not(.ant-input-disabled):not(.ant-input-borderless).ant-input:focus,
.ant-input-status-error:not(.ant-input-disabled):not(.ant-input-borderless).ant-input-focused {
  border-color: #ff7875;
  box-shadow: 0 0 0 0.03rem rgba(255,77,79,.2);
  border-right-width: 0.01rem;
  outline: 0;
}

.ant-input-status-error .ant-input-prefix {
  color: #ff4d4f;
}

.ant-input-status-warning:not(.ant-input-disabled):not(.ant-input-borderless).ant-input,
.ant-input-status-warning:not(.ant-input-disabled):not(.ant-input-borderless).ant-input:hover {
  background: #fff;
  border-color: #faad14;
}

.ant-input-status-warning:not(.ant-input-disabled):not(.ant-input-borderless).ant-input:focus,
.ant-input-status-warning:not(.ant-input-disabled):not(.ant-input-borderless).ant-input-focused {
  border-color: #ffc53d;
  box-shadow: 0 0 0 0.03rem rgba(250,173,20,.2);
  border-right-width: 0.01rem;
  outline: 0;
}

.ant-input-status-warning .ant-input-prefix {
  color: #faad14;
}

.ant-input-affix-wrapper-status-error:not(.ant-input-affix-wrapper-disabled):not(.ant-input-affix-wrapper-borderless).ant-input-affix-wrapper,
.ant-input-affix-wrapper-status-error:not(.ant-input-affix-wrapper-disabled):not(.ant-input-affix-wrapper-borderless).ant-input-affix-wrapper:hover {
  background: #fff;
  border-color: #ff4d4f;
}

.ant-input-affix-wrapper-status-error:not(.ant-input-affix-wrapper-disabled):not(.ant-input-affix-wrapper-borderless).ant-input-affix-wrapper:focus,
.ant-input-affix-wrapper-status-error:not(.ant-input-affix-wrapper-disabled):not(.ant-input-affix-wrapper-borderless).ant-input-affix-wrapper-focused {
  border-color: #ff7875;
  box-shadow: 0 0 0 0.03rem rgba(255,77,79,.2);
  border-right-width: 0.01rem;
  outline: 0;
}

.ant-input-affix-wrapper-status-error .ant-input-prefix {
  color: #ff4d4f;
}

.ant-input-affix-wrapper-status-warning:not(.ant-input-affix-wrapper-disabled):not(.ant-input-affix-wrapper-borderless).ant-input-affix-wrapper,
.ant-input-affix-wrapper-status-warning:not(.ant-input-affix-wrapper-disabled):not(.ant-input-affix-wrapper-borderless).ant-input-affix-wrapper:hover {
  background: #fff;
  border-color: #faad14;
}

.ant-input-affix-wrapper-status-warning:not(.ant-input-affix-wrapper-disabled):not(.ant-input-affix-wrapper-borderless).ant-input-affix-wrapper:focus,
.ant-input-affix-wrapper-status-warning:not(.ant-input-affix-wrapper-disabled):not(.ant-input-affix-wrapper-borderless).ant-input-affix-wrapper-focused {
  border-color: #ffc53d;
  box-shadow: 0 0 0 0.03rem rgba(250,173,20,.2);
  border-right-width: 0.01rem;
  outline: 0;
}

.ant-input-affix-wrapper-status-warning .ant-input-prefix {
  color: #faad14;
}

.ant-input-textarea-status-error.ant-input-textarea-has-feedback .ant-input,
.ant-input-textarea-status-warning.ant-input-textarea-has-feedback .ant-input,
.ant-input-textarea-status-success.ant-input-textarea-has-feedback .ant-input,
.ant-input-textarea-status-validating.ant-input-textarea-has-feedback .ant-input {
  padding-right: 0.32rem;
}

.ant-input-group-wrapper-status-error .ant-input-group-addon {
  color: #ff4d4f;
  border-color: #ff4d4f;
}

.ant-input-group-wrapper-status-warning .ant-input-group-addon {
  color: #faad14;
  border-color: #faad14;
}

.ant-input {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  font-variant: tabular-nums;
  list-style: none;
  font-feature-settings: "tnum";
  position: relative;
  display: inline-block;
  width: 100%;
  min-width: 0;
  padding: 0.05rem 0.15rem;
  color: rgba(0,0,0,.85);
  font-size: 0.19rem;
  line-height: 1.5715;
  background-color: #fff;
  background-image: none;
  border: 0.01rem solid #d9d9d9;
  border-radius: 0.03rem;
  transition: all .3s;
}

.ant-input::-moz-placeholder {
  opacity: 1;
}

.ant-input:-ms-input-placeholder {
  color: #bfbfbf;
  -ms-user-select: none;
  user-select: none;
}

.ant-input::placeholder {
  color: #bfbfbf;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.ant-input:-moz-placeholder-shown {
  text-overflow: ellipsis;
}

.ant-input:-ms-input-placeholder {
  text-overflow: ellipsis;
}

.ant-input:placeholder-shown {
  text-overflow: ellipsis;
}

.ant-input:hover {
  border-color: #ffd429;
  border-right-width: 0.01rem;
}

.ant-input-rtl .ant-input:hover {
  border-right-width: 0;
  border-left-width: 0.01rem !important;
}

.ant-input:focus,
.ant-input-focused {
  border-color: #ffd429;
  box-shadow: 0 0 0 0.03rem rgba(249,190,0,.2);
  border-right-width: 0.01rem;
  outline: 0;
}

.ant-input-rtl .ant-input:focus,
.ant-input-rtl .ant-input-focused {
  border-right-width: 0;
  border-left-width: 0.01rem !important;
}

.ant-input-disabled {
  color: rgba(0,0,0,.25);
  background-color: #f5f5f5;
  border-color: #d9d9d9;
  box-shadow: none;
  cursor: not-allowed;
  opacity: 1;
}

.ant-input-disabled:hover {
  border-color: #d9d9d9;
  border-right-width: 0.01rem;
}

.ant-input[disabled] {
  color: rgba(0,0,0,.25);
  background-color: #f5f5f5;
  border-color: #d9d9d9;
  box-shadow: none;
  cursor: not-allowed;
  opacity: 1;
}

.ant-input[disabled]:hover {
  border-color: #d9d9d9;
  border-right-width: 0.01rem;
}

.ant-input-borderless,
.ant-input-borderless:hover,
.ant-input-borderless:focus,
.ant-input-borderless-focused,
.ant-input-borderless-disabled,
.ant-input-borderless[disabled] {
  background-color: rgba(0,0,0,0);
  border: none;
  box-shadow: none;
}

textarea.ant-input {
  max-width: 100%;
  height: auto;
  min-height: 0.43rem;
  line-height: 1.5715;
  vertical-align: bottom;
  transition: all .3s,height 0s;
}

.ant-input-lg {
  padding: 0.09rem 0.15rem;
  font-size: 0.21rem;
}

.ant-input-sm {
  padding: 0 0.09rem;
}

.ant-input-rtl {
  direction: rtl;
}

.ant-input-group {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: rgba(0,0,0,.85);
  font-size: 0.19rem;
  font-variant: tabular-nums;
  line-height: 1.5715;
  list-style: none;
  font-feature-settings: "tnum";
  position: relative;
  display: table;
  width: 100%;
  border-collapse: separate;
  border-spacing: 0;
}

.ant-input-group[class*=col-] {
  float: none;
  padding-right: 0;
  padding-left: 0;
}

.ant-input-group>[class*=col-] {
  padding-right: 0.11rem;
}

.ant-input-group>[class*=col-]:last-child {
  padding-right: 0;
}

.ant-input-group-addon,
.ant-input-group-wrap,
.ant-input-group>.ant-input {
  display: table-cell;
}

.ant-input-group-addon:not(:first-child):not(:last-child),
.ant-input-group-wrap:not(:first-child):not(:last-child),
.ant-input-group>.ant-input:not(:first-child):not(:last-child) {
  border-radius: 0;
}

.ant-input-group-addon,
.ant-input-group-wrap {
  width: 0.01rem;
  white-space: nowrap;
  vertical-align: middle;
}

.ant-input-group-wrap>* {
  display: block !important;
}

.ant-input-group .ant-input {
  float: left;
  width: 100%;
  margin-bottom: 0;
  text-align: inherit;
}

.ant-input-group .ant-input:focus {
  z-index: 1;
  border-right-width: 0.01rem;
}

.ant-input-group .ant-input:hover {
  z-index: 1;
  border-right-width: 0.01rem;
}

.ant-input-search-with-button .ant-input-group .ant-input:hover {
  z-index: 0;
}

.ant-input-group-addon {
  position: relative;
  padding: 0 0.15rem;
  color: rgba(0,0,0,.85);
  font-weight: normal;
  font-size: 0.19rem;
  text-align: center;
  background-color: #fafafa;
  border: 0.01rem solid #d9d9d9;
  border-radius: 0.03rem;
  transition: all .3s;
}

.ant-input-group-addon .ant-select {
  margin: -0.07rem -0.15rem;
}

.ant-input-group-addon .ant-select.ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
  background-color: inherit;
  border: 0.01rem solid rgba(0,0,0,0);
  box-shadow: none;
}

.ant-input-group-addon .ant-select-open .ant-select-selector,
.ant-input-group-addon .ant-select-focused .ant-select-selector {
  color: #f9be00;
}

.ant-input-group-addon .ant-cascader-picker {
  margin: -0.12rem -0.16rem;
  background-color: rgba(0,0,0,0);
}

.ant-input-group-addon .ant-cascader-picker .ant-cascader-input {
  text-align: left;
  border: 0;
  box-shadow: none;
}

.ant-input-group>.ant-input:first-child,
.ant-input-group-addon:first-child {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.ant-input-group>.ant-input:first-child .ant-select .ant-select-selector,
.ant-input-group-addon:first-child .ant-select .ant-select-selector {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.ant-input-group>.ant-input-affix-wrapper:not(:first-child) .ant-input {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.ant-input-group>.ant-input-affix-wrapper:not(:last-child) .ant-input {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.ant-input-group-addon:first-child {
  border-right: 0;
}

.ant-input-group-addon:last-child {
  border-left: 0;
}

.ant-input-group>.ant-input:last-child,
.ant-input-group-addon:last-child {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.ant-input-group>.ant-input:last-child .ant-select .ant-select-selector,
.ant-input-group-addon:last-child .ant-select .ant-select-selector {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.ant-input-group-lg .ant-input,
.ant-input-group-lg>.ant-input-group-addon {
  padding: 0.09rem 0.15rem;
  font-size: 0.21rem;
}

.ant-input-group-sm .ant-input,
.ant-input-group-sm>.ant-input-group-addon {
  padding: 0 0.09rem;
}

.ant-input-group-lg .ant-select-single .ant-select-selector {
  height: 0.53rem;
}

.ant-input-group-sm .ant-select-single .ant-select-selector {
  height: 0.32rem;
}

.ant-input-group .ant-input-affix-wrapper:not(:last-child) {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.ant-input-search .ant-input-group .ant-input-affix-wrapper:not(:last-child) {
  border-top-left-radius: 0.03rem;
  border-bottom-left-radius: 0.03rem;
}

.ant-input-group .ant-input-affix-wrapper:not(:first-child),
.ant-input-search .ant-input-group .ant-input-affix-wrapper:not(:first-child) {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.ant-input-group.ant-input-group-compact {
  display: block;
}

.ant-input-group.ant-input-group-compact::before {
  display: table;
  content: "";
}

.ant-input-group.ant-input-group-compact::after {
  display: table;
  clear: both;
  content: "";
}

.ant-input-group.ant-input-group-compact-addon:not(:first-child):not(:last-child),
.ant-input-group.ant-input-group-compact-wrap:not(:first-child):not(:last-child),
.ant-input-group.ant-input-group-compact>.ant-input:not(:first-child):not(:last-child) {
  border-right-width: 0.01rem;
}

.ant-input-group.ant-input-group-compact-addon:not(:first-child):not(:last-child):hover,
.ant-input-group.ant-input-group-compact-wrap:not(:first-child):not(:last-child):hover,
.ant-input-group.ant-input-group-compact>.ant-input:not(:first-child):not(:last-child):hover {
  z-index: 1;
}

.ant-input-group.ant-input-group-compact-addon:not(:first-child):not(:last-child):focus,
.ant-input-group.ant-input-group-compact-wrap:not(:first-child):not(:last-child):focus,
.ant-input-group.ant-input-group-compact>.ant-input:not(:first-child):not(:last-child):focus {
  z-index: 1;
}

.ant-input-group.ant-input-group-compact>* {
  display: inline-block;
  float: none;
  vertical-align: top;
  border-radius: 0;
}

.ant-input-group.ant-input-group-compact>.ant-input-affix-wrapper {
  display: inline-flex;
}

.ant-input-group.ant-input-group-compact>.ant-picker-range {
  display: inline-flex;
}

.ant-input-group.ant-input-group-compact>*:not(:last-child) {
  margin-right: -0.01rem;
  border-right-width: 0.01rem;
}

.ant-input-group.ant-input-group-compact .ant-input {
  float: none;
}

.ant-input-group.ant-input-group-compact>.ant-select>.ant-select-selector,
.ant-input-group.ant-input-group-compact>.ant-select-auto-complete .ant-input,
.ant-input-group.ant-input-group-compact>.ant-cascader-picker .ant-input,
.ant-input-group.ant-input-group-compact>.ant-input-group-wrapper .ant-input {
  border-right-width: 0.01rem;
  border-radius: 0;
}

.ant-input-group.ant-input-group-compact>.ant-select>.ant-select-selector:hover,
.ant-input-group.ant-input-group-compact>.ant-select-auto-complete .ant-input:hover,
.ant-input-group.ant-input-group-compact>.ant-cascader-picker .ant-input:hover,
.ant-input-group.ant-input-group-compact>.ant-input-group-wrapper .ant-input:hover {
  z-index: 1;
}

.ant-input-group.ant-input-group-compact>.ant-select>.ant-select-selector:focus,
.ant-input-group.ant-input-group-compact>.ant-select-auto-complete .ant-input:focus,
.ant-input-group.ant-input-group-compact>.ant-cascader-picker .ant-input:focus,
.ant-input-group.ant-input-group-compact>.ant-input-group-wrapper .ant-input:focus {
  z-index: 1;
}

.ant-input-group.ant-input-group-compact>.ant-select-focused {
  z-index: 1;
}

.ant-input-group.ant-input-group-compact>.ant-select>.ant-select-arrow {
  z-index: 1;
}

.ant-input-group.ant-input-group-compact>*:first-child,
.ant-input-group.ant-input-group-compact>.ant-select:first-child>.ant-select-selector,
.ant-input-group.ant-input-group-compact>.ant-select-auto-complete:first-child .ant-input,
.ant-input-group.ant-input-group-compact>.ant-cascader-picker:first-child .ant-input {
  border-top-left-radius: 0.03rem;
  border-bottom-left-radius: 0.03rem;
}

.ant-input-group.ant-input-group-compact>*:last-child,
.ant-input-group.ant-input-group-compact>.ant-select:last-child>.ant-select-selector,
.ant-input-group.ant-input-group-compact>.ant-cascader-picker:last-child .ant-input,
.ant-input-group.ant-input-group-compact>.ant-cascader-picker-focused:last-child .ant-input {
  border-right-width: 0.01rem;
  border-top-right-radius: 0.03rem;
  border-bottom-right-radius: 0.03rem;
}

.ant-input-group.ant-input-group-compact>.ant-select-auto-complete .ant-input {
  vertical-align: top;
}

.ant-input-group.ant-input-group-compact .ant-input-group-wrapper+.ant-input-group-wrapper {
  margin-left: -0.01rem;
}

.ant-input-group.ant-input-group-compact .ant-input-group-wrapper+.ant-input-group-wrapper .ant-input-affix-wrapper {
  border-radius: 0;
}

.ant-input-group.ant-input-group-compact .ant-input-group-wrapper:not(:last-child).ant-input-search>.ant-input-group>.ant-input-group-addon>.ant-input-search-button {
  border-radius: 0;
}

.ant-input-group.ant-input-group-compact .ant-input-group-wrapper:not(:last-child).ant-input-search>.ant-input-group>.ant-input {
  border-radius: 0.03rem 0 0 0.03rem;
}

.ant-input-group>.ant-input-rtl:first-child,
.ant-input-group-rtl .ant-input-group-addon:first-child {
  border-radius: 0 0.03rem 0.03rem 0;
}

.ant-input-group-rtl .ant-input-group-addon:first-child {
  border-right: 0.01rem solid #d9d9d9;
  border-left: 0;
}

.ant-input-group-rtl .ant-input-group-addon:last-child {
  border-right: 0;
  border-left: 0.01rem solid #d9d9d9;
  border-radius: 0.03rem 0 0 0.03rem;
}

.ant-input-group-rtl.ant-input-group>.ant-input:last-child,
.ant-input-group-rtl.ant-input-group-addon:last-child {
  border-radius: 0.03rem 0 0 0.03rem;
}

.ant-input-group-rtl.ant-input-group .ant-input-affix-wrapper:not(:first-child) {
  border-radius: 0.03rem 0 0 0.03rem;
}

.ant-input-group-rtl.ant-input-group .ant-input-affix-wrapper:not(:last-child) {
  border-radius: 0 0.03rem 0.03rem 0;
}

.ant-input-group-rtl.ant-input-group.ant-input-group-compact>*:not(:last-child) {
  margin-right: 0;
  margin-left: -0.01rem;
  border-left-width: 0.01rem;
}

.ant-input-group-rtl.ant-input-group.ant-input-group-compact>*:first-child,
.ant-input-group-rtl.ant-input-group.ant-input-group-compact>.ant-select:first-child>.ant-select-selector,
.ant-input-group-rtl.ant-input-group.ant-input-group-compact>.ant-select-auto-complete:first-child .ant-input,
.ant-input-group-rtl.ant-input-group.ant-input-group-compact>.ant-cascader-picker:first-child .ant-input {
  border-radius: 0 0.03rem 0.03rem 0;
}

.ant-input-group-rtl.ant-input-group.ant-input-group-compact>*:last-child,
.ant-input-group-rtl.ant-input-group.ant-input-group-compact>.ant-select:last-child>.ant-select-selector,
.ant-input-group-rtl.ant-input-group.ant-input-group-compact>.ant-select-auto-complete:last-child .ant-input,
.ant-input-group-rtl.ant-input-group.ant-input-group-compact>.ant-cascader-picker:last-child .ant-input,
.ant-input-group-rtl.ant-input-group.ant-input-group-compact>.ant-cascader-picker-focused:last-child .ant-input {
  border-left-width: 0.01rem;
  border-radius: 0.03rem 0 0 0.03rem;
}

.ant-input-group.ant-input-group-compact .ant-input-group-wrapper-rtl+.ant-input-group-wrapper-rtl {
  margin-right: -0.01rem;
  margin-left: 0;
}

.ant-input-group.ant-input-group-compact .ant-input-group-wrapper-rtl:not(:last-child).ant-input-search>.ant-input-group>.ant-input {
  border-radius: 0 0.03rem 0.03rem 0;
}

.ant-input-group-wrapper {
  display: inline-block;
  width: 100%;
  text-align: start;
  vertical-align: top;
}

.ant-input-password-icon.anticon {
  color: rgba(0,0,0,.45);
  cursor: pointer;
  transition: all .3s;
}

.ant-input-password-icon.anticon:hover {
  color: rgba(0,0,0,.85);
}

.ant-input[type=color] {
  height: 0.43rem;
}

.ant-input[type=color].ant-input-lg {
  height: 0.53rem;
}

.ant-input[type=color].ant-input-sm {
  height: 0.32rem;
  padding-top: 0.04rem;
  padding-bottom: 0.04rem;
}

.ant-input-textarea-show-count>.ant-input {
  height: 100%;
}

.ant-input-textarea-show-count::after {
  float: right;
  color: rgba(0,0,0,.45);
  white-space: nowrap;
  content: attr(data-count);
  pointer-events: none;
}

.ant-input-textarea-show-count.ant-input-textarea-in-form-item::after {
  margin-bottom: -0.29rem;
}

.ant-input-textarea-suffix {
  position: absolute;
  top: 0;
  right: 0.15rem;
  bottom: 0;
  z-index: 1;
  display: inline-flex;
  align-items: center;
  margin: auto;
}

.ant-input-search .ant-input:hover,
.ant-input-search .ant-input:focus {
  border-color: #ffd429;
}

.ant-input-search .ant-input:hover+.ant-input-group-addon .ant-input-search-button:not(.ant-btn-primary),
.ant-input-search .ant-input:focus+.ant-input-group-addon .ant-input-search-button:not(.ant-btn-primary) {
  border-left-color: #ffd429;
}

.ant-input-search .ant-input-affix-wrapper {
  border-radius: 0;
}

.ant-input-search .ant-input-lg {
  line-height: 1.5713;
}

.ant-input-search>.ant-input-group>.ant-input-group-addon:last-child {
  left: -0.01rem;
  padding: 0;
  border: 0;
}

.ant-input-search>.ant-input-group>.ant-input-group-addon:last-child .ant-input-search-button {
  padding-top: 0;
  padding-bottom: 0;
  border-radius: 0 0.03rem 0.03rem 0;
}

.ant-input-search>.ant-input-group>.ant-input-group-addon:last-child .ant-input-search-button:not(.ant-btn-primary) {
  color: rgba(0,0,0,.45);
}

.ant-input-search>.ant-input-group>.ant-input-group-addon:last-child .ant-input-search-button:not(.ant-btn-primary).ant-btn-loading::before {
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}

.ant-input-search-button {
  height: 0.43rem;
}

.ant-input-search-button:hover,
.ant-input-search-button:focus {
  z-index: 1;
}

.ant-input-search-large .ant-input-search-button {
  height: 0.53rem;
}

.ant-input-search-small .ant-input-search-button {
  height: 0.32rem;
}

.ant-input-group-wrapper-rtl {
  direction: rtl;
}

.ant-input-group-rtl {
  direction: rtl;
}

.ant-input-affix-wrapper.ant-input-affix-wrapper-rtl>input.ant-input {
  border: none;
  outline: none;
}

.ant-input-affix-wrapper-rtl .ant-input-prefix {
  margin: 0 0 0 0.05rem;
}

.ant-input-affix-wrapper-rtl .ant-input-suffix {
  margin: 0 0.05rem 0 0;
}

.ant-input-textarea-rtl {
  direction: rtl;
}

.ant-input-textarea-rtl.ant-input-textarea-show-count::after {
  text-align: left;
}

.ant-input-affix-wrapper-rtl .ant-input-clear-icon-has-suffix {
  margin-right: 0;
  margin-left: 0.05rem;
}

.ant-input-affix-wrapper-rtl .ant-input-clear-icon {
  right: auto;
  left: 0.11rem;
}

.ant-input-search-rtl {
  direction: rtl;
}

.ant-input-search-rtl .ant-input:hover+.ant-input-group-addon .ant-input-search-button:not(.ant-btn-primary),
.ant-input-search-rtl .ant-input:focus+.ant-input-group-addon .ant-input-search-button:not(.ant-btn-primary) {
  border-right-color: #ffd429;
  border-left-color: #d9d9d9;
}

.ant-input-search-rtl>.ant-input-group>.ant-input-affix-wrapper:hover,
.ant-input-search-rtl>.ant-input-group>.ant-input-affix-wrapper-focused {
  border-right-color: #ffd429;
}

.ant-input-search-rtl>.ant-input-group>.ant-input-group-addon {
  right: -0.01rem;
  left: auto;
}

.ant-input-search-rtl>.ant-input-group>.ant-input-group-addon .ant-input-search-button {
  border-radius: 0.03rem 0 0 0.03rem;
}

@media screen and (-ms-high-contrast: active),(-ms-high-contrast: none) {
  .ant-input {
    height: 0.43rem;
  }

  .ant-input-lg {
    height: 0.53rem;
  }

  .ant-input-sm {
    height: 0.32rem;
  }

  .ant-input-affix-wrapper>input.ant-input {
    height: auto;
  }
}