.ant-tabs-small>.ant-tabs-nav .ant-tabs-tab {
  padding: 0.11rem 0;
  font-size: 0.19rem;
}

.ant-tabs-large>.ant-tabs-nav .ant-tabs-tab {
  padding: 0.21rem 0;
  font-size: 0.21rem;
}

.ant-tabs-card.ant-tabs-small>.ant-tabs-nav .ant-tabs-tab {
  padding: 0.08rem 0.21rem;
}

.ant-tabs-card.ant-tabs-large>.ant-tabs-nav .ant-tabs-tab {
  padding: 0.09rem 0.21rem 0.08rem;
}

.ant-tabs-rtl {
  direction: rtl;
}

.ant-tabs-rtl .ant-tabs-nav .ant-tabs-tab {
  margin: 0 0 0 0.43rem;
}

.ant-tabs-rtl .ant-tabs-nav .ant-tabs-tab:last-of-type {
  margin-left: 0;
}

.ant-tabs-rtl .ant-tabs-nav .ant-tabs-tab .anticon {
  margin-right: 0;
  margin-left: 0.16rem;
}

.ant-tabs-rtl .ant-tabs-nav .ant-tabs-tab .ant-tabs-tab-remove {
  margin-right: 0.11rem;
  margin-left: -0.05rem;
}

.ant-tabs-rtl .ant-tabs-nav .ant-tabs-tab .ant-tabs-tab-remove .anticon {
  margin: 0;
}

.ant-tabs-rtl.ant-tabs-left>.ant-tabs-nav {
  order: 1;
}

.ant-tabs-rtl.ant-tabs-left>.ant-tabs-content-holder {
  order: 0;
}

.ant-tabs-rtl.ant-tabs-right>.ant-tabs-nav {
  order: 0;
}

.ant-tabs-rtl.ant-tabs-right>.ant-tabs-content-holder {
  order: 1;
}

.ant-tabs-rtl.ant-tabs-card.ant-tabs-top>.ant-tabs-nav .ant-tabs-tab+.ant-tabs-tab,
.ant-tabs-rtl.ant-tabs-card.ant-tabs-bottom>.ant-tabs-nav .ant-tabs-tab+.ant-tabs-tab,
.ant-tabs-rtl.ant-tabs-card.ant-tabs-top>div>.ant-tabs-nav .ant-tabs-tab+.ant-tabs-tab,
.ant-tabs-rtl.ant-tabs-card.ant-tabs-bottom>div>.ant-tabs-nav .ant-tabs-tab+.ant-tabs-tab {
  margin-right: 0.03rem;
  margin-left: 0;
}

.ant-tabs-rtl.ant-tabs-card.ant-tabs-top>.ant-tabs-nav .ant-tabs-nav-add,
.ant-tabs-rtl.ant-tabs-card.ant-tabs-bottom>.ant-tabs-nav .ant-tabs-nav-add,
.ant-tabs-rtl.ant-tabs-card.ant-tabs-top>div>.ant-tabs-nav .ant-tabs-nav-add,
.ant-tabs-rtl.ant-tabs-card.ant-tabs-bottom>div>.ant-tabs-nav .ant-tabs-nav-add {
  margin-right: 0.03rem;
  margin-left: 0;
}

.ant-tabs-dropdown-rtl {
  direction: rtl;
}

.ant-tabs-dropdown-rtl .ant-tabs-dropdown-menu-item {
  text-align: right;
}

.ant-tabs-top,
.ant-tabs-bottom {
  flex-direction: column;
}

.ant-tabs-top>.ant-tabs-nav,
.ant-tabs-bottom>.ant-tabs-nav,
.ant-tabs-top>div>.ant-tabs-nav,
.ant-tabs-bottom>div>.ant-tabs-nav {
  margin: 0 0 0.21rem 0;
}

.ant-tabs-top>.ant-tabs-nav::before,
.ant-tabs-bottom>.ant-tabs-nav::before,
.ant-tabs-top>div>.ant-tabs-nav::before,
.ant-tabs-bottom>div>.ant-tabs-nav::before {
  position: absolute;
  right: 0;
  left: 0;
  border-bottom: 0.01rem solid #f0f0f0;
  content: "";
}

.ant-tabs-top>.ant-tabs-nav .ant-tabs-ink-bar,
.ant-tabs-bottom>.ant-tabs-nav .ant-tabs-ink-bar,
.ant-tabs-top>div>.ant-tabs-nav .ant-tabs-ink-bar,
.ant-tabs-bottom>div>.ant-tabs-nav .ant-tabs-ink-bar {
  height: 0.03rem;
}

.ant-tabs-top>.ant-tabs-nav .ant-tabs-ink-bar-animated,
.ant-tabs-bottom>.ant-tabs-nav .ant-tabs-ink-bar-animated,
.ant-tabs-top>div>.ant-tabs-nav .ant-tabs-ink-bar-animated,
.ant-tabs-bottom>div>.ant-tabs-nav .ant-tabs-ink-bar-animated {
  transition: width .3s,left .3s,right .3s;
}

.ant-tabs-top>.ant-tabs-nav .ant-tabs-nav-wrap::before,
.ant-tabs-bottom>.ant-tabs-nav .ant-tabs-nav-wrap::before,
.ant-tabs-top>div>.ant-tabs-nav .ant-tabs-nav-wrap::before,
.ant-tabs-bottom>div>.ant-tabs-nav .ant-tabs-nav-wrap::before,
.ant-tabs-top>.ant-tabs-nav .ant-tabs-nav-wrap::after,
.ant-tabs-bottom>.ant-tabs-nav .ant-tabs-nav-wrap::after,
.ant-tabs-top>div>.ant-tabs-nav .ant-tabs-nav-wrap::after,
.ant-tabs-bottom>div>.ant-tabs-nav .ant-tabs-nav-wrap::after {
  top: 0;
  bottom: 0;
  width: 0.4rem;
}

.ant-tabs-top>.ant-tabs-nav .ant-tabs-nav-wrap::before,
.ant-tabs-bottom>.ant-tabs-nav .ant-tabs-nav-wrap::before,
.ant-tabs-top>div>.ant-tabs-nav .ant-tabs-nav-wrap::before,
.ant-tabs-bottom>div>.ant-tabs-nav .ant-tabs-nav-wrap::before {
  left: 0;
  box-shadow: inset 0.13rem 0 0.11rem -0.11rem rgba(0,0,0,.08);
}

.ant-tabs-top>.ant-tabs-nav .ant-tabs-nav-wrap::after,
.ant-tabs-bottom>.ant-tabs-nav .ant-tabs-nav-wrap::after,
.ant-tabs-top>div>.ant-tabs-nav .ant-tabs-nav-wrap::after,
.ant-tabs-bottom>div>.ant-tabs-nav .ant-tabs-nav-wrap::after {
  right: 0;
  box-shadow: inset -0.13rem 0 0.11rem -0.11rem rgba(0,0,0,.08);
}

.ant-tabs-top>.ant-tabs-nav .ant-tabs-nav-wrap.ant-tabs-nav-wrap-ping-left::before,
.ant-tabs-bottom>.ant-tabs-nav .ant-tabs-nav-wrap.ant-tabs-nav-wrap-ping-left::before,
.ant-tabs-top>div>.ant-tabs-nav .ant-tabs-nav-wrap.ant-tabs-nav-wrap-ping-left::before,
.ant-tabs-bottom>div>.ant-tabs-nav .ant-tabs-nav-wrap.ant-tabs-nav-wrap-ping-left::before {
  opacity: 1;
}

.ant-tabs-top>.ant-tabs-nav .ant-tabs-nav-wrap.ant-tabs-nav-wrap-ping-right::after,
.ant-tabs-bottom>.ant-tabs-nav .ant-tabs-nav-wrap.ant-tabs-nav-wrap-ping-right::after,
.ant-tabs-top>div>.ant-tabs-nav .ant-tabs-nav-wrap.ant-tabs-nav-wrap-ping-right::after,
.ant-tabs-bottom>div>.ant-tabs-nav .ant-tabs-nav-wrap.ant-tabs-nav-wrap-ping-right::after {
  opacity: 1;
}

.ant-tabs-top>.ant-tabs-nav::before,
.ant-tabs-top>div>.ant-tabs-nav::before {
  bottom: 0;
}

.ant-tabs-top>.ant-tabs-nav .ant-tabs-ink-bar,
.ant-tabs-top>div>.ant-tabs-nav .ant-tabs-ink-bar {
  bottom: 0;
}

.ant-tabs-bottom>.ant-tabs-nav,
.ant-tabs-bottom>div>.ant-tabs-nav {
  order: 1;
  margin-top: 0.21rem;
  margin-bottom: 0;
}

.ant-tabs-bottom>.ant-tabs-nav::before,
.ant-tabs-bottom>div>.ant-tabs-nav::before {
  top: 0;
}

.ant-tabs-bottom>.ant-tabs-nav .ant-tabs-ink-bar,
.ant-tabs-bottom>div>.ant-tabs-nav .ant-tabs-ink-bar {
  top: 0;
}

.ant-tabs-bottom>.ant-tabs-content-holder,
.ant-tabs-bottom>div>.ant-tabs-content-holder {
  order: 0;
}

.ant-tabs-left>.ant-tabs-nav,
.ant-tabs-right>.ant-tabs-nav,
.ant-tabs-left>div>.ant-tabs-nav,
.ant-tabs-right>div>.ant-tabs-nav {
  flex-direction: column;
  min-width: 0.67rem;
}

.ant-tabs-left>.ant-tabs-nav .ant-tabs-tab,
.ant-tabs-right>.ant-tabs-nav .ant-tabs-tab,
.ant-tabs-left>div>.ant-tabs-nav .ant-tabs-tab,
.ant-tabs-right>div>.ant-tabs-nav .ant-tabs-tab {
  padding: 0.11rem 0.32rem;
  text-align: center;
}

.ant-tabs-left>.ant-tabs-nav .ant-tabs-tab+.ant-tabs-tab,
.ant-tabs-right>.ant-tabs-nav .ant-tabs-tab+.ant-tabs-tab,
.ant-tabs-left>div>.ant-tabs-nav .ant-tabs-tab+.ant-tabs-tab,
.ant-tabs-right>div>.ant-tabs-nav .ant-tabs-tab+.ant-tabs-tab {
  margin: 0.21rem 0 0 0;
}

.ant-tabs-left>.ant-tabs-nav .ant-tabs-nav-wrap,
.ant-tabs-right>.ant-tabs-nav .ant-tabs-nav-wrap,
.ant-tabs-left>div>.ant-tabs-nav .ant-tabs-nav-wrap,
.ant-tabs-right>div>.ant-tabs-nav .ant-tabs-nav-wrap {
  flex-direction: column;
}

.ant-tabs-left>.ant-tabs-nav .ant-tabs-nav-wrap::before,
.ant-tabs-right>.ant-tabs-nav .ant-tabs-nav-wrap::before,
.ant-tabs-left>div>.ant-tabs-nav .ant-tabs-nav-wrap::before,
.ant-tabs-right>div>.ant-tabs-nav .ant-tabs-nav-wrap::before,
.ant-tabs-left>.ant-tabs-nav .ant-tabs-nav-wrap::after,
.ant-tabs-right>.ant-tabs-nav .ant-tabs-nav-wrap::after,
.ant-tabs-left>div>.ant-tabs-nav .ant-tabs-nav-wrap::after,
.ant-tabs-right>div>.ant-tabs-nav .ant-tabs-nav-wrap::after {
  right: 0;
  left: 0;
  height: 0.4rem;
}

.ant-tabs-left>.ant-tabs-nav .ant-tabs-nav-wrap::before,
.ant-tabs-right>.ant-tabs-nav .ant-tabs-nav-wrap::before,
.ant-tabs-left>div>.ant-tabs-nav .ant-tabs-nav-wrap::before,
.ant-tabs-right>div>.ant-tabs-nav .ant-tabs-nav-wrap::before {
  top: 0;
  box-shadow: inset 0 0.13rem 0.11rem -0.11rem rgba(0,0,0,.08);
}

.ant-tabs-left>.ant-tabs-nav .ant-tabs-nav-wrap::after,
.ant-tabs-right>.ant-tabs-nav .ant-tabs-nav-wrap::after,
.ant-tabs-left>div>.ant-tabs-nav .ant-tabs-nav-wrap::after,
.ant-tabs-right>div>.ant-tabs-nav .ant-tabs-nav-wrap::after {
  bottom: 0;
  box-shadow: inset 0 -0.13rem 0.11rem -0.11rem rgba(0,0,0,.08);
}

.ant-tabs-left>.ant-tabs-nav .ant-tabs-nav-wrap.ant-tabs-nav-wrap-ping-top::before,
.ant-tabs-right>.ant-tabs-nav .ant-tabs-nav-wrap.ant-tabs-nav-wrap-ping-top::before,
.ant-tabs-left>div>.ant-tabs-nav .ant-tabs-nav-wrap.ant-tabs-nav-wrap-ping-top::before,
.ant-tabs-right>div>.ant-tabs-nav .ant-tabs-nav-wrap.ant-tabs-nav-wrap-ping-top::before {
  opacity: 1;
}

.ant-tabs-left>.ant-tabs-nav .ant-tabs-nav-wrap.ant-tabs-nav-wrap-ping-bottom::after,
.ant-tabs-right>.ant-tabs-nav .ant-tabs-nav-wrap.ant-tabs-nav-wrap-ping-bottom::after,
.ant-tabs-left>div>.ant-tabs-nav .ant-tabs-nav-wrap.ant-tabs-nav-wrap-ping-bottom::after,
.ant-tabs-right>div>.ant-tabs-nav .ant-tabs-nav-wrap.ant-tabs-nav-wrap-ping-bottom::after {
  opacity: 1;
}

.ant-tabs-left>.ant-tabs-nav .ant-tabs-ink-bar,
.ant-tabs-right>.ant-tabs-nav .ant-tabs-ink-bar,
.ant-tabs-left>div>.ant-tabs-nav .ant-tabs-ink-bar,
.ant-tabs-right>div>.ant-tabs-nav .ant-tabs-ink-bar {
  width: 0.03rem;
}

.ant-tabs-left>.ant-tabs-nav .ant-tabs-ink-bar-animated,
.ant-tabs-right>.ant-tabs-nav .ant-tabs-ink-bar-animated,
.ant-tabs-left>div>.ant-tabs-nav .ant-tabs-ink-bar-animated,
.ant-tabs-right>div>.ant-tabs-nav .ant-tabs-ink-bar-animated {
  transition: height .3s,top .3s;
}

.ant-tabs-left>.ant-tabs-nav .ant-tabs-nav-list,
.ant-tabs-right>.ant-tabs-nav .ant-tabs-nav-list,
.ant-tabs-left>div>.ant-tabs-nav .ant-tabs-nav-list,
.ant-tabs-right>div>.ant-tabs-nav .ant-tabs-nav-list,
.ant-tabs-left>.ant-tabs-nav .ant-tabs-nav-operations,
.ant-tabs-right>.ant-tabs-nav .ant-tabs-nav-operations,
.ant-tabs-left>div>.ant-tabs-nav .ant-tabs-nav-operations,
.ant-tabs-right>div>.ant-tabs-nav .ant-tabs-nav-operations {
  flex: 1 0 auto;
  flex-direction: column;
}

.ant-tabs-left>.ant-tabs-nav .ant-tabs-ink-bar,
.ant-tabs-left>div>.ant-tabs-nav .ant-tabs-ink-bar {
  right: 0;
}

.ant-tabs-left>.ant-tabs-content-holder,
.ant-tabs-left>div>.ant-tabs-content-holder {
  margin-left: -0.01rem;
  border-left: 0.01rem solid #f0f0f0;
}

.ant-tabs-left>.ant-tabs-content-holder>.ant-tabs-content>.ant-tabs-tabpane,
.ant-tabs-left>div>.ant-tabs-content-holder>.ant-tabs-content>.ant-tabs-tabpane {
  padding-left: 0.32rem;
}

.ant-tabs-right>.ant-tabs-nav,
.ant-tabs-right>div>.ant-tabs-nav {
  order: 1;
}

.ant-tabs-right>.ant-tabs-nav .ant-tabs-ink-bar,
.ant-tabs-right>div>.ant-tabs-nav .ant-tabs-ink-bar {
  left: 0;
}

.ant-tabs-right>.ant-tabs-content-holder,
.ant-tabs-right>div>.ant-tabs-content-holder {
  order: 0;
  margin-right: -0.01rem;
  border-right: 0.01rem solid #f0f0f0;
}

.ant-tabs-right>.ant-tabs-content-holder>.ant-tabs-content>.ant-tabs-tabpane,
.ant-tabs-right>div>.ant-tabs-content-holder>.ant-tabs-content>.ant-tabs-tabpane {
  padding-right: 0.32rem;
}

.ant-tabs-dropdown {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: rgba(0,0,0,.85);
  font-size: 0.19rem;
  font-variant: tabular-nums;
  line-height: 1.5715;
  list-style: none;
  font-feature-settings: "tnum";
  position: absolute;
  top: -133.32rem;
  left: -133.32rem;
  z-index: 1050;
  display: block;
}

.ant-tabs-dropdown-hidden {
  display: none;
}

.ant-tabs-dropdown-menu {
  max-height: 2.67rem;
  margin: 0;
  padding: 0.05rem 0;
  overflow-x: hidden;
  overflow-y: auto;
  text-align: left;
  list-style-type: none;
  background-color: #fff;
  background-clip: padding-box;
  border-radius: 0.03rem;
  outline: none;
  box-shadow: 0 0.04rem 0.08rem -0.05rem rgba(0,0,0,.12),0 0.08rem 0.21rem 0 rgba(0,0,0,.08),0 0.12rem 0.37rem 0.11rem rgba(0,0,0,.05);
}

.ant-tabs-dropdown-menu-item {
  display: flex;
  align-items: center;
  min-width: 1.6rem;
  margin: 0;
  padding: 0.07rem 0.16rem;
  overflow: hidden;
  color: rgba(0,0,0,.85);
  font-weight: normal;
  font-size: 0.19rem;
  line-height: 0.29rem;
  white-space: nowrap;
  text-overflow: ellipsis;
  cursor: pointer;
  transition: all .3s;
}

.ant-tabs-dropdown-menu-item>span {
  flex: 1 1;
  white-space: nowrap;
}

.ant-tabs-dropdown-menu-item-remove {
  flex: none;
  margin-left: 0.16rem;
  color: rgba(0,0,0,.45);
  font-size: 0.16rem;
  background: rgba(0,0,0,0);
  border: 0;
  cursor: pointer;
}

.ant-tabs-dropdown-menu-item-remove:hover {
  color: #ffd429;
}

.ant-tabs-dropdown-menu-item:hover {
  background: #f5f5f5;
}

.ant-tabs-dropdown-menu-item-disabled,
.ant-tabs-dropdown-menu-item-disabled:hover {
  color: rgba(0,0,0,.25);
  background: rgba(0,0,0,0);
  cursor: not-allowed;
}

.ant-tabs-card>.ant-tabs-nav .ant-tabs-tab,
.ant-tabs-card>div>.ant-tabs-nav .ant-tabs-tab {
  margin: 0;
  padding: 0.11rem 0.21rem;
  background: #fafafa;
  border: 0.01rem solid #f0f0f0;
  transition: all .3s cubic-bezier(0.645, 0.045, 0.355, 1);
}

.ant-tabs-card>.ant-tabs-nav .ant-tabs-tab-active,
.ant-tabs-card>div>.ant-tabs-nav .ant-tabs-tab-active {
  color: #f9be00;
  background: #fff;
}

.ant-tabs-card>.ant-tabs-nav .ant-tabs-ink-bar,
.ant-tabs-card>div>.ant-tabs-nav .ant-tabs-ink-bar {
  visibility: hidden;
}

.ant-tabs-card.ant-tabs-top>.ant-tabs-nav .ant-tabs-tab+.ant-tabs-tab,
.ant-tabs-card.ant-tabs-bottom>.ant-tabs-nav .ant-tabs-tab+.ant-tabs-tab,
.ant-tabs-card.ant-tabs-top>div>.ant-tabs-nav .ant-tabs-tab+.ant-tabs-tab,
.ant-tabs-card.ant-tabs-bottom>div>.ant-tabs-nav .ant-tabs-tab+.ant-tabs-tab {
  margin-left: 0.03rem;
}

.ant-tabs-card.ant-tabs-top>.ant-tabs-nav .ant-tabs-tab,
.ant-tabs-card.ant-tabs-top>div>.ant-tabs-nav .ant-tabs-tab {
  border-radius: 0.03rem 0.03rem 0 0;
}

.ant-tabs-card.ant-tabs-top>.ant-tabs-nav .ant-tabs-tab-active,
.ant-tabs-card.ant-tabs-top>div>.ant-tabs-nav .ant-tabs-tab-active {
  border-bottom-color: #fff;
}

.ant-tabs-card.ant-tabs-bottom>.ant-tabs-nav .ant-tabs-tab,
.ant-tabs-card.ant-tabs-bottom>div>.ant-tabs-nav .ant-tabs-tab {
  border-radius: 0 0 0.03rem 0.03rem;
}

.ant-tabs-card.ant-tabs-bottom>.ant-tabs-nav .ant-tabs-tab-active,
.ant-tabs-card.ant-tabs-bottom>div>.ant-tabs-nav .ant-tabs-tab-active {
  border-top-color: #fff;
}

.ant-tabs-card.ant-tabs-left>.ant-tabs-nav .ant-tabs-tab+.ant-tabs-tab,
.ant-tabs-card.ant-tabs-right>.ant-tabs-nav .ant-tabs-tab+.ant-tabs-tab,
.ant-tabs-card.ant-tabs-left>div>.ant-tabs-nav .ant-tabs-tab+.ant-tabs-tab,
.ant-tabs-card.ant-tabs-right>div>.ant-tabs-nav .ant-tabs-tab+.ant-tabs-tab {
  margin-top: 0.03rem;
}

.ant-tabs-card.ant-tabs-left>.ant-tabs-nav .ant-tabs-tab,
.ant-tabs-card.ant-tabs-left>div>.ant-tabs-nav .ant-tabs-tab {
  border-radius: 0.03rem 0 0 0.03rem;
}

.ant-tabs-card.ant-tabs-left>.ant-tabs-nav .ant-tabs-tab-active,
.ant-tabs-card.ant-tabs-left>div>.ant-tabs-nav .ant-tabs-tab-active {
  border-right-color: #fff;
}

.ant-tabs-card.ant-tabs-right>.ant-tabs-nav .ant-tabs-tab,
.ant-tabs-card.ant-tabs-right>div>.ant-tabs-nav .ant-tabs-tab {
  border-radius: 0 0.03rem 0.03rem 0;
}

.ant-tabs-card.ant-tabs-right>.ant-tabs-nav .ant-tabs-tab-active,
.ant-tabs-card.ant-tabs-right>div>.ant-tabs-nav .ant-tabs-tab-active {
  border-left-color: #fff;
}

.ant-tabs {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: rgba(0,0,0,.85);
  font-size: 0.19rem;
  font-variant: tabular-nums;
  line-height: 1.5715;
  list-style: none;
  font-feature-settings: "tnum";
  display: flex;
}

.ant-tabs>.ant-tabs-nav,
.ant-tabs>div>.ant-tabs-nav {
  position: relative;
  display: flex;
  flex: none;
  align-items: center;
}

.ant-tabs>.ant-tabs-nav .ant-tabs-nav-wrap,
.ant-tabs>div>.ant-tabs-nav .ant-tabs-nav-wrap {
  position: relative;
  display: inline-block;
  display: flex;
  flex: auto;
  align-self: stretch;
  overflow: hidden;
  white-space: nowrap;
  transform: translate(0);
}

.ant-tabs>.ant-tabs-nav .ant-tabs-nav-wrap::before,
.ant-tabs>div>.ant-tabs-nav .ant-tabs-nav-wrap::before,
.ant-tabs>.ant-tabs-nav .ant-tabs-nav-wrap::after,
.ant-tabs>div>.ant-tabs-nav .ant-tabs-nav-wrap::after {
  position: absolute;
  z-index: 1;
  opacity: 0;
  transition: opacity .3s;
  content: "";
  pointer-events: none;
}

.ant-tabs>.ant-tabs-nav .ant-tabs-nav-list,
.ant-tabs>div>.ant-tabs-nav .ant-tabs-nav-list {
  position: relative;
  display: flex;
  transition: transform .3s;
}

.ant-tabs>.ant-tabs-nav .ant-tabs-nav-operations,
.ant-tabs>div>.ant-tabs-nav .ant-tabs-nav-operations {
  display: flex;
  align-self: stretch;
}

.ant-tabs>.ant-tabs-nav .ant-tabs-nav-operations-hidden,
.ant-tabs>div>.ant-tabs-nav .ant-tabs-nav-operations-hidden {
  position: absolute;
  visibility: hidden;
  pointer-events: none;
}

.ant-tabs>.ant-tabs-nav .ant-tabs-nav-more,
.ant-tabs>div>.ant-tabs-nav .ant-tabs-nav-more {
  position: relative;
  padding: 0.11rem 0.21rem;
  background: rgba(0,0,0,0);
  border: 0;
}

.ant-tabs>.ant-tabs-nav .ant-tabs-nav-more::after,
.ant-tabs>div>.ant-tabs-nav .ant-tabs-nav-more::after {
  position: absolute;
  right: 0;
  bottom: 0;
  left: 0;
  height: 0.07rem;
  transform: translateY(100%);
  content: "";
}

.ant-tabs>.ant-tabs-nav .ant-tabs-nav-add,
.ant-tabs>div>.ant-tabs-nav .ant-tabs-nav-add {
  min-width: 0.53rem;
  margin-left: 0.03rem;
  padding: 0 0.11rem;
  background: #fafafa;
  border: 0.01rem solid #f0f0f0;
  border-radius: 0.03rem 0.03rem 0 0;
  outline: none;
  cursor: pointer;
  transition: all .3s cubic-bezier(0.645, 0.045, 0.355, 1);
}

.ant-tabs>.ant-tabs-nav .ant-tabs-nav-add:hover,
.ant-tabs>div>.ant-tabs-nav .ant-tabs-nav-add:hover {
  color: #ffd429;
}

.ant-tabs>.ant-tabs-nav .ant-tabs-nav-add:active,
.ant-tabs>div>.ant-tabs-nav .ant-tabs-nav-add:active,
.ant-tabs>.ant-tabs-nav .ant-tabs-nav-add:focus,
.ant-tabs>div>.ant-tabs-nav .ant-tabs-nav-add:focus {
  color: #d49b00;
}

.ant-tabs-extra-content {
  flex: none;
}

.ant-tabs-centered>.ant-tabs-nav .ant-tabs-nav-wrap:not([class*=ant-tabs-nav-wrap-ping]),
.ant-tabs-centered>div>.ant-tabs-nav .ant-tabs-nav-wrap:not([class*=ant-tabs-nav-wrap-ping]) {
  justify-content: center;
}

.ant-tabs-ink-bar {
  position: absolute;
  background: #f9be00;
  pointer-events: none;
}

.ant-tabs-tab {
  position: relative;
  display: inline-flex;
  align-items: center;
  padding: 0.16rem 0;
  font-size: 0.19rem;
  background: rgba(0,0,0,0);
  border: 0;
  outline: none;
  cursor: pointer;
}

.ant-tabs-tab-btn:focus,
.ant-tabs-tab-remove:focus,
.ant-tabs-tab-btn:active,
.ant-tabs-tab-remove:active {
  color: #d49b00;
}

.ant-tabs-tab-btn {
  outline: none;
  transition: all .3s;
}

.ant-tabs-tab-remove {
  flex: none;
  margin-right: -0.05rem;
  margin-left: 0.11rem;
  color: rgba(0,0,0,.45);
  font-size: 0.16rem;
  background: rgba(0,0,0,0);
  border: none;
  outline: none;
  cursor: pointer;
  transition: all .3s;
}

.ant-tabs-tab-remove:hover {
  color: rgba(0,0,0,.85);
}

.ant-tabs-tab:hover {
  color: #ffd429;
}

.ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
  color: #f9be00;
  text-shadow: 0 0 .0.33rem currentcolor;
}

.ant-tabs-tab.ant-tabs-tab-disabled {
  color: rgba(0,0,0,.25);
  cursor: not-allowed;
}

.ant-tabs-tab.ant-tabs-tab-disabled .ant-tabs-tab-btn:focus,
.ant-tabs-tab.ant-tabs-tab-disabled .ant-tabs-tab-remove:focus,
.ant-tabs-tab.ant-tabs-tab-disabled .ant-tabs-tab-btn:active,
.ant-tabs-tab.ant-tabs-tab-disabled .ant-tabs-tab-remove:active {
  color: rgba(0,0,0,.25);
}

.ant-tabs-tab .ant-tabs-tab-remove .anticon {
  margin: 0;
}

.ant-tabs-tab .anticon {
  margin-right: 0.16rem;
}

.ant-tabs-tab+.ant-tabs-tab {
  margin: 0 0 0 0.43rem;
}

.ant-tabs-content {
  display: flex;
  width: 100%;
}

.ant-tabs-content-holder {
  flex: auto;
  min-width: 0;
  min-height: 0;
}

.ant-tabs-content-animated {
  transition: margin .3s;
}

.ant-tabs-tabpane {
  flex: none;
  width: 100%;
  outline: none;
}