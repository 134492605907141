.ant-tooltip {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: rgba(0,0,0,.85);
  font-size: 0.19rem;
  font-variant: tabular-nums;
  line-height: 1.5715;
  list-style: none;
  font-feature-settings: "tnum";
  position: absolute;
  z-index: 1070;
  display: block;
  width: -moz-max-content;
  width: max-content;
  width: intrinsic;
  max-width: 3.33rem;
  visibility: visible;
}

.ant-tooltip-content {
  position: relative;
}

.ant-tooltip-hidden {
  display: none;
}

.ant-tooltip-placement-top,
.ant-tooltip-placement-topLeft,
.ant-tooltip-placement-topRight {
  padding-bottom: 0.19rem;
}

.ant-tooltip-placement-right,
.ant-tooltip-placement-rightTop,
.ant-tooltip-placement-rightBottom {
  padding-left: 0.19rem;
}

.ant-tooltip-placement-bottom,
.ant-tooltip-placement-bottomLeft,
.ant-tooltip-placement-bottomRight {
  padding-top: 0.19rem;
}

.ant-tooltip-placement-left,
.ant-tooltip-placement-leftTop,
.ant-tooltip-placement-leftBottom {
  padding-right: 0.19rem;
}

.ant-tooltip-inner {
  min-width: 0.4rem;
  min-height: 0.43rem;
  padding: 0.08rem 0.11rem;
  color: #fff;
  text-align: left;
  text-decoration: none;
  word-wrap: break-word;
  background-color: rgba(0,0,0,.75);
  border-radius: 0.03rem;
  box-shadow: 0 0.04rem 0.08rem -0.05rem rgba(0,0,0,.12),0 0.08rem 0.21rem 0 rgba(0,0,0,.08),0 0.12rem 0.37rem 0.11rem rgba(0,0,0,.05);
}

.ant-tooltip-arrow {
  position: absolute;
  z-index: 2;
  display: block;
  width: 0.29rem;
  height: 0.29rem;
  overflow: hidden;
  background: rgba(0,0,0,0);
  pointer-events: none;
}

.ant-tooltip-arrow-content {
  --antd-arrow-background-color: linear-gradient(to right bottom, rgba(0, 0, 0, 0.65), rgba(0, 0, 0, 0.75));
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: block;
  width: 0.15rem;
  height: 0.15rem;
  margin: auto;
  background-color: rgba(0,0,0,0);
  content: "";
  pointer-events: auto;
  border-radius: 0 0 0.03rem;
  pointer-events: none;
}

.ant-tooltip-arrow-content::before {
  position: absolute;
  top: -0.15rem;
  left: -0.15rem;
  width: 0.45rem;
  height: 0.45rem;
  background: var(--antd-arrow-background-color);
  background-repeat: no-repeat;
  background-position: -0.13rem -0.13rem;
  content: "";
  clip-path: path("M 9.849242404917499 24.091883092036785 A 5 5 0 0 1 13.384776310850237 22.627416997969522 L 20.627416997969522 22.627416997969522 A 2 2 0 0 0 22.627416997969522 20.627416997969522 L 22.627416997969522 13.384776310850237 A 5 5 0 0 1 24.091883092036785 9.849242404917499 L 23.091883092036785 9.849242404917499 L 9.849242404917499 23.091883092036785 Z");
}

.ant-tooltip-placement-top .ant-tooltip-arrow,
.ant-tooltip-placement-topLeft .ant-tooltip-arrow,
.ant-tooltip-placement-topRight .ant-tooltip-arrow {
  bottom: 0;
  transform: translateY(100%);
}

.ant-tooltip-placement-top .ant-tooltip-arrow-content,
.ant-tooltip-placement-topLeft .ant-tooltip-arrow-content,
.ant-tooltip-placement-topRight .ant-tooltip-arrow-content {
  box-shadow: 0.04rem 0.04rem 0.09rem rgba(0,0,0,.07);
  transform: translateY(-0.15rem) rotate(45deg);
}

.ant-tooltip-placement-top .ant-tooltip-arrow {
  left: 50%;
  transform: translateY(100%) translateX(-50%);
}

.ant-tooltip-placement-topLeft .ant-tooltip-arrow {
  left: 0.17rem;
}

.ant-tooltip-placement-topRight .ant-tooltip-arrow {
  right: 0.17rem;
}

.ant-tooltip-placement-right .ant-tooltip-arrow,
.ant-tooltip-placement-rightTop .ant-tooltip-arrow,
.ant-tooltip-placement-rightBottom .ant-tooltip-arrow {
  left: 0;
  transform: translateX(-100%);
}

.ant-tooltip-placement-right .ant-tooltip-arrow-content,
.ant-tooltip-placement-rightTop .ant-tooltip-arrow-content,
.ant-tooltip-placement-rightBottom .ant-tooltip-arrow-content {
  box-shadow: -0.04rem 0.04rem 0.09rem rgba(0,0,0,.07);
  transform: translateX(0.15rem) rotate(135deg);
}

.ant-tooltip-placement-right .ant-tooltip-arrow {
  top: 50%;
  transform: translateX(-100%) translateY(-50%);
}

.ant-tooltip-placement-rightTop .ant-tooltip-arrow {
  top: 0.07rem;
}

.ant-tooltip-placement-rightBottom .ant-tooltip-arrow {
  bottom: 0.07rem;
}

.ant-tooltip-placement-left .ant-tooltip-arrow,
.ant-tooltip-placement-leftTop .ant-tooltip-arrow,
.ant-tooltip-placement-leftBottom .ant-tooltip-arrow {
  right: 0;
  transform: translateX(100%);
}

.ant-tooltip-placement-left .ant-tooltip-arrow-content,
.ant-tooltip-placement-leftTop .ant-tooltip-arrow-content,
.ant-tooltip-placement-leftBottom .ant-tooltip-arrow-content {
  box-shadow: 0.04rem -0.04rem 0.09rem rgba(0,0,0,.07);
  transform: translateX(-0.15rem) rotate(315deg);
}

.ant-tooltip-placement-left .ant-tooltip-arrow {
  top: 50%;
  transform: translateX(100%) translateY(-50%);
}

.ant-tooltip-placement-leftTop .ant-tooltip-arrow {
  top: 0.07rem;
}

.ant-tooltip-placement-leftBottom .ant-tooltip-arrow {
  bottom: 0.07rem;
}

.ant-tooltip-placement-bottom .ant-tooltip-arrow,
.ant-tooltip-placement-bottomLeft .ant-tooltip-arrow,
.ant-tooltip-placement-bottomRight .ant-tooltip-arrow {
  top: 0;
  transform: translateY(-100%);
}

.ant-tooltip-placement-bottom .ant-tooltip-arrow-content,
.ant-tooltip-placement-bottomLeft .ant-tooltip-arrow-content,
.ant-tooltip-placement-bottomRight .ant-tooltip-arrow-content {
  box-shadow: -0.04rem -0.04rem 0.09rem rgba(0,0,0,.07);
  transform: translateY(0.15rem) rotate(225deg);
}

.ant-tooltip-placement-bottom .ant-tooltip-arrow {
  left: 50%;
  transform: translateY(-100%) translateX(-50%);
}

.ant-tooltip-placement-bottomLeft .ant-tooltip-arrow {
  left: 0.17rem;
}

.ant-tooltip-placement-bottomRight .ant-tooltip-arrow {
  right: 0.17rem;
}

.ant-tooltip-pink .ant-tooltip-inner {
  background-color: #eb2f96;
}

.ant-tooltip-pink .ant-tooltip-arrow-content::before {
  background: #eb2f96;
}

.ant-tooltip-magenta .ant-tooltip-inner {
  background-color: #eb2f96;
}

.ant-tooltip-magenta .ant-tooltip-arrow-content::before {
  background: #eb2f96;
}

.ant-tooltip-red .ant-tooltip-inner {
  background-color: #f5222d;
}

.ant-tooltip-red .ant-tooltip-arrow-content::before {
  background: #f5222d;
}

.ant-tooltip-volcano .ant-tooltip-inner {
  background-color: #fa541c;
}

.ant-tooltip-volcano .ant-tooltip-arrow-content::before {
  background: #fa541c;
}

.ant-tooltip-orange .ant-tooltip-inner {
  background-color: #fa8c16;
}

.ant-tooltip-orange .ant-tooltip-arrow-content::before {
  background: #fa8c16;
}

.ant-tooltip-yellow .ant-tooltip-inner {
  background-color: #fadb14;
}

.ant-tooltip-yellow .ant-tooltip-arrow-content::before {
  background: #fadb14;
}

.ant-tooltip-gold .ant-tooltip-inner {
  background-color: #faad14;
}

.ant-tooltip-gold .ant-tooltip-arrow-content::before {
  background: #faad14;
}

.ant-tooltip-cyan .ant-tooltip-inner {
  background-color: #13c2c2;
}

.ant-tooltip-cyan .ant-tooltip-arrow-content::before {
  background: #13c2c2;
}

.ant-tooltip-lime .ant-tooltip-inner {
  background-color: #a0d911;
}

.ant-tooltip-lime .ant-tooltip-arrow-content::before {
  background: #a0d911;
}

.ant-tooltip-green .ant-tooltip-inner {
  background-color: #52c41a;
}

.ant-tooltip-green .ant-tooltip-arrow-content::before {
  background: #52c41a;
}

.ant-tooltip-blue .ant-tooltip-inner {
  background-color: #1890ff;
}

.ant-tooltip-blue .ant-tooltip-arrow-content::before {
  background: #1890ff;
}

.ant-tooltip-geekblue .ant-tooltip-inner {
  background-color: #2f54eb;
}

.ant-tooltip-geekblue .ant-tooltip-arrow-content::before {
  background: #2f54eb;
}

.ant-tooltip-purple .ant-tooltip-inner {
  background-color: #722ed1;
}

.ant-tooltip-purple .ant-tooltip-arrow-content::before {
  background: #722ed1;
}

.ant-tooltip-rtl {
  direction: rtl;
}

.ant-tooltip-rtl .ant-tooltip-inner {
  text-align: right;
}