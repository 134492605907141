.ant-empty {
  margin: 0 0.11rem;
  font-size: 0.19rem;
  line-height: 1.5715;
  text-align: center;
}

.ant-empty-image {
  height: 1.33rem;
  margin-bottom: 0.11rem;
}

.ant-empty-image img {
  height: 100%;
}

.ant-empty-image svg {
  height: 100%;
  margin: auto;
}

.ant-empty-footer {
  margin-top: 0.21rem;
}

.ant-empty-normal {
  margin: 0.43rem 0;
  color: rgba(0,0,0,.25);
}

.ant-empty-normal .ant-empty-image {
  height: 0.53rem;
}

.ant-empty-small {
  margin: 0.11rem 0;
  color: rgba(0,0,0,.25);
}

.ant-empty-small .ant-empty-image {
  height: 0.47rem;
}

.ant-empty-img-default-ellipse {
  fill: #f5f5f5;
  fill-opacity: .8;
}

.ant-empty-img-default-path-1 {
  fill: #aeb8c2;
}

.ant-empty-img-default-path-2 {
  fill: url("#linearGradient-1");
}

.ant-empty-img-default-path-3 {
  fill: #f5f5f7;
}

.ant-empty-img-default-path-4 {
  fill: #dce0e6;
}

.ant-empty-img-default-path-5 {
  fill: #dce0e6;
}

.ant-empty-img-default-g {
  fill: #fff;
}

.ant-empty-img-simple-ellipse {
  fill: #f5f5f5;
}

.ant-empty-img-simple-g {
  stroke: #d9d9d9;
}

.ant-empty-img-simple-path {
  fill: #fafafa;
}

.ant-empty-rtl {
  direction: rtl;
}