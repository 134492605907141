.ant-menu-item-danger.ant-menu-item {
  color: #ff4d4f;
}

.ant-menu-item-danger.ant-menu-item:hover,
.ant-menu-item-danger.ant-menu-item-active {
  color: #ff4d4f;
}

.ant-menu-item-danger.ant-menu-item:active {
  background: #fff1f0;
}

.ant-menu-item-danger.ant-menu-item-selected {
  color: #ff4d4f;
}

.ant-menu-item-danger.ant-menu-item-selected>a,
.ant-menu-item-danger.ant-menu-item-selected>a:hover {
  color: #ff4d4f;
}

.ant-menu:not(.ant-menu-horizontal) .ant-menu-item-danger.ant-menu-item-selected {
  background-color: #fff1f0;
}

.ant-menu-inline .ant-menu-item-danger.ant-menu-item::after {
  border-right-color: #ff4d4f;
}

.ant-menu-dark .ant-menu-item-danger.ant-menu-item,
.ant-menu-dark .ant-menu-item-danger.ant-menu-item:hover,
.ant-menu-dark .ant-menu-item-danger.ant-menu-item>a {
  color: #ff4d4f;
}

.ant-menu-dark.ant-menu-dark:not(.ant-menu-horizontal) .ant-menu-item-danger.ant-menu-item-selected {
  color: #fff;
  background-color: #ff4d4f;
}

.ant-menu {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  font-variant: tabular-nums;
  line-height: 1.5715;
  font-feature-settings: "tnum";
  margin-bottom: 0;
  padding-left: 0;
  color: rgba(0,0,0,.85);
  font-size: 0.19rem;
  line-height: 0;
  text-align: left;
  list-style: none;
  background: #fff;
  outline: none;
  box-shadow: 0 0.04rem 0.08rem -0.05rem rgba(0,0,0,.12),0 0.08rem 0.21rem 0 rgba(0,0,0,.08),0 0.12rem 0.37rem 0.11rem rgba(0,0,0,.05);
  transition: background .3s,width .3s cubic-bezier(0.2, 0, 0, 1) 0s;
}

.ant-menu::before {
  display: table;
  content: "";
}

.ant-menu::after {
  display: table;
  clear: both;
  content: "";
}

.ant-menu.ant-menu-root:focus-visible {
  box-shadow: 0 0 0 0.03rem #fff6a3;
}

.ant-menu ul,
.ant-menu ol {
  margin: 0;
  padding: 0;
  list-style: none;
}

.ant-menu-overflow {
  display: flex;
}

.ant-menu-overflow-item {
  flex: none;
}

.ant-menu-hidden,
.ant-menu-submenu-hidden {
  display: none;
}

.ant-menu-item-group-title {
  height: 1.5715;
  padding: 0.11rem 0.21rem;
  color: rgba(0,0,0,.45);
  font-size: 0.19rem;
  line-height: 1.5715;
  transition: all .3s;
}

.ant-menu-horizontal .ant-menu-submenu {
  transition: border-color .3s cubic-bezier(0.645, 0.045, 0.355, 1),background .3s cubic-bezier(0.645, 0.045, 0.355, 1);
}

.ant-menu-submenu,
.ant-menu-submenu-inline {
  transition: border-color .3s cubic-bezier(0.645, 0.045, 0.355, 1),background .3s cubic-bezier(0.645, 0.045, 0.355, 1),padding .15s cubic-bezier(0.645, 0.045, 0.355, 1);
}

.ant-menu-submenu-selected {
  color: #f9be00;
}

.ant-menu-item:active,
.ant-menu-submenu-title:active {
  background: #fffde6;
}

.ant-menu-submenu .ant-menu-sub {
  cursor: auto;
  cursor: initial;
  transition: background .3s cubic-bezier(0.645, 0.045, 0.355, 1),padding .3s cubic-bezier(0.645, 0.045, 0.355, 1);
}

.ant-menu-title-content {
  transition: color .3s;
}

.ant-menu-item a {
  color: rgba(0,0,0,.85);
}

.ant-menu-item a:hover {
  color: #f9be00;
}

.ant-menu-item a::before {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: rgba(0,0,0,0);
  content: "";
}

.ant-menu-item>.ant-badge a {
  color: rgba(0,0,0,.85);
}

.ant-menu-item>.ant-badge a:hover {
  color: #f9be00;
}

.ant-menu-item-divider {
  overflow: hidden;
  line-height: 0;
  border-color: #f0f0f0;
  border-style: solid;
  border-width: 0.01rem 0 0;
}

.ant-menu-item-divider-dashed {
  border-style: dashed;
}

.ant-menu-horizontal .ant-menu-item,
.ant-menu-horizontal .ant-menu-submenu {
  margin-top: -0.01rem;
}

.ant-menu-horizontal>.ant-menu-item:hover,
.ant-menu-horizontal>.ant-menu-item-active,
.ant-menu-horizontal>.ant-menu-submenu .ant-menu-submenu-title:hover {
  background-color: rgba(0,0,0,0);
}

.ant-menu-item-selected {
  color: #f9be00;
}

.ant-menu-item-selected a,
.ant-menu-item-selected a:hover {
  color: #f9be00;
}

.ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected {
  background-color: #fffde6;
}

.ant-menu-inline,
.ant-menu-vertical,
.ant-menu-vertical-left {
  border-right: 0.01rem solid #f0f0f0;
}

.ant-menu-vertical-right {
  border-left: 0.01rem solid #f0f0f0;
}

.ant-menu-vertical.ant-menu-sub,
.ant-menu-vertical-left.ant-menu-sub,
.ant-menu-vertical-right.ant-menu-sub {
  min-width: 2.13rem;
  max-height: calc(100vh - 1.33rem);
  padding: 0;
  overflow: hidden;
  border-right: 0;
}

.ant-menu-vertical.ant-menu-sub:not([class*=-active]),
.ant-menu-vertical-left.ant-menu-sub:not([class*=-active]),
.ant-menu-vertical-right.ant-menu-sub:not([class*=-active]) {
  overflow-x: hidden;
  overflow-y: auto;
}

.ant-menu-vertical.ant-menu-sub .ant-menu-item,
.ant-menu-vertical-left.ant-menu-sub .ant-menu-item,
.ant-menu-vertical-right.ant-menu-sub .ant-menu-item {
  left: 0;
  margin-left: 0;
  border-right: 0;
}

.ant-menu-vertical.ant-menu-sub .ant-menu-item::after,
.ant-menu-vertical-left.ant-menu-sub .ant-menu-item::after,
.ant-menu-vertical-right.ant-menu-sub .ant-menu-item::after {
  border-right: 0;
}

.ant-menu-vertical.ant-menu-sub>.ant-menu-item,
.ant-menu-vertical-left.ant-menu-sub>.ant-menu-item,
.ant-menu-vertical-right.ant-menu-sub>.ant-menu-item,
.ant-menu-vertical.ant-menu-sub>.ant-menu-submenu,
.ant-menu-vertical-left.ant-menu-sub>.ant-menu-submenu,
.ant-menu-vertical-right.ant-menu-sub>.ant-menu-submenu {
  transform-origin: 0 0;
}

.ant-menu-horizontal.ant-menu-sub {
  min-width: 1.52rem;
}

.ant-menu-horizontal .ant-menu-item,
.ant-menu-horizontal .ant-menu-submenu-title {
  transition: border-color .3s,background .3s;
}

.ant-menu-item,
.ant-menu-submenu-title {
  position: relative;
  display: block;
  margin: 0;
  padding: 0 0.27rem;
  white-space: nowrap;
  cursor: pointer;
  transition: border-color .3s,background .3s,padding .3s cubic-bezier(0.645, 0.045, 0.355, 1);
}

.ant-menu-item .ant-menu-item-icon,
.ant-menu-submenu-title .ant-menu-item-icon,
.ant-menu-item .anticon,
.ant-menu-submenu-title .anticon {
  min-width: 0.19rem;
  font-size: 0.19rem;
  transition: font-size .15s cubic-bezier(0.215, 0.61, 0.355, 1),margin .3s cubic-bezier(0.645, 0.045, 0.355, 1),color .3s;
}

.ant-menu-item .ant-menu-item-icon+span,
.ant-menu-submenu-title .ant-menu-item-icon+span,
.ant-menu-item .anticon+span,
.ant-menu-submenu-title .anticon+span {
  margin-left: 0.13rem;
  opacity: 1;
  transition: opacity .3s cubic-bezier(0.645, 0.045, 0.355, 1),margin .3s,color .3s;
}

.ant-menu-item .ant-menu-item-icon.svg,
.ant-menu-submenu-title .ant-menu-item-icon.svg {
  vertical-align: -0.125em;
}

.ant-menu-item.ant-menu-item-only-child>.anticon,
.ant-menu-submenu-title.ant-menu-item-only-child>.anticon,
.ant-menu-item.ant-menu-item-only-child>.ant-menu-item-icon,
.ant-menu-submenu-title.ant-menu-item-only-child>.ant-menu-item-icon {
  margin-right: 0;
}

.ant-menu-item:focus-visible,
.ant-menu-submenu-title:focus-visible {
  box-shadow: 0 0 0 0.03rem #fff6a3;
}

.ant-menu>.ant-menu-item-divider {
  margin: 0.01rem 0;
  padding: 0;
}

.ant-menu-submenu-popup {
  position: absolute;
  z-index: 1050;
  background: rgba(0,0,0,0);
  border-radius: 0.03rem;
  box-shadow: none;
  transform-origin: 0 0;
}

.ant-menu-submenu-popup::before {
  position: absolute;
  top: -0.09rem;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: -1;
  width: 100%;
  height: 100%;
  opacity: .0001;
  content: " ";
}

.ant-menu-submenu-placement-rightTop::before {
  top: 0;
  left: -0.09rem;
}

.ant-menu-submenu>.ant-menu {
  background-color: #fff;
  border-radius: 0.03rem;
}

.ant-menu-submenu>.ant-menu-submenu-title::after {
  transition: transform .3s cubic-bezier(0.645, 0.045, 0.355, 1);
}

.ant-menu-submenu-popup>.ant-menu {
  background-color: #fff;
}

.ant-menu-submenu-expand-icon,
.ant-menu-submenu-arrow {
  position: absolute;
  top: 50%;
  right: 0.21rem;
  width: 0.13rem;
  color: rgba(0,0,0,.85);
  transform: translateY(-50%);
  transition: transform .3s cubic-bezier(0.645, 0.045, 0.355, 1);
}

.ant-menu-submenu-arrow::before,
.ant-menu-submenu-arrow::after {
  position: absolute;
  width: 0.08rem;
  height: 0.02rem;
  background-color: currentcolor;
  border-radius: 0.03rem;
  transition: background .3s cubic-bezier(0.645, 0.045, 0.355, 1),transform .3s cubic-bezier(0.645, 0.045, 0.355, 1),top .3s cubic-bezier(0.645, 0.045, 0.355, 1),color .3s cubic-bezier(0.645, 0.045, 0.355, 1);
  content: "";
}

.ant-menu-submenu-arrow::before {
  transform: rotate(45deg) translateY(-0.03rem);
}

.ant-menu-submenu-arrow::after {
  transform: rotate(-45deg) translateY(0.03rem);
}

.ant-menu-submenu:hover>.ant-menu-submenu-title>.ant-menu-submenu-expand-icon,
.ant-menu-submenu:hover>.ant-menu-submenu-title>.ant-menu-submenu-arrow {
  color: #f9be00;
}

.ant-menu-inline-collapsed .ant-menu-submenu-arrow::before,
.ant-menu-submenu-inline .ant-menu-submenu-arrow::before {
  transform: rotate(-45deg) translateX(0.03rem);
}

.ant-menu-inline-collapsed .ant-menu-submenu-arrow::after,
.ant-menu-submenu-inline .ant-menu-submenu-arrow::after {
  transform: rotate(45deg) translateX(-0.03rem);
}

.ant-menu-submenu-horizontal .ant-menu-submenu-arrow {
  display: none;
}

.ant-menu-submenu-open.ant-menu-submenu-inline>.ant-menu-submenu-title>.ant-menu-submenu-arrow {
  transform: translateY(-0.03rem);
}

.ant-menu-submenu-open.ant-menu-submenu-inline>.ant-menu-submenu-title>.ant-menu-submenu-arrow::after {
  transform: rotate(-45deg) translateX(-0.03rem);
}

.ant-menu-submenu-open.ant-menu-submenu-inline>.ant-menu-submenu-title>.ant-menu-submenu-arrow::before {
  transform: rotate(45deg) translateX(0.03rem);
}

.ant-menu-vertical .ant-menu-submenu-selected,
.ant-menu-vertical-left .ant-menu-submenu-selected,
.ant-menu-vertical-right .ant-menu-submenu-selected {
  color: #f9be00;
}

.ant-menu-horizontal {
  line-height: 0.61rem;
  border: 0;
  border-bottom: 0.01rem solid #f0f0f0;
  box-shadow: none;
}

.ant-menu-horizontal:not(.ant-menu-dark)>.ant-menu-item,
.ant-menu-horizontal:not(.ant-menu-dark)>.ant-menu-submenu {
  margin-top: -0.01rem;
  margin-bottom: 0;
  padding: 0 0.27rem;
}

.ant-menu-horizontal:not(.ant-menu-dark)>.ant-menu-item:hover,
.ant-menu-horizontal:not(.ant-menu-dark)>.ant-menu-submenu:hover,
.ant-menu-horizontal:not(.ant-menu-dark)>.ant-menu-item-active,
.ant-menu-horizontal:not(.ant-menu-dark)>.ant-menu-submenu-active,
.ant-menu-horizontal:not(.ant-menu-dark)>.ant-menu-item-open,
.ant-menu-horizontal:not(.ant-menu-dark)>.ant-menu-submenu-open,
.ant-menu-horizontal:not(.ant-menu-dark)>.ant-menu-item-selected,
.ant-menu-horizontal:not(.ant-menu-dark)>.ant-menu-submenu-selected {
  color: #f9be00;
}

.ant-menu-horizontal:not(.ant-menu-dark)>.ant-menu-item:hover::after,
.ant-menu-horizontal:not(.ant-menu-dark)>.ant-menu-submenu:hover::after,
.ant-menu-horizontal:not(.ant-menu-dark)>.ant-menu-item-active::after,
.ant-menu-horizontal:not(.ant-menu-dark)>.ant-menu-submenu-active::after,
.ant-menu-horizontal:not(.ant-menu-dark)>.ant-menu-item-open::after,
.ant-menu-horizontal:not(.ant-menu-dark)>.ant-menu-submenu-open::after,
.ant-menu-horizontal:not(.ant-menu-dark)>.ant-menu-item-selected::after,
.ant-menu-horizontal:not(.ant-menu-dark)>.ant-menu-submenu-selected::after {
  border-bottom: 0.03rem solid #f9be00;
}

.ant-menu-horizontal>.ant-menu-item,
.ant-menu-horizontal>.ant-menu-submenu {
  position: relative;
  top: 0.01rem;
  display: inline-block;
  vertical-align: bottom;
}

.ant-menu-horizontal>.ant-menu-item::after,
.ant-menu-horizontal>.ant-menu-submenu::after {
  position: absolute;
  right: 0.27rem;
  bottom: 0;
  left: 0.27rem;
  border-bottom: 0.03rem solid rgba(0,0,0,0);
  transition: border-color .3s cubic-bezier(0.645, 0.045, 0.355, 1);
  content: "";
}

.ant-menu-horizontal>.ant-menu-submenu>.ant-menu-submenu-title {
  padding: 0;
}

.ant-menu-horizontal>.ant-menu-item a {
  color: rgba(0,0,0,.85);
}

.ant-menu-horizontal>.ant-menu-item a:hover {
  color: #f9be00;
}

.ant-menu-horizontal>.ant-menu-item a::before {
  bottom: -0.03rem;
}

.ant-menu-horizontal>.ant-menu-item-selected a {
  color: #f9be00;
}

.ant-menu-horizontal::after {
  display: block;
  clear: both;
  height: 0;
  content: " ";
}

.ant-menu-vertical .ant-menu-item,
.ant-menu-vertical-left .ant-menu-item,
.ant-menu-vertical-right .ant-menu-item,
.ant-menu-inline .ant-menu-item {
  position: relative;
}

.ant-menu-vertical .ant-menu-item::after,
.ant-menu-vertical-left .ant-menu-item::after,
.ant-menu-vertical-right .ant-menu-item::after,
.ant-menu-inline .ant-menu-item::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  border-right: 0.04rem solid #f9be00;
  transform: scaleY(0.0001);
  opacity: 0;
  transition: transform .15s cubic-bezier(0.215, 0.61, 0.355, 1),opacity .15s cubic-bezier(0.215, 0.61, 0.355, 1);
  content: "";
}

.ant-menu-vertical .ant-menu-item,
.ant-menu-vertical-left .ant-menu-item,
.ant-menu-vertical-right .ant-menu-item,
.ant-menu-inline .ant-menu-item,
.ant-menu-vertical .ant-menu-submenu-title,
.ant-menu-vertical-left .ant-menu-submenu-title,
.ant-menu-vertical-right .ant-menu-submenu-title,
.ant-menu-inline .ant-menu-submenu-title {
  height: 0.53rem;
  margin-top: 0.05rem;
  margin-bottom: 0.05rem;
  padding: 0 0.21rem;
  overflow: hidden;
  line-height: 0.53rem;
  text-overflow: ellipsis;
}

.ant-menu-vertical .ant-menu-submenu,
.ant-menu-vertical-left .ant-menu-submenu,
.ant-menu-vertical-right .ant-menu-submenu,
.ant-menu-inline .ant-menu-submenu {
  padding-bottom: .0.03rem;
}

.ant-menu-vertical .ant-menu-item:not(:last-child),
.ant-menu-vertical-left .ant-menu-item:not(:last-child),
.ant-menu-vertical-right .ant-menu-item:not(:last-child),
.ant-menu-inline .ant-menu-item:not(:last-child) {
  margin-bottom: 0.11rem;
}

.ant-menu-vertical>.ant-menu-item,
.ant-menu-vertical-left>.ant-menu-item,
.ant-menu-vertical-right>.ant-menu-item,
.ant-menu-inline>.ant-menu-item,
.ant-menu-vertical>.ant-menu-submenu>.ant-menu-submenu-title,
.ant-menu-vertical-left>.ant-menu-submenu>.ant-menu-submenu-title,
.ant-menu-vertical-right>.ant-menu-submenu>.ant-menu-submenu-title,
.ant-menu-inline>.ant-menu-submenu>.ant-menu-submenu-title {
  height: 0.53rem;
  line-height: 0.53rem;
}

.ant-menu-vertical .ant-menu-item-group-list .ant-menu-submenu-title,
.ant-menu-vertical .ant-menu-submenu-title {
  padding-right: 0.45rem;
}

.ant-menu-inline {
  width: 100%;
}

.ant-menu-inline .ant-menu-selected::after,
.ant-menu-inline .ant-menu-item-selected::after {
  transform: scaleY(1);
  opacity: 1;
  transition: transform .15s cubic-bezier(0.645, 0.045, 0.355, 1),opacity .15s cubic-bezier(0.645, 0.045, 0.355, 1);
}

.ant-menu-inline .ant-menu-item,
.ant-menu-inline .ant-menu-submenu-title {
  width: calc(100% + 0.01rem);
}

.ant-menu-inline .ant-menu-item-group-list .ant-menu-submenu-title,
.ant-menu-inline .ant-menu-submenu-title {
  padding-right: 0.45rem;
}

.ant-menu-inline.ant-menu-root .ant-menu-item,
.ant-menu-inline.ant-menu-root .ant-menu-submenu-title {
  display: flex;
  align-items: center;
  transition: border-color .3s,background .3s,padding .1s cubic-bezier(0.215, 0.61, 0.355, 1);
}

.ant-menu-inline.ant-menu-root .ant-menu-item>.ant-menu-title-content,
.ant-menu-inline.ant-menu-root .ant-menu-submenu-title>.ant-menu-title-content {
  flex: auto;
  min-width: 0;
  overflow: hidden;
  text-overflow: ellipsis;
}

.ant-menu-inline.ant-menu-root .ant-menu-item>*,
.ant-menu-inline.ant-menu-root .ant-menu-submenu-title>* {
  flex: none;
}

.ant-menu.ant-menu-inline-collapsed {
  width: 1.07rem;
}

.ant-menu.ant-menu-inline-collapsed>.ant-menu-item,
.ant-menu.ant-menu-inline-collapsed>.ant-menu-item-group>.ant-menu-item-group-list>.ant-menu-item,
.ant-menu.ant-menu-inline-collapsed>.ant-menu-item-group>.ant-menu-item-group-list>.ant-menu-submenu>.ant-menu-submenu-title,
.ant-menu.ant-menu-inline-collapsed>.ant-menu-submenu>.ant-menu-submenu-title {
  left: 0;
  padding: 0 calc(50% - 0.11rem);
  text-overflow: clip;
}

.ant-menu.ant-menu-inline-collapsed>.ant-menu-item .ant-menu-submenu-arrow,
.ant-menu.ant-menu-inline-collapsed>.ant-menu-item-group>.ant-menu-item-group-list>.ant-menu-item .ant-menu-submenu-arrow,
.ant-menu.ant-menu-inline-collapsed>.ant-menu-item-group>.ant-menu-item-group-list>.ant-menu-submenu>.ant-menu-submenu-title .ant-menu-submenu-arrow,
.ant-menu.ant-menu-inline-collapsed>.ant-menu-submenu>.ant-menu-submenu-title .ant-menu-submenu-arrow {
  opacity: 0;
}

.ant-menu.ant-menu-inline-collapsed>.ant-menu-item .ant-menu-item-icon,
.ant-menu.ant-menu-inline-collapsed>.ant-menu-item-group>.ant-menu-item-group-list>.ant-menu-item .ant-menu-item-icon,
.ant-menu.ant-menu-inline-collapsed>.ant-menu-item-group>.ant-menu-item-group-list>.ant-menu-submenu>.ant-menu-submenu-title .ant-menu-item-icon,
.ant-menu.ant-menu-inline-collapsed>.ant-menu-submenu>.ant-menu-submenu-title .ant-menu-item-icon,
.ant-menu.ant-menu-inline-collapsed>.ant-menu-item .anticon,
.ant-menu.ant-menu-inline-collapsed>.ant-menu-item-group>.ant-menu-item-group-list>.ant-menu-item .anticon,
.ant-menu.ant-menu-inline-collapsed>.ant-menu-item-group>.ant-menu-item-group-list>.ant-menu-submenu>.ant-menu-submenu-title .anticon,
.ant-menu.ant-menu-inline-collapsed>.ant-menu-submenu>.ant-menu-submenu-title .anticon {
  margin: 0;
  font-size: 0.21rem;
  line-height: 0.53rem;
}

.ant-menu.ant-menu-inline-collapsed>.ant-menu-item .ant-menu-item-icon+span,
.ant-menu.ant-menu-inline-collapsed>.ant-menu-item-group>.ant-menu-item-group-list>.ant-menu-item .ant-menu-item-icon+span,
.ant-menu.ant-menu-inline-collapsed>.ant-menu-item-group>.ant-menu-item-group-list>.ant-menu-submenu>.ant-menu-submenu-title .ant-menu-item-icon+span,
.ant-menu.ant-menu-inline-collapsed>.ant-menu-submenu>.ant-menu-submenu-title .ant-menu-item-icon+span,
.ant-menu.ant-menu-inline-collapsed>.ant-menu-item .anticon+span,
.ant-menu.ant-menu-inline-collapsed>.ant-menu-item-group>.ant-menu-item-group-list>.ant-menu-item .anticon+span,
.ant-menu.ant-menu-inline-collapsed>.ant-menu-item-group>.ant-menu-item-group-list>.ant-menu-submenu>.ant-menu-submenu-title .anticon+span,
.ant-menu.ant-menu-inline-collapsed>.ant-menu-submenu>.ant-menu-submenu-title .anticon+span {
  display: inline-block;
  opacity: 0;
}

.ant-menu.ant-menu-inline-collapsed .ant-menu-item-icon,
.ant-menu.ant-menu-inline-collapsed .anticon {
  display: inline-block;
}

.ant-menu.ant-menu-inline-collapsed-tooltip {
  pointer-events: none;
}

.ant-menu.ant-menu-inline-collapsed-tooltip .ant-menu-item-icon,
.ant-menu.ant-menu-inline-collapsed-tooltip .anticon {
  display: none;
}

.ant-menu.ant-menu-inline-collapsed-tooltip a {
  color: hsla(0,0%,100%,.85);
}

.ant-menu.ant-menu-inline-collapsed .ant-menu-item-group-title {
  padding-right: 0.05rem;
  padding-left: 0.05rem;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.ant-menu-item-group-list {
  margin: 0;
  padding: 0;
}

.ant-menu-item-group-list .ant-menu-item,
.ant-menu-item-group-list .ant-menu-submenu-title {
  padding: 0 0.21rem 0 0.37rem;
}

.ant-menu-root.ant-menu-vertical,
.ant-menu-root.ant-menu-vertical-left,
.ant-menu-root.ant-menu-vertical-right,
.ant-menu-root.ant-menu-inline {
  box-shadow: none;
}

.ant-menu-root.ant-menu-inline-collapsed .ant-menu-item>.ant-menu-inline-collapsed-noicon,
.ant-menu-root.ant-menu-inline-collapsed .ant-menu-submenu .ant-menu-submenu-title>.ant-menu-inline-collapsed-noicon {
  font-size: 0.21rem;
  text-align: center;
}

.ant-menu-sub.ant-menu-inline {
  padding: 0;
  background: #fafafa;
  border: 0;
  border-radius: 0;
  box-shadow: none;
}

.ant-menu-sub.ant-menu-inline>.ant-menu-item,
.ant-menu-sub.ant-menu-inline>.ant-menu-submenu>.ant-menu-submenu-title {
  height: 0.53rem;
  line-height: 0.53rem;
  list-style-position: inside;
  list-style-type: disc;
}

.ant-menu-sub.ant-menu-inline .ant-menu-item-group-title {
  padding-left: 0.43rem;
}

.ant-menu-item-disabled,
.ant-menu-submenu-disabled {
  color: rgba(0,0,0,.25) !important;
  background: none;
  cursor: not-allowed;
}

.ant-menu-item-disabled::after,
.ant-menu-submenu-disabled::after {
  border-color: rgba(0,0,0,0) !important;
}

.ant-menu-item-disabled a,
.ant-menu-submenu-disabled a {
  color: rgba(0,0,0,.25) !important;
  pointer-events: none;
}

.ant-menu-item-disabled>.ant-menu-submenu-title,
.ant-menu-submenu-disabled>.ant-menu-submenu-title {
  color: rgba(0,0,0,.25) !important;
  cursor: not-allowed;
}

.ant-menu-item-disabled>.ant-menu-submenu-title>.ant-menu-submenu-arrow::before,
.ant-menu-submenu-disabled>.ant-menu-submenu-title>.ant-menu-submenu-arrow::before,
.ant-menu-item-disabled>.ant-menu-submenu-title>.ant-menu-submenu-arrow::after,
.ant-menu-submenu-disabled>.ant-menu-submenu-title>.ant-menu-submenu-arrow::after {
  background: rgba(0,0,0,.25) !important;
}

.ant-layout-header .ant-menu {
  line-height: inherit;
}

.ant-menu-inline-collapsed-tooltip a,
.ant-menu-inline-collapsed-tooltip a:hover {
  color: #fff;
}

.ant-menu-light .ant-menu-item:hover,
.ant-menu-light .ant-menu-item-active,
.ant-menu-light .ant-menu:not(.ant-menu-inline) .ant-menu-submenu-open,
.ant-menu-light .ant-menu-submenu-active,
.ant-menu-light .ant-menu-submenu-title:hover {
  color: #f9be00;
}

.ant-menu.ant-menu-root:focus-visible {
  box-shadow: 0 0 0 0.03rem #d49b00;
}

.ant-menu-dark .ant-menu-item:focus-visible,
.ant-menu-dark .ant-menu-submenu-title:focus-visible {
  box-shadow: 0 0 0 0.03rem #d49b00;
}

.ant-menu.ant-menu-dark,
.ant-menu-dark .ant-menu-sub,
.ant-menu.ant-menu-dark .ant-menu-sub {
  color: hsla(0,0%,100%,.65);
  background: #001529;
}

.ant-menu.ant-menu-dark .ant-menu-submenu-title .ant-menu-submenu-arrow,
.ant-menu-dark .ant-menu-sub .ant-menu-submenu-title .ant-menu-submenu-arrow,
.ant-menu.ant-menu-dark .ant-menu-sub .ant-menu-submenu-title .ant-menu-submenu-arrow {
  opacity: .45;
  transition: all .3s;
}

.ant-menu.ant-menu-dark .ant-menu-submenu-title .ant-menu-submenu-arrow::after,
.ant-menu-dark .ant-menu-sub .ant-menu-submenu-title .ant-menu-submenu-arrow::after,
.ant-menu.ant-menu-dark .ant-menu-sub .ant-menu-submenu-title .ant-menu-submenu-arrow::after,
.ant-menu.ant-menu-dark .ant-menu-submenu-title .ant-menu-submenu-arrow::before,
.ant-menu-dark .ant-menu-sub .ant-menu-submenu-title .ant-menu-submenu-arrow::before,
.ant-menu.ant-menu-dark .ant-menu-sub .ant-menu-submenu-title .ant-menu-submenu-arrow::before {
  background: #fff;
}

.ant-menu-dark.ant-menu-submenu-popup {
  background: rgba(0,0,0,0);
}

.ant-menu-dark .ant-menu-inline.ant-menu-sub {
  background: #000c17;
}

.ant-menu-dark.ant-menu-horizontal {
  border-bottom: 0;
}

.ant-menu-dark.ant-menu-horizontal>.ant-menu-item,
.ant-menu-dark.ant-menu-horizontal>.ant-menu-submenu {
  top: 0;
  margin-top: 0;
  padding: 0 0.27rem;
  border-color: #001529;
  border-bottom: 0;
}

.ant-menu-dark.ant-menu-horizontal>.ant-menu-item:hover {
  background-color: #f9be00;
}

.ant-menu-dark.ant-menu-horizontal>.ant-menu-item>a::before {
  bottom: 0;
}

.ant-menu-dark .ant-menu-item,
.ant-menu-dark .ant-menu-item-group-title,
.ant-menu-dark .ant-menu-item>a,
.ant-menu-dark .ant-menu-item>span>a {
  color: hsla(0,0%,100%,.65);
}

.ant-menu-dark.ant-menu-inline,
.ant-menu-dark.ant-menu-vertical,
.ant-menu-dark.ant-menu-vertical-left,
.ant-menu-dark.ant-menu-vertical-right {
  border-right: 0;
}

.ant-menu-dark.ant-menu-inline .ant-menu-item,
.ant-menu-dark.ant-menu-vertical .ant-menu-item,
.ant-menu-dark.ant-menu-vertical-left .ant-menu-item,
.ant-menu-dark.ant-menu-vertical-right .ant-menu-item {
  left: 0;
  margin-left: 0;
  border-right: 0;
}

.ant-menu-dark.ant-menu-inline .ant-menu-item::after,
.ant-menu-dark.ant-menu-vertical .ant-menu-item::after,
.ant-menu-dark.ant-menu-vertical-left .ant-menu-item::after,
.ant-menu-dark.ant-menu-vertical-right .ant-menu-item::after {
  border-right: 0;
}

.ant-menu-dark.ant-menu-inline .ant-menu-item,
.ant-menu-dark.ant-menu-inline .ant-menu-submenu-title {
  width: 100%;
}

.ant-menu-dark .ant-menu-item:hover,
.ant-menu-dark .ant-menu-item-active,
.ant-menu-dark .ant-menu-submenu-active,
.ant-menu-dark .ant-menu-submenu-open,
.ant-menu-dark .ant-menu-submenu-selected,
.ant-menu-dark .ant-menu-submenu-title:hover {
  color: #fff;
  background-color: rgba(0,0,0,0);
}

.ant-menu-dark .ant-menu-item:hover>a,
.ant-menu-dark .ant-menu-item-active>a,
.ant-menu-dark .ant-menu-submenu-active>a,
.ant-menu-dark .ant-menu-submenu-open>a,
.ant-menu-dark .ant-menu-submenu-selected>a,
.ant-menu-dark .ant-menu-submenu-title:hover>a,
.ant-menu-dark .ant-menu-item:hover>span>a,
.ant-menu-dark .ant-menu-item-active>span>a,
.ant-menu-dark .ant-menu-submenu-active>span>a,
.ant-menu-dark .ant-menu-submenu-open>span>a,
.ant-menu-dark .ant-menu-submenu-selected>span>a,
.ant-menu-dark .ant-menu-submenu-title:hover>span>a {
  color: #fff;
}

.ant-menu-dark .ant-menu-item:hover>.ant-menu-submenu-title>.ant-menu-submenu-arrow,
.ant-menu-dark .ant-menu-item-active>.ant-menu-submenu-title>.ant-menu-submenu-arrow,
.ant-menu-dark .ant-menu-submenu-active>.ant-menu-submenu-title>.ant-menu-submenu-arrow,
.ant-menu-dark .ant-menu-submenu-open>.ant-menu-submenu-title>.ant-menu-submenu-arrow,
.ant-menu-dark .ant-menu-submenu-selected>.ant-menu-submenu-title>.ant-menu-submenu-arrow,
.ant-menu-dark .ant-menu-submenu-title:hover>.ant-menu-submenu-title>.ant-menu-submenu-arrow {
  opacity: 1;
}

.ant-menu-dark .ant-menu-item:hover>.ant-menu-submenu-title>.ant-menu-submenu-arrow::after,
.ant-menu-dark .ant-menu-item-active>.ant-menu-submenu-title>.ant-menu-submenu-arrow::after,
.ant-menu-dark .ant-menu-submenu-active>.ant-menu-submenu-title>.ant-menu-submenu-arrow::after,
.ant-menu-dark .ant-menu-submenu-open>.ant-menu-submenu-title>.ant-menu-submenu-arrow::after,
.ant-menu-dark .ant-menu-submenu-selected>.ant-menu-submenu-title>.ant-menu-submenu-arrow::after,
.ant-menu-dark .ant-menu-submenu-title:hover>.ant-menu-submenu-title>.ant-menu-submenu-arrow::after,
.ant-menu-dark .ant-menu-item:hover>.ant-menu-submenu-title>.ant-menu-submenu-arrow::before,
.ant-menu-dark .ant-menu-item-active>.ant-menu-submenu-title>.ant-menu-submenu-arrow::before,
.ant-menu-dark .ant-menu-submenu-active>.ant-menu-submenu-title>.ant-menu-submenu-arrow::before,
.ant-menu-dark .ant-menu-submenu-open>.ant-menu-submenu-title>.ant-menu-submenu-arrow::before,
.ant-menu-dark .ant-menu-submenu-selected>.ant-menu-submenu-title>.ant-menu-submenu-arrow::before,
.ant-menu-dark .ant-menu-submenu-title:hover>.ant-menu-submenu-title>.ant-menu-submenu-arrow::before {
  background: #fff;
}

.ant-menu-dark .ant-menu-item:hover {
  background-color: rgba(0,0,0,0);
}

.ant-menu-dark.ant-menu-dark:not(.ant-menu-horizontal) .ant-menu-item-selected {
  background-color: #f9be00;
}

.ant-menu-dark .ant-menu-item-selected {
  color: #fff;
  border-right: 0;
}

.ant-menu-dark .ant-menu-item-selected::after {
  border-right: 0;
}

.ant-menu-dark .ant-menu-item-selected>a,
.ant-menu-dark .ant-menu-item-selected>span>a,
.ant-menu-dark .ant-menu-item-selected>a:hover,
.ant-menu-dark .ant-menu-item-selected>span>a:hover {
  color: #fff;
}

.ant-menu-dark .ant-menu-item-selected .ant-menu-item-icon,
.ant-menu-dark .ant-menu-item-selected .anticon {
  color: #fff;
}

.ant-menu-dark .ant-menu-item-selected .ant-menu-item-icon+span,
.ant-menu-dark .ant-menu-item-selected .anticon+span {
  color: #fff;
}

.ant-menu.ant-menu-dark .ant-menu-item-selected,
.ant-menu-submenu-popup.ant-menu-dark .ant-menu-item-selected {
  background-color: #f9be00;
}

.ant-menu-dark .ant-menu-item-disabled,
.ant-menu-dark .ant-menu-submenu-disabled,
.ant-menu-dark .ant-menu-item-disabled>a,
.ant-menu-dark .ant-menu-submenu-disabled>a,
.ant-menu-dark .ant-menu-item-disabled>span>a,
.ant-menu-dark .ant-menu-submenu-disabled>span>a {
  color: hsla(0,0%,100%,.35) !important;
  opacity: .8;
}

.ant-menu-dark .ant-menu-item-disabled>.ant-menu-submenu-title,
.ant-menu-dark .ant-menu-submenu-disabled>.ant-menu-submenu-title {
  color: hsla(0,0%,100%,.35) !important;
}

.ant-menu-dark .ant-menu-item-disabled>.ant-menu-submenu-title>.ant-menu-submenu-arrow::before,
.ant-menu-dark .ant-menu-submenu-disabled>.ant-menu-submenu-title>.ant-menu-submenu-arrow::before,
.ant-menu-dark .ant-menu-item-disabled>.ant-menu-submenu-title>.ant-menu-submenu-arrow::after,
.ant-menu-dark .ant-menu-submenu-disabled>.ant-menu-submenu-title>.ant-menu-submenu-arrow::after {
  background: hsla(0,0%,100%,.35) !important;
}

.ant-menu.ant-menu-rtl {
  direction: rtl;
  text-align: right;
}

.ant-menu-rtl .ant-menu-item-group-title {
  text-align: right;
}

.ant-menu-rtl.ant-menu-inline,
.ant-menu-rtl.ant-menu-vertical {
  border-right: none;
  border-left: 0.01rem solid #f0f0f0;
}

.ant-menu-rtl.ant-menu-dark.ant-menu-inline,
.ant-menu-rtl.ant-menu-dark.ant-menu-vertical {
  border-left: none;
}

.ant-menu-rtl.ant-menu-vertical.ant-menu-sub>.ant-menu-item,
.ant-menu-rtl.ant-menu-vertical-left.ant-menu-sub>.ant-menu-item,
.ant-menu-rtl.ant-menu-vertical-right.ant-menu-sub>.ant-menu-item,
.ant-menu-rtl.ant-menu-vertical.ant-menu-sub>.ant-menu-submenu,
.ant-menu-rtl.ant-menu-vertical-left.ant-menu-sub>.ant-menu-submenu,
.ant-menu-rtl.ant-menu-vertical-right.ant-menu-sub>.ant-menu-submenu {
  transform-origin: top right;
}

.ant-menu-rtl .ant-menu-item .ant-menu-item-icon,
.ant-menu-rtl .ant-menu-submenu-title .ant-menu-item-icon,
.ant-menu-rtl .ant-menu-item .anticon,
.ant-menu-rtl .ant-menu-submenu-title .anticon {
  margin-right: auto;
  margin-left: 0.13rem;
}

.ant-menu-rtl .ant-menu-item.ant-menu-item-only-child>.ant-menu-item-icon,
.ant-menu-rtl .ant-menu-submenu-title.ant-menu-item-only-child>.ant-menu-item-icon,
.ant-menu-rtl .ant-menu-item.ant-menu-item-only-child>.anticon,
.ant-menu-rtl .ant-menu-submenu-title.ant-menu-item-only-child>.anticon {
  margin-left: 0;
}

.ant-menu-submenu-rtl.ant-menu-submenu-popup {
  transform-origin: 100% 0;
}

.ant-menu-rtl .ant-menu-submenu-vertical>.ant-menu-submenu-title .ant-menu-submenu-arrow,
.ant-menu-rtl .ant-menu-submenu-vertical-left>.ant-menu-submenu-title .ant-menu-submenu-arrow,
.ant-menu-rtl .ant-menu-submenu-vertical-right>.ant-menu-submenu-title .ant-menu-submenu-arrow,
.ant-menu-rtl .ant-menu-submenu-inline>.ant-menu-submenu-title .ant-menu-submenu-arrow {
  right: auto;
  left: 0.21rem;
}

.ant-menu-rtl .ant-menu-submenu-vertical>.ant-menu-submenu-title .ant-menu-submenu-arrow::before,
.ant-menu-rtl .ant-menu-submenu-vertical-left>.ant-menu-submenu-title .ant-menu-submenu-arrow::before,
.ant-menu-rtl .ant-menu-submenu-vertical-right>.ant-menu-submenu-title .ant-menu-submenu-arrow::before {
  transform: rotate(-45deg) translateY(-0.03rem);
}

.ant-menu-rtl .ant-menu-submenu-vertical>.ant-menu-submenu-title .ant-menu-submenu-arrow::after,
.ant-menu-rtl .ant-menu-submenu-vertical-left>.ant-menu-submenu-title .ant-menu-submenu-arrow::after,
.ant-menu-rtl .ant-menu-submenu-vertical-right>.ant-menu-submenu-title .ant-menu-submenu-arrow::after {
  transform: rotate(45deg) translateY(0.03rem);
}

.ant-menu-rtl.ant-menu-vertical .ant-menu-item::after,
.ant-menu-rtl.ant-menu-vertical-left .ant-menu-item::after,
.ant-menu-rtl.ant-menu-vertical-right .ant-menu-item::after,
.ant-menu-rtl.ant-menu-inline .ant-menu-item::after {
  right: auto;
  left: 0;
}

.ant-menu-rtl.ant-menu-vertical .ant-menu-item,
.ant-menu-rtl.ant-menu-vertical-left .ant-menu-item,
.ant-menu-rtl.ant-menu-vertical-right .ant-menu-item,
.ant-menu-rtl.ant-menu-inline .ant-menu-item,
.ant-menu-rtl.ant-menu-vertical .ant-menu-submenu-title,
.ant-menu-rtl.ant-menu-vertical-left .ant-menu-submenu-title,
.ant-menu-rtl.ant-menu-vertical-right .ant-menu-submenu-title,
.ant-menu-rtl.ant-menu-inline .ant-menu-submenu-title {
  text-align: right;
}

.ant-menu-rtl.ant-menu-inline .ant-menu-submenu-title {
  padding-right: 0;
  padding-left: 0.45rem;
}

.ant-menu-rtl.ant-menu-vertical .ant-menu-submenu-title {
  padding-right: 0.21rem;
  padding-left: 0.45rem;
}

.ant-menu-rtl.ant-menu-inline-collapsed.ant-menu-vertical .ant-menu-submenu-title {
  padding: 0 calc(50% - 0.11rem);
}

.ant-menu-rtl .ant-menu-item-group-list .ant-menu-item,
.ant-menu-rtl .ant-menu-item-group-list .ant-menu-submenu-title {
  padding: 0 0.37rem 0 0.21rem;
}

.ant-menu-sub.ant-menu-inline {
  border: 0;
}

.ant-menu-rtl.ant-menu-sub.ant-menu-inline .ant-menu-item-group-title {
  padding-right: 0.43rem;
  padding-left: 0;
}