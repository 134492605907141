.ant-select-single .ant-select-selector {
  display: flex;
}

.ant-select-single .ant-select-selector .ant-select-selection-search {
  position: absolute;
  top: 0;
  right: 0.15rem;
  bottom: 0;
  left: 0.15rem;
}

.ant-select-single .ant-select-selector .ant-select-selection-search-input {
  width: 100%;
}

.ant-select-single .ant-select-selector .ant-select-selection-item,
.ant-select-single .ant-select-selector .ant-select-selection-placeholder {
  padding: 0;
  line-height: 0.4rem;
  transition: all .3s;
}

@supports (-moz-appearance: meterbar) {
  .ant-select-single .ant-select-selector .ant-select-selection-item,
  .ant-select-single .ant-select-selector .ant-select-selection-placeholder {
    line-height: 30px;
  }
}

.ant-select-single .ant-select-selector .ant-select-selection-item {
  position: relative;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.ant-select-single .ant-select-selector .ant-select-selection-placeholder {
  transition: none;
  pointer-events: none;
}

.ant-select-single .ant-select-selector::after,
.ant-select-single .ant-select-selector .ant-select-selection-item::after,
.ant-select-single .ant-select-selector .ant-select-selection-placeholder::after {
  display: inline-block;
  width: 0;
  visibility: hidden;
  content: " ";
}

.ant-select-single.ant-select-show-arrow .ant-select-selection-search {
  right: 0.33rem;
}

.ant-select-single.ant-select-show-arrow .ant-select-selection-item,
.ant-select-single.ant-select-show-arrow .ant-select-selection-placeholder {
  padding-right: 0.24rem;
}

.ant-select-single.ant-select-open .ant-select-selection-item {
  color: #bfbfbf;
}

.ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
  width: 100%;
  height: 0.43rem;
  padding: 0 0.15rem;
}

.ant-select-single:not(.ant-select-customize-input) .ant-select-selector .ant-select-selection-search-input {
  height: 0.4rem;
}

.ant-select-single:not(.ant-select-customize-input) .ant-select-selector::after {
  line-height: 0.4rem;
}

.ant-select-single.ant-select-customize-input .ant-select-selector::after {
  display: none;
}

.ant-select-single.ant-select-customize-input .ant-select-selector .ant-select-selection-search {
  position: static;
  width: 100%;
}

.ant-select-single.ant-select-customize-input .ant-select-selector .ant-select-selection-placeholder {
  position: absolute;
  right: 0;
  left: 0;
  padding: 0 0.15rem;
}

.ant-select-single.ant-select-customize-input .ant-select-selector .ant-select-selection-placeholder::after {
  display: none;
}

.ant-select-single.ant-select-lg:not(.ant-select-customize-input) .ant-select-selector {
  height: 0.53rem;
}

.ant-select-single.ant-select-lg:not(.ant-select-customize-input) .ant-select-selector::after,
.ant-select-single.ant-select-lg:not(.ant-select-customize-input) .ant-select-selector .ant-select-selection-item,
.ant-select-single.ant-select-lg:not(.ant-select-customize-input) .ant-select-selector .ant-select-selection-placeholder {
  line-height: 0.51rem;
}

.ant-select-single.ant-select-lg:not(.ant-select-customize-input):not(.ant-select-customize-input) .ant-select-selection-search-input {
  height: 0.51rem;
}

.ant-select-single.ant-select-sm:not(.ant-select-customize-input) .ant-select-selector {
  height: 0.32rem;
}

.ant-select-single.ant-select-sm:not(.ant-select-customize-input) .ant-select-selector::after,
.ant-select-single.ant-select-sm:not(.ant-select-customize-input) .ant-select-selector .ant-select-selection-item,
.ant-select-single.ant-select-sm:not(.ant-select-customize-input) .ant-select-selector .ant-select-selection-placeholder {
  line-height: 0.29rem;
}

.ant-select-single.ant-select-sm:not(.ant-select-customize-input):not(.ant-select-customize-input) .ant-select-selection-search-input {
  height: 0.29rem;
}

.ant-select-single.ant-select-sm:not(.ant-select-customize-input) .ant-select-selection-search {
  right: 0.09rem;
  left: 0.09rem;
}

.ant-select-single.ant-select-sm:not(.ant-select-customize-input) .ant-select-selector {
  padding: 0 0.09rem;
}

.ant-select-single.ant-select-sm:not(.ant-select-customize-input).ant-select-show-arrow .ant-select-selection-search {
  right: 0.37rem;
}

.ant-select-single.ant-select-sm:not(.ant-select-customize-input).ant-select-show-arrow .ant-select-selection-item,
.ant-select-single.ant-select-sm:not(.ant-select-customize-input).ant-select-show-arrow .ant-select-selection-placeholder {
  padding-right: 0.28rem;
}

.ant-select-single.ant-select-lg:not(.ant-select-customize-input) .ant-select-selector {
  padding: 0 0.15rem;
}

.ant-select-selection-overflow {
  position: relative;
  display: flex;
  flex: auto;
  flex-wrap: wrap;
  max-width: 100%;
}

.ant-select-selection-overflow-item {
  flex: none;
  align-self: center;
  max-width: 100%;
}

.ant-select-multiple .ant-select-selector {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  padding: 0.01rem 0.05rem;
}

.ant-select-show-search.ant-select-multiple .ant-select-selector {
  cursor: text;
}

.ant-select-disabled.ant-select-multiple .ant-select-selector {
  background: #f5f5f5;
  cursor: not-allowed;
}

.ant-select-multiple .ant-select-selector::after {
  display: inline-block;
  width: 0;
  margin: 0.03rem 0;
  line-height: 0.32rem;
  content: " ";
}

.ant-select-multiple.ant-select-show-arrow .ant-select-selector,
.ant-select-multiple.ant-select-allow-clear .ant-select-selector {
  padding-right: 0.32rem;
}

.ant-select-multiple .ant-select-selection-item {
  position: relative;
  display: flex;
  flex: none;
  box-sizing: border-box;
  max-width: 100%;
  height: 0.32rem;
  margin-top: 0.03rem;
  margin-bottom: 0.03rem;
  line-height: 0.29rem;
  background: #f5f5f5;
  border: 0.01rem solid #f0f0f0;
  border-radius: 0.03rem;
  cursor: default;
  transition: font-size .3s,line-height .3s,height .3s;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  margin-inline-end: 0.05rem;
  padding-inline-start: 0.11rem;
  padding-inline-end: 0.05rem;
}

.ant-select-disabled.ant-select-multiple .ant-select-selection-item {
  color: #bfbfbf;
  border-color: #d9d9d9;
  cursor: not-allowed;
}

.ant-select-multiple .ant-select-selection-item-content {
  display: inline-block;
  margin-right: 0.05rem;
  overflow: hidden;
  white-space: pre;
  text-overflow: ellipsis;
}

.ant-select-multiple .ant-select-selection-item-remove {
  color: inherit;
  font-style: normal;
  line-height: 0;
  text-align: center;
  text-transform: none;
  vertical-align: -0.125em;
  text-rendering: optimizelegibility;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  display: inline-block;
  color: rgba(0,0,0,.45);
  font-weight: bold;
  font-size: 0.13rem;
  line-height: inherit;
  cursor: pointer;
}

.ant-select-multiple .ant-select-selection-item-remove>* {
  line-height: 1;
}

.ant-select-multiple .ant-select-selection-item-remove svg {
  display: inline-block;
}

.ant-select-multiple .ant-select-selection-item-remove::before {
  display: none;
}

.ant-select-multiple .ant-select-selection-item-remove .ant-select-multiple .ant-select-selection-item-remove-icon {
  display: block;
}

.ant-select-multiple .ant-select-selection-item-remove>.anticon {
  vertical-align: middle;
}

.ant-select-multiple .ant-select-selection-item-remove:hover {
  color: rgba(0,0,0,.75);
}

.ant-select-multiple .ant-select-selection-overflow-item+.ant-select-selection-overflow-item .ant-select-selection-search {
  margin-inline-start: 0;
}

.ant-select-multiple .ant-select-selection-search {
  position: relative;
  max-width: 100%;
  margin-inline-start: 0.09rem;
}

.ant-select-multiple .ant-select-selection-search-input,
.ant-select-multiple .ant-select-selection-search-mirror {
  height: 0.32rem;
  font-family: -apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,"Helvetica Neue",Arial,"Noto Sans",sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol","Noto Color Emoji";
  line-height: 0.32rem;
  transition: all .3s;
}

.ant-select-multiple .ant-select-selection-search-input {
  width: 100%;
  min-width: 0.05rem;
}

.ant-select-multiple .ant-select-selection-search-mirror {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 999;
  white-space: pre;
  visibility: hidden;
}

.ant-select-multiple .ant-select-selection-placeholder {
  position: absolute;
  top: 50%;
  right: 0.15rem;
  left: 0.15rem;
  transform: translateY(-50%);
  transition: all .3s;
}

.ant-select-multiple.ant-select-lg .ant-select-selector::after {
  line-height: 0.43rem;
}

.ant-select-multiple.ant-select-lg .ant-select-selection-item {
  height: 0.43rem;
  line-height: 0.4rem;
}

.ant-select-multiple.ant-select-lg .ant-select-selection-search {
  height: 0.43rem;
  line-height: 0.43rem;
}

.ant-select-multiple.ant-select-lg .ant-select-selection-search-input,
.ant-select-multiple.ant-select-lg .ant-select-selection-search-mirror {
  height: 0.43rem;
  line-height: 0.4rem;
}

.ant-select-multiple.ant-select-sm .ant-select-selector::after {
  line-height: 0.21rem;
}

.ant-select-multiple.ant-select-sm .ant-select-selection-item {
  height: 0.21rem;
  line-height: 0.19rem;
}

.ant-select-multiple.ant-select-sm .ant-select-selection-search {
  height: 0.21rem;
  line-height: 0.21rem;
}

.ant-select-multiple.ant-select-sm .ant-select-selection-search-input,
.ant-select-multiple.ant-select-sm .ant-select-selection-search-mirror {
  height: 0.21rem;
  line-height: 0.19rem;
}

.ant-select-multiple.ant-select-sm .ant-select-selection-placeholder {
  left: 0.09rem;
}

.ant-select-multiple.ant-select-sm .ant-select-selection-search {
  margin-inline-start: 0.04rem;
}

.ant-select-multiple.ant-select-lg .ant-select-selection-item {
  height: 0.43rem;
  line-height: 0.43rem;
}

.ant-select-disabled .ant-select-selection-item-remove {
  display: none;
}

.ant-select-status-error.ant-select:not(.ant-select-disabled):not(.ant-select-customize-input) .ant-select-selector {
  background-color: #fff;
  border-color: #ff4d4f !important;
}

.ant-select-status-error.ant-select:not(.ant-select-disabled):not(.ant-select-customize-input).ant-select-open .ant-select-selector,
.ant-select-status-error.ant-select:not(.ant-select-disabled):not(.ant-select-customize-input).ant-select-focused .ant-select-selector {
  border-color: #ff7875;
  box-shadow: 0 0 0 0.03rem rgba(255,77,79,.2);
  border-right-width: 0.01rem;
  outline: 0;
}

.ant-select-status-warning.ant-select:not(.ant-select-disabled):not(.ant-select-customize-input) .ant-select-selector {
  background-color: #fff;
  border-color: #faad14 !important;
}

.ant-select-status-warning.ant-select:not(.ant-select-disabled):not(.ant-select-customize-input).ant-select-open .ant-select-selector,
.ant-select-status-warning.ant-select:not(.ant-select-disabled):not(.ant-select-customize-input).ant-select-focused .ant-select-selector {
  border-color: #ffc53d;
  box-shadow: 0 0 0 0.03rem rgba(250,173,20,.2);
  border-right-width: 0.01rem;
  outline: 0;
}

.ant-select-status-error.ant-select-has-feedback .ant-select-clear,
.ant-select-status-warning.ant-select-has-feedback .ant-select-clear,
.ant-select-status-success.ant-select-has-feedback .ant-select-clear,
.ant-select-status-validating.ant-select-has-feedback .ant-select-clear {
  right: 0.43rem;
}

.ant-select-status-error.ant-select-has-feedback .ant-select-selection-selected-value,
.ant-select-status-warning.ant-select-has-feedback .ant-select-selection-selected-value,
.ant-select-status-success.ant-select-has-feedback .ant-select-selection-selected-value,
.ant-select-status-validating.ant-select-has-feedback .ant-select-selection-selected-value {
  padding-right: 0.56rem;
}

.ant-select {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: rgba(0,0,0,.85);
  font-size: 0.19rem;
  font-variant: tabular-nums;
  line-height: 1.5715;
  list-style: none;
  font-feature-settings: "tnum";
  position: relative;
  display: inline-block;
  cursor: pointer;
}

.ant-select:not(.ant-select-customize-input) .ant-select-selector {
  position: relative;
  background-color: #fff;
  border: 0.01rem solid #d9d9d9;
  border-radius: 0.03rem;
  transition: all .3s cubic-bezier(0.645, 0.045, 0.355, 1);
}

.ant-select:not(.ant-select-customize-input) .ant-select-selector input {
  cursor: pointer;
}

.ant-select-show-search.ant-select:not(.ant-select-customize-input) .ant-select-selector {
  cursor: text;
}

.ant-select-show-search.ant-select:not(.ant-select-customize-input) .ant-select-selector input {
  cursor: auto;
}

.ant-select-focused:not(.ant-select-disabled).ant-select:not(.ant-select-customize-input) .ant-select-selector {
  border-color: #ffd429;
  box-shadow: 0 0 0 0.03rem rgba(249,190,0,.2);
  border-right-width: 0.01rem;
  outline: 0;
}

.ant-select-disabled.ant-select:not(.ant-select-customize-input) .ant-select-selector {
  color: rgba(0,0,0,.25);
  background: #f5f5f5;
  cursor: not-allowed;
}

.ant-select-multiple.ant-select-disabled.ant-select:not(.ant-select-customize-input) .ant-select-selector {
  background: #f5f5f5;
}

.ant-select-disabled.ant-select:not(.ant-select-customize-input) .ant-select-selector input {
  cursor: not-allowed;
}

.ant-select:not(.ant-select-customize-input) .ant-select-selector .ant-select-selection-search-input {
  margin: 0;
  padding: 0;
  background: rgba(0,0,0,0);
  border: none;
  outline: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}

.ant-select:not(.ant-select-customize-input) .ant-select-selector .ant-select-selection-search-input::-webkit-search-cancel-button {
  display: none;
  -webkit-appearance: none;
}

.ant-select:not(.ant-select-disabled):hover .ant-select-selector {
  border-color: #ffd429;
  border-right-width: 0.01rem;
}

.ant-select-selection-item {
  flex: 1 1;
  overflow: hidden;
  font-weight: normal;
  white-space: nowrap;
  text-overflow: ellipsis;
}

@media all and (-ms-high-contrast: none) {
  .ant-select-selection-item *::-ms-backdrop,
  .ant-select-selection-item {
    flex: auto;
  }
}

.ant-select-selection-placeholder {
  flex: 1 1;
  overflow: hidden;
  color: #bfbfbf;
  white-space: nowrap;
  text-overflow: ellipsis;
  pointer-events: none;
}

@media all and (-ms-high-contrast: none) {
  .ant-select-selection-placeholder *::-ms-backdrop,
  .ant-select-selection-placeholder {
    flex: auto;
  }
}

.ant-select-arrow {
  display: inline-block;
  color: inherit;
  font-style: normal;
  line-height: 0;
  text-transform: none;
  vertical-align: -0.125em;
  text-rendering: optimizelegibility;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  position: absolute;
  top: 50%;
  right: 0.15rem;
  display: flex;
  align-items: center;
  height: 0.16rem;
  margin-top: -0.08rem;
  color: rgba(0,0,0,.25);
  font-size: 0.16rem;
  line-height: 1;
  text-align: center;
  pointer-events: none;
}

.ant-select-arrow>* {
  line-height: 1;
}

.ant-select-arrow svg {
  display: inline-block;
}

.ant-select-arrow::before {
  display: none;
}

.ant-select-arrow .ant-select-arrow-icon {
  display: block;
}

.ant-select-arrow .anticon {
  vertical-align: top;
  transition: transform .3s;
}

.ant-select-arrow .anticon>svg {
  vertical-align: top;
}

.ant-select-arrow .anticon:not(.ant-select-suffix) {
  pointer-events: auto;
}

.ant-select-disabled .ant-select-arrow {
  cursor: not-allowed;
}

.ant-select-arrow>*:not(:last-child) {
  margin-inline-end: 0.11rem;
}

.ant-select-clear {
  position: absolute;
  top: 50%;
  right: 0.15rem;
  z-index: 1;
  display: inline-block;
  width: 0.16rem;
  height: 0.16rem;
  margin-top: -0.08rem;
  color: rgba(0,0,0,.25);
  font-size: 0.16rem;
  font-style: normal;
  line-height: 1;
  text-align: center;
  text-transform: none;
  background: #fff;
  cursor: pointer;
  opacity: 0;
  transition: color .3s ease,opacity .15s ease;
  text-rendering: auto;
}

.ant-select-clear::before {
  display: block;
}

.ant-select-clear:hover {
  color: rgba(0,0,0,.45);
}

.ant-select:hover .ant-select-clear {
  opacity: 1;
}

.ant-select-dropdown {
  margin: 0;
  padding: 0;
  color: rgba(0,0,0,.85);
  font-variant: tabular-nums;
  line-height: 1.5715;
  list-style: none;
  font-feature-settings: "tnum",;
  position: absolute;
  top: -133.32rem;
  left: -133.32rem;
  z-index: 1050;
  box-sizing: border-box;
  padding: 0.05rem 0;
  overflow: hidden;
  font-size: 0.19rem;
  font-variant: initial;
  background-color: #fff;
  border-radius: 0.03rem;
  outline: none;
  box-shadow: 0 0.04rem 0.08rem -0.05rem rgba(0,0,0,.12),0 0.08rem 0.21rem 0 rgba(0,0,0,.08),0 0.12rem 0.37rem 0.11rem rgba(0,0,0,.05);
}

.ant-select-dropdown.ant-slide-up-enter.ant-slide-up-enter-active.ant-select-dropdown-placement-bottomLeft,
.ant-select-dropdown.ant-slide-up-appear.ant-slide-up-appear-active.ant-select-dropdown-placement-bottomLeft {
  animation-name: antSlideUpIn;
}

.ant-select-dropdown.ant-slide-up-enter.ant-slide-up-enter-active.ant-select-dropdown-placement-topLeft,
.ant-select-dropdown.ant-slide-up-appear.ant-slide-up-appear-active.ant-select-dropdown-placement-topLeft {
  animation-name: antSlideDownIn;
}

.ant-select-dropdown.ant-slide-up-leave.ant-slide-up-leave-active.ant-select-dropdown-placement-bottomLeft {
  animation-name: antSlideUpOut;
}

.ant-select-dropdown.ant-slide-up-leave.ant-slide-up-leave-active.ant-select-dropdown-placement-topLeft {
  animation-name: antSlideDownOut;
}

.ant-select-dropdown-hidden {
  display: none;
}

.ant-select-dropdown-empty {
  color: rgba(0,0,0,.25);
}

.ant-select-item-empty {
  position: relative;
  display: block;
  min-height: 0.43rem;
  padding: 0.07rem 0.16rem;
  color: rgba(0,0,0,.85);
  font-weight: normal;
  font-size: 0.19rem;
  line-height: 0.29rem;
  color: rgba(0,0,0,.25);
}

.ant-select-item {
  position: relative;
  display: block;
  min-height: 0.43rem;
  padding: 0.07rem 0.16rem;
  color: rgba(0,0,0,.85);
  font-weight: normal;
  font-size: 0.19rem;
  line-height: 0.29rem;
  cursor: pointer;
  transition: background .3s ease;
}

.ant-select-item-group {
  color: rgba(0,0,0,.45);
  font-size: 0.16rem;
  cursor: default;
}

.ant-select-item-option {
  display: flex;
}

.ant-select-item-option-content {
  flex: auto;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.ant-select-item-option-state {
  flex: none;
}

.ant-select-item-option-active:not(.ant-select-item-option-disabled) {
  background-color: #f5f5f5;
}

.ant-select-item-option-selected:not(.ant-select-item-option-disabled) {
  color: rgba(0,0,0,.85);
  font-weight: 600;
  background-color: #fffde6;
}

.ant-select-item-option-selected:not(.ant-select-item-option-disabled) .ant-select-item-option-state {
  color: #f9be00;
}

.ant-select-item-option-disabled {
  color: rgba(0,0,0,.25);
  cursor: not-allowed;
}

.ant-select-item-option-disabled.ant-select-item-option-selected {
  background-color: #f5f5f5;
}

.ant-select-item-option-grouped {
  padding-left: 0.32rem;
}

.ant-select-lg {
  font-size: 0.21rem;
}

.ant-select-borderless .ant-select-selector {
  background-color: rgba(0,0,0,0) !important;
  border-color: rgba(0,0,0,0) !important;
  box-shadow: none !important;
}

.ant-select.ant-select-in-form-item {
  width: 100%;
}

.ant-select-rtl {
  direction: rtl;
}

.ant-select-rtl .ant-select-arrow {
  right: auto;
  right: initial;
  left: 0.15rem;
}

.ant-select-rtl .ant-select-clear {
  right: auto;
  right: initial;
  left: 0.15rem;
}

.ant-select-dropdown-rtl {
  direction: rtl;
}

.ant-select-dropdown-rtl .ant-select-item-option-grouped {
  padding-right: 0.32rem;
  padding-left: 0.16rem;
}

.ant-select-rtl.ant-select-multiple.ant-select-show-arrow .ant-select-selector,
.ant-select-rtl.ant-select-multiple.ant-select-allow-clear .ant-select-selector {
  padding-right: 0.05rem;
  padding-left: 0.32rem;
}

.ant-select-rtl.ant-select-multiple .ant-select-selection-item {
  text-align: right;
}

.ant-select-rtl.ant-select-multiple .ant-select-selection-item-content {
  margin-right: 0;
  margin-left: 0.05rem;
  text-align: right;
}

.ant-select-rtl.ant-select-multiple .ant-select-selection-search-mirror {
  right: 0;
  left: auto;
}

.ant-select-rtl.ant-select-multiple .ant-select-selection-placeholder {
  right: 0.15rem;
  left: auto;
}

.ant-select-rtl.ant-select-multiple.ant-select-sm .ant-select-selection-placeholder {
  right: 0.09rem;
}

.ant-select-rtl.ant-select-single .ant-select-selector .ant-select-selection-item,
.ant-select-rtl.ant-select-single .ant-select-selector .ant-select-selection-placeholder {
  right: 0;
  left: 0.12rem;
  text-align: right;
}

.ant-select-rtl.ant-select-single.ant-select-show-arrow .ant-select-selection-search {
  right: 0.15rem;
  left: 0.33rem;
}

.ant-select-rtl.ant-select-single.ant-select-show-arrow .ant-select-selection-item,
.ant-select-rtl.ant-select-single.ant-select-show-arrow .ant-select-selection-placeholder {
  padding-right: 0;
  padding-left: 0.24rem;
}

.ant-select-rtl.ant-select-single.ant-select-sm:not(.ant-select-customize-input).ant-select-show-arrow .ant-select-selection-search {
  right: 0.08rem;
}

.ant-select-rtl.ant-select-single.ant-select-sm:not(.ant-select-customize-input).ant-select-show-arrow .ant-select-selection-item,
.ant-select-rtl.ant-select-single.ant-select-sm:not(.ant-select-customize-input).ant-select-show-arrow .ant-select-selection-placeholder {
  padding-right: 0;
  padding-left: 0.28rem;
}