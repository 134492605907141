@keyframes antCheckboxEffect {
  0% {
    transform: scale(1);
    opacity: .5;
  }

  100% {
    transform: scale(1.6);
    opacity: 0;
  }
}

.ant-checkbox {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: rgba(0,0,0,.85);
  font-size: 0.19rem;
  font-variant: tabular-nums;
  line-height: 1.5715;
  list-style: none;
  font-feature-settings: "tnum";
  position: relative;
  top: .2em;
  line-height: 1;
  white-space: nowrap;
  outline: none;
  cursor: pointer;
}

.ant-checkbox-wrapper:hover .ant-checkbox-inner,
.ant-checkbox:hover .ant-checkbox-inner,
.ant-checkbox-input:focus+.ant-checkbox-inner {
  border-color: #f9be00;
}

.ant-checkbox-checked::after {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border: 0.01rem solid #f9be00;
  border-radius: 0.03rem;
  visibility: hidden;
  animation: antCheckboxEffect .36s ease-in-out;
  animation-fill-mode: backwards;
  content: "";
}

.ant-checkbox:hover::after,
.ant-checkbox-wrapper:hover .ant-checkbox::after {
  visibility: visible;
}

.ant-checkbox-inner {
  position: relative;
  top: 0;
  left: 0;
  display: block;
  width: 0.21rem;
  height: 0.21rem;
  direction: ltr;
  background-color: #fff;
  border: 0.01rem solid #d9d9d9;
  border-radius: 0.03rem;
  border-collapse: separate;
  transition: all .3s;
}

.ant-checkbox-inner::after {
  position: absolute;
  top: 50%;
  left: 21.5%;
  display: table;
  width: 0.08rem;
  height: 0.12rem;
  border: 0.03rem solid #fff;
  border-top: 0;
  border-left: 0;
  transform: rotate(45deg) scale(0) translate(-50%, -50%);
  opacity: 0;
  transition: all .1s cubic-bezier(0.71, -0.46, 0.88, 0.6),opacity .1s;
  content: " ";
}

.ant-checkbox-input {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1;
  width: 100%;
  height: 100%;
  cursor: pointer;
  opacity: 0;
}

.ant-checkbox-checked .ant-checkbox-inner::after {
  position: absolute;
  display: table;
  border: 0.03rem solid #fff;
  border-top: 0;
  border-left: 0;
  transform: rotate(45deg) scale(1) translate(-50%, -50%);
  opacity: 1;
  transition: all .2s cubic-bezier(0.12, 0.4, 0.29, 1.46) .1s;
  content: " ";
}

.ant-checkbox-checked .ant-checkbox-inner {
  background-color: #f9be00;
  border-color: #f9be00;
}

.ant-checkbox-disabled {
  cursor: not-allowed;
}

.ant-checkbox-disabled.ant-checkbox-checked .ant-checkbox-inner::after {
  border-color: rgba(0,0,0,.25);
  animation-name: none;
}

.ant-checkbox-disabled .ant-checkbox-input {
  cursor: not-allowed;
  pointer-events: none;
}

.ant-checkbox-disabled .ant-checkbox-inner {
  background-color: #f5f5f5;
  border-color: #d9d9d9 !important;
}

.ant-checkbox-disabled .ant-checkbox-inner::after {
  border-color: #f5f5f5;
  border-collapse: separate;
  animation-name: none;
}

.ant-checkbox-disabled+span {
  color: rgba(0,0,0,.25);
  cursor: not-allowed;
}

.ant-checkbox-disabled:hover::after,
.ant-checkbox-wrapper:hover .ant-checkbox-disabled::after {
  visibility: hidden;
}

.ant-checkbox-wrapper {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: rgba(0,0,0,.85);
  font-size: 0.19rem;
  font-variant: tabular-nums;
  line-height: 1.5715;
  list-style: none;
  font-feature-settings: "tnum";
  display: inline-flex;
  align-items: baseline;
  line-height: unset;
  cursor: pointer;
}

.ant-checkbox-wrapper::after {
  display: inline-block;
  width: 0;
  overflow: hidden;
  content: " ";
}

.ant-checkbox-wrapper.ant-checkbox-wrapper-disabled {
  cursor: not-allowed;
}

.ant-checkbox-wrapper+.ant-checkbox-wrapper {
  margin-left: 0.11rem;
}

.ant-checkbox-wrapper.ant-checkbox-wrapper-in-form-item input[type=checkbox] {
  width: 0.19rem;
  height: 0.19rem;
}

.ant-checkbox+span {
  padding-right: 0.11rem;
  padding-left: 0.11rem;
}

.ant-checkbox-group {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: rgba(0,0,0,.85);
  font-size: 0.19rem;
  font-variant: tabular-nums;
  line-height: 1.5715;
  list-style: none;
  font-feature-settings: "tnum";
  display: inline-block;
}

.ant-checkbox-group-item {
  margin-right: 0.11rem;
}

.ant-checkbox-group-item:last-child {
  margin-right: 0;
}

.ant-checkbox-group-item+.ant-checkbox-group-item {
  margin-left: 0;
}

.ant-checkbox-indeterminate .ant-checkbox-inner {
  background-color: #fff;
  border-color: #d9d9d9;
}

.ant-checkbox-indeterminate .ant-checkbox-inner::after {
  top: 50%;
  left: 50%;
  width: 0.11rem;
  height: 0.11rem;
  background-color: #f9be00;
  border: 0;
  transform: translate(-50%, -50%) scale(1);
  opacity: 1;
  content: " ";
}

.ant-checkbox-indeterminate.ant-checkbox-disabled .ant-checkbox-inner::after {
  background-color: rgba(0,0,0,.25);
  border-color: rgba(0,0,0,.25);
}

.ant-checkbox-rtl {
  direction: rtl;
}

.ant-checkbox-group-rtl .ant-checkbox-group-item {
  margin-right: 0;
  margin-left: 0.11rem;
}

.ant-checkbox-group-rtl .ant-checkbox-group-item:last-child {
  margin-left: 0 !important;
}

.ant-checkbox-group-rtl .ant-checkbox-group-item+.ant-checkbox-group-item {
  margin-left: 0.11rem;
}